import { Component, OnInit, ElementRef,ChangeDetectorRef } from '@angular/core';
import { OcInfraModule, APICallerService, Configuration, NavigationService, IAlias, ResourceService, ResourceSchemaService, OptionParams, ResourceDirectoryService } from '@diaas/ux-web';
import { CommonService } from '../../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-close-call-filter',
  templateUrl: './close-call-filter.component.html',
  styleUrls: ['./close-call-filter.component.scss']
})
export class CloseCallFilterComponent implements OnInit {
  resourceService = OcInfraModule.AppInjector.get(ResourceService);
  data: any;
  selectedReason: any;
  commonService = OcInfraModule.AppInjector.get(CommonService);
  selectedRole: any;
  selectedRoleParams: any;
  reasonList: any;
  searchTerm: string = '';
  selectedTitle: string | null = null;
  filteredReasons: any[] = [];
  isDropdownVisible: boolean = false;
  isCallDispositionValid: boolean = true;
  touchedOnce: boolean = false; 
  hasUserInteracted: boolean = false;  
  constructor(public elementRef: ElementRef, private cdr: ChangeDetectorRef) { }

  ngDoCheck() {
    if (OcInfraModule.AppInjector.get(CommonService).resetFlag) {
      this.resetField();
      // Clear the reset flag to avoid multiple resets
      OcInfraModule.AppInjector.get(CommonService).clearResetFlag();
      this.cdr.detectChanges();
    } 
  }
  ngOnInit() {
    if(this.elementRef.nativeElement.id=='closeReason'){

      let selectedRoleHref = OcInfraModule.AppInjector.get(CommonService).selectedRoleFirst ? OcInfraModule.AppInjector.get(CommonService).selectedRoleFirst : '';
      let selectedRoleParams = OcInfraModule.AppInjector.get(CommonService).requestedParams ? OcInfraModule.AppInjector.get(CommonService).requestedParams : ''; 
      // console.log(selectedRoleHref);
      if(selectedRoleHref){
        OcInfraModule.AppInjector.get(APICallerService).get(selectedRoleHref[0].href, selectedRoleParams).subscribe(response => {
          let data =response;
          this.reasonList = data.response._options.properties.reason.oneOf;
          // console.log(this.reasonList);
          this.filteredReasons = this.reasonList;
  
          OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        });
        error => {
          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
          this.data._embedded.status_report.messages ?OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0):"";
        }
      }

    }
  }

  // Function to filter the reasonList based on searchTerm
  onSearchChange(value: string): void {
    this.searchTerm = value.toLowerCase();
    this.filteredReasons = this.reasonList.filter(reason =>
      reason.title.toLowerCase().includes(this.searchTerm)
    );
    this.isDropdownVisible = this.filteredReasons.length > 0;  
  }


  onInputFocus(): void {
    this.isDropdownVisible = true; 
    // this.searchTerm = '';   
    // this.touchedOnce = true; 
    this.hasUserInteracted = true;
  }

  onInputBlur(): void {
    if (this.hasUserInteracted = true && !this.searchTerm) {
      this.isCallDispositionValid = false;  
    } 
    else {
      this.isCallDispositionValid = true;  
    }

    // Hide the dropdown after a slight delay
    setTimeout(() => {
      this.isDropdownVisible = false;  
    }, 300);  
  }


  onSelectOption(reason: any): void {
    this.searchTerm = reason.title;  
    this.isCallDispositionValid = true;
    OcInfraModule.AppInjector.get(CommonService).selectedReason = this.searchTerm;
    this.isDropdownVisible = false;  
  }

  validateSearchInput(): void {
    if (!this.searchTerm) {
      this.isCallDispositionValid = false;
    }
  }
  resetField(): void {
    this.isDropdownVisible = false;  
    this.searchTerm = '';  // Reset the field after authorization
    this.isCallDispositionValid = true;  // Set it to valid initially after reset
    this.hasUserInteracted = false;  
  }

}
