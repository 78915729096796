import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { Observable, throwError } from 'rxjs';
import 'rxjs/add/operator/do';
import { map, tap, catchError, finalize } from "rxjs/operators";
import { OcInfraModule, ResourceDirectoryService } from '@diaas/ux-web';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';


@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    errorAppendedValue: any = [];
    errorMessageObj = {
        WMA: 'wmA backend system unresponsive currently'
    };
    tenantCodes = ['IUL', 'CONV', 'NB', 'IUL', 'LT', 'DAY2', 'WMA', 'REST', 'VARI'];
    currentPage: string = '';
    private count = 0;
    private totalRequests = 0;
    private requests: HttpRequest<any>[] = [];
    contractsApi: string;
    constructor(private loaderService: CommonService, private router: Router) { }
    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        if (this.requests.length > 0) {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true })
        } else {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true })
        }
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requests.push(request);
        this.currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;

        /*     
            ==============    Need's to get Tenant Name   ========================
            Tenant Name there in the "contracts" API. So, split the URL by 'Slash'
            count "contracts" in the URL for to get Tenant Name to show server error message.  
        */
        var spliteBySlash = (request.url).split('/');
        var countContractsStringInUrl = spliteBySlash.reduce(function (count, val) {
            return count + ((val === 'contracts') ? 1 : 0)
        }, 0);

        // To get Tenant Name
        var tenantName: string = '';
        if (Number(countContractsStringInUrl) > 1 && spliteBySlash.length == 7) {
            // identify tenent name in the URL
            let isTenantAvailable = this.tenantCodes.find((indexValue) => {
                return (request.url.indexOf(indexValue.toLowerCase()) > -1);
            });

            this.contractsApi = request.url;

            // Tenant Name available in the URl then assign to global variable
            if (isTenantAvailable.length) {
                let tenantCodeWithRegion = spliteBySlash[4].split('-');
                tenantName = tenantCodeWithRegion[1].toUpperCase();
                OcInfraModule.AppInjector.get(CommonService).tenantName = spliteBySlash[4];
            } else {
                OcInfraModule.AppInjector.get(CommonService).tenantName = '';
            }
        }

        next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                this.requests.push(request);
                if (event instanceof HttpResponse && event.body && event.body._embedded && event.body._embedded.status_report) {
                    event.body._embedded.status_report.url = event.url;
                    OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(event.body._embedded.status_report);
                }
            }));
        return Observable.create(observer => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: false});
            const subscription = next.handle(request)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse && event.body && event.body._embedded && event.body._embedded.status_report) {
                            event.body._embedded.status_report.url = event.url;
                            OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(event.body._embedded.status_report);
                        }
                        if (event instanceof HttpResponse) {
                            this.removeRequest(request);
                            if(event.url.includes("claims") && event.body && event.body._embedded && event.body._embedded.status_report){
                                OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(event.body._embedded.status_report);
                            }
                            observer.next(event);
                        }
                    },
                    err => {
                        this.removeRequest(request);
                        observer.error(err);
                        if (err.status == 401 && this.contractsApi != request.url) { // 401 for non contracts API
                            setTimeout(function () {
                                let searchField = document.getElementById("contracts-collection-search-text");
                                if (searchField != null) {
                                    let parentSearch = document.querySelectorAll(".csr-search-input");
                                    parentSearch[0].setAttribute('id', 'searchBarError');
                                    (<HTMLInputElement>searchField).disabled = true;
                                    var searchIconDisable = document.querySelectorAll(".searchInHeader");
                                    searchIconDisable[0].setAttribute("id", 'disableSearchIcon');
                                }
                            }, 500);
                            err.error && err.error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(err.error.message, 'Error', 0) : null;
                        } else if ((err.status == 504) && (request.url.endsWith('/casemanagement/user/instances') || ["/casemanagement/contracts/", "/instances"].every(i => request.url.indexOf(i) > -1))) {
                            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('AWD timed out', 'Error', 0);
                        } else if (err.status != 200 && tenantName.length && this.contractsApi == request.url) { //Only for Policy detail API fails
                            // Get the Tenant code 
                            var index = this.errorAppendedValue.length ? this.errorAppendedValue.findIndex(tenantName) : -1;
                            var tenantCode = tenantName == 'WMA' || tenantName == 'CONV';
                            if (tenantCode && index == -1) {
                                tenantName = tenantCode ? 'WMA' : '';
                                this.errorAppendedValue.push(tenantName);
                            } else if (tenantName == 'VARI' && index == -1) {
                                this.errorAppendedValue.push(tenantName);
                            }
                        } else {
                            err.error && err.error._embedded && err.error._embedded.status_report && err.error._embedded.status_report.messages.length && err.error._embedded.status_report.messages[0].severity == "ERROR" ? err.status == 404 ? null : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(err.error._embedded.status_report.messages[0].message, 'Error', 0) : null;
                        }

                        const encPolicyNo = OcInfraModule.AppInjector.get(CommonService).encPolicyNo;
                        if (encPolicyNo && encPolicyNo.length > 0 &&
                            request.url.includes(encPolicyNo)) {
                            //remove any request that is contract related
                            let filteredReqs = this.requests.filter(function (req) {
                                return req.url.includes(encPolicyNo);
                            });
                            filteredReqs.forEach(req => this.removeRequest(req));
                        }

                        // Policy detail API is fail's then show the modal window and for only CSR user and after click on
                        // cancel button in the modal window reset the value and navigate to policy search page.
                        let csrUser = OcInfraModule.AppInjector.get(CommonService).csrUser;
                        if ((err.status != 404) && this.contractsApi == request.url && this.errorAppendedValue.length && (this.currentPage == '/screen/policyDetails' || this.currentPage == '/screen/partySearch') && csrUser) {
                            // Display error message
                            var errorMessage = this.errorMessageObj[this.errorAppendedValue[0]];
                            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: [errorMessage], custom: true }, 'Close', '', 'ERROR', '393px');
                            dialogRef.afterClosed().subscribe(dialogResponse => {
                                if (!dialogResponse) {
                                    OcInfraModule.AppInjector.get(CommonService).resetPolicyDetails();
                                    OcInfraModule.AppInjector.get(CommonService).changeHeaders({ remote_user: null, company_code: null, region_code: null });
                                    OcInfraModule.AppInjector.get(CommonService).myCallHistory();
                                    const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
                                    resourceDirectoryService.deleteElementObservableDirectory(this.contractsApi);
                                    this.router.navigate(['/screen/partySearch']);
                                }

                                errorMessage = '';
                                this.errorAppendedValue.length = 0;
                                this.contractsApi = '';
                            })
                        }
                    },
                    () => {
                        this.removeRequest(request);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(request);
                subscription.unsubscribe();
            };
        });
        // if(request.method=="GET"){
        //   OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: false})
        // }
        // if(request.method=="POST" || request.method=="PUT"){
        //     OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: true, edit: true})
        //   }  
        //   return next.handle(request).pipe(
        //         tap((event: HttpEvent<any>) => {         
        //             this.requests.push(request);

        //             //this.loaderService.isLoading.next(true);
        //             if (event instanceof HttpResponse && event.body && event.body._embedded && event.body._embedded.status_report) {    
        //                 event.body._embedded.status_report.url = event.url;
        //     //            this.removeRequest(request);
        //              //   this.loaderService.isLoading.next(false);

        //              OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(event.body._embedded.status_report);                 

        //             }

        //         }),
        //         catchError(err => {
        //             if (err instanceof HttpErrorResponse) {
        //                 //console.log('Caught error', err);
        //             }         
        //             return throwError(err);
        //         }),finalize(() => {
        //             OcInfraModule.AppInjector.get(CommonService).showHideSpinner({showSpinner: false, edit: false});
        //         })
        //     );
    }
    // private decreaseRequests() {
    //     this.totalRequests--;
    //     if (this.totalRequests === 0) {
    //       this.loaderService.isLoading.next(false);
    //     }
    //   }
}
