import { Component, OnInit, ElementRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { OcInfraModule, APICallerService, OptionParams, ResourceService } from '@diaas/ux-web';
import { AdvGrowlService } from 'primeng-advanced-growl';
import * as _ from 'lodash';

@Component({
  selector: 'policynotes-table',
  templateUrl: './policyNotes.component.html',
  styleUrls: ['./policyNotes.component.scss']
})

export class PolicyNotesComponent implements OnInit {

  displayedColumns: string[] = ['message', 'oper_id', 'note_type_desc', 'type_no', 'note_date'];
  notesList: any[] = [];
  checkedData: Object = {};
  noRecords: boolean = true;
  statusMsg: string = "";
  message: boolean = false;
  isAnnuity: boolean = false;
  isGroupAnnuity: boolean = false;
  notesObj: any = {};
  policyDetails: any;
  resourceService: any;
  headers = { ...{ wma_user: window.sessionStorage.getItem('username'), client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client }, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("policyNotes") };
  notesData: boolean;
  noNotesData: boolean = false;
  currentChecked: {};
  checkedProperties: any = {};
  selectdValues: any;
  coveragehtml: string = "";
  constructor(public elementRef: ElementRef) {

  }

  ngOnInit() {
    OcInfraModule.AppInjector.get(CommonService).refreshResponse$.subscribe(response => {
      if (response) {
        this.loadNotes(response);
      }
    });
    setTimeout(() => {
        let policyNotes = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyNotes');
        if(policyNotes) {
            this.loadNotes(policyNotes)
        } else {
            this.getNotes()
        }
    }, 500);
  }

  getNotes = () => {
    const allURLs = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
    if (allURLs["notes"] && allURLs["notes"].href) {
      let optionParams = new OptionParams();
      optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("policyNotes");
      OcInfraModule.AppInjector.get(APICallerService).get(allURLs["notes"].href + "?_start=0&_num=100", optionParams).subscribe(response => {
      OcInfraModule.AppInjector.get(CommonService).notesRes = response;
       this.loadNotes(response);
      });
    }

  }

  loadNotes = (response: any) => {
    this.notesList = response.response._links.item;
    let notesIndex = 0;
    if (this.notesList.length > 0) {
      response.response._links.item.forEach(element => {
        element.summary.nameFlag = false;
        element.summary.id = notesIndex + 'F';
        notesIndex++;
      });
    }
    this.noRecords = this.notesList && !this.notesList.length;
    if (response.response._embedded && response.response._embedded.status_report.messages) {
      response.response._embedded.status_report.messages.forEach(msgObj => {
        this.statusMsg += msgObj.message;
        this.message = true;
      });
    }
    let status_report = OcInfraModule.AppInjector.get(CommonService).getNested(response, 'response','_embedded','status_report');
    if(status_report){
    OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(status_report);
    }
  }

  covrageChecked(event, data, selectedValue, selectedKey, columnName, valueType, flag) {
    let selctedCoverage = [];
    selctedCoverage = OcInfraModule.AppInjector.get(CommonService).selectedCoverages;
    if (event.checked) {
      let coverageChecked = []
      this.currentChecked = {}
      selectedValue = selectedValue ? selectedValue : 'No data in admin system.';
      if (selectedValue) {
        this.currentChecked[selectedKey] = selectedValue;
        if (selctedCoverage.length > 0) {
          if (selctedCoverage[0].hasOwnProperty(["selectedNote"])) {
            for (let i = 0; i < selctedCoverage.length; i++) {
              let subKeys = Object.keys(selctedCoverage[0]["selectedNote"]);
              let existed: boolean = true;
              if (subKeys.length > 0) {
                for (let j = 0; j < subKeys.length; j++) {
                  if (subKeys[j] == data["note_type_desc"] + data["type_no"]) {
                    Object.assign(selctedCoverage[0]["selectedNote"][data["note_type_desc"] + data["type_no"]], this.currentChecked);
                    OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
                    existed = false;
                  } else if (j + 1 >= subKeys.length && existed == true) {
                    // this.checkedProperties["selectedNote"] = []
                    this.checkedProperties["selectedNote"][data["note_type_desc"] + data["type_no"]] = this.currentChecked;
                    Object.assign(selctedCoverage[0]["selectedNote"], this.checkedProperties["selectedNote"]);
                    OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
                  }
                }
              } else {
                this.checkedProperties["selectedNote"] = []
                this.checkedProperties["selectedNote"][data["note_type_desc"] + data["type_no"]] = this.currentChecked;
                Object.assign(selctedCoverage[0]["selectedNote"], this.checkedProperties["selectedNote"]);
                OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
              }
            }
          } else {
            this.checkedProperties["selectedNote"] = []
            this.checkedProperties["selectedNote"][data["note_type_desc"] + data["type_no"]] = this.currentChecked;
            Object.assign(selctedCoverage[0], this.checkedProperties)
            OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
          }
        } else {
          this.checkedProperties["selectedNote"] = []
          this.checkedProperties["selectedNote"][data["note_type_desc"] + data["type_no"]] = this.currentChecked;
          selctedCoverage.push(this.checkedProperties);
          OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
        }
      }
    }
    else {
      let unCheckedId = selectedKey;
      delete selctedCoverage[0]["selectedNote"][data["note_type_desc"] + data["type_no"]][unCheckedId]
      let subKeys = Object.keys(selctedCoverage[0]["selectedNote"]);
      let checked = [];
      for (let i = 0; i < subKeys.length; i++) {
        if (_.isEmpty(selctedCoverage[0]["selectedNote"][subKeys[i]])) {
          delete selctedCoverage[0]["selectedNote"][data["note_type_desc"] + data["type_no"]]
          checked.push(false)
        } else {
          checked.push(true)
        }
      }
      localStorage.setItem("selectedNote", JSON.stringify(this.checkedProperties));
      OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
    }
  }

  testChange(event, data, selectedValue, selectedKey, columnName, valueType, flag) {
    let selectedVal: any;
    if (this.checkedData[data.id] && columnName !== 'name') {
      selectedVal = selectedValue ? selectedValue : 'No data in admin system';
      event.checked ? this.checkedData[data.id].push(selectedKey + ':' + selectedVal) : this.checkedData[data.id].splice(this.checkedData[data.id].indexOf(selectedKey + ':' + selectedVal), 1)
    } else {
      if (!this.checkedData[data.id]) {
        selectedVal = selectedValue ? selectedValue : 'No data in admin system';
        this.checkedData[data.id] = new Array(selectedKey + ':' + selectedVal);
      }
    }
    if (this.checkedData[data.id].length == 0) {
      delete this.checkedData[data.id];
    }
    let html = '';
    if (this.checkedData[data.id]) {
      for (var i = 0; i < this.checkedData[data.id].length; i++) {
        var split = this.checkedData[data.id][i].split(':');
        this.notesObj[split[0].trim()] = split[1].trim();
      }
    }
    if (Object.keys(this.checkedData).length > 0) {
      Object.keys(this.checkedData).forEach(key => {
        this.checkedData[key].forEach(element => {
          html += '<dt class="pull-left">' + element.split(":")[0] + ':</dt><dd style = "font-weight: 200;"  >&nbsp;' + element.split(":")[1] + (element.split(":")[2] ? element.split(":")[2] : "") + (element.split(":")[3] ? element.split(":")[3] : "") + (element.split(":")[4] ? element.split(":")[4] : "") + '</dd>';
        });
      });
    }
    let selectedData = [];
    if (this.notesList.length > 0) {
      this.notesList.forEach(element => {
        if (this.checkedData.hasOwnProperty(element.summary.id)) {
          let currentField = {};
          currentField[data.id] = this.checkedData[element.summary.id];
          selectedData.push(currentField);
        }
      });
    }
    if (Object.keys(this.checkedData).length) {
      OcInfraModule.AppInjector.get(CommonService).notesHtml = '<span style="font-size:15px" class="pad-lAr-10px">NOTES</span><dl class="pad-lAr-10px">' + html + '</dl>'
    }
    else {
      OcInfraModule.AppInjector.get(CommonService).notesHtml = '';
    }
    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    this.covrageChecked(event, data, selectedValue, selectedKey, columnName, valueType, flag);
    this.selectdValues = OcInfraModule.AppInjector.get(CommonService).selectedCoverages;
    let keys = Object.keys(this.selectdValues[0]["selectedNote"]);
    if (keys.length > 0) {
      this.selectdValues = OcInfraModule.AppInjector.get(CommonService).selectedCoverages;
      for (let i = 0; i < this.selectdValues.length; i++) {
        localStorage.setItem("selectedNote", JSON.stringify(Object.assign(this.checkedProperties, this.selectdValues[0].selectedNote)));
      }
    }
    else {
      OcInfraModule.AppInjector.get(CommonService).notesHtml = '';
      OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    }

  }

  getPolicyNotesInfo() {
    let optionParams = new OptionParams();
    var allURLs = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();

    optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("policyNotes");
    Object.assign(optionParams.headers, { client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client });
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    OcInfraModule.AppInjector.get(APICallerService).get(allURLs["policyNotes"].href, optionParams).subscribe(response => {
      this.notesList = response.response._links.item;
      let notesIndex = 0;
      if (this.notesList.length > 0) {
        response.response._links.item.forEach(element => {
          element.summary.nameFlag = false;
          element.summary.id = notesIndex + 'F';
          notesIndex++;
        });
      }
      this.noNotesData = this.notesList && !this.notesList.length;
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
      }
    });
  }

}