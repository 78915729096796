import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSortModule } from "@angular/material/sort";
import { MomentModule } from "ngx-moment";

import {
  MatInputModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatIconModule,
  MatMenuModule,
  MatCheckboxModule,
  MatSelectModule,
  MatDatepickerModule,
  MatStepperModule,
  MatFormFieldModule,
  MatListModule,
  MatOptionModule,
  MatTableModule,
  MatCardModule,
  MatTabsModule,
  MatPaginatorModule,
  MatChipsModule,
  MatTooltipModule,
} from "@angular/material";
import { CdkTableModule } from "@angular/cdk/table";

import { OcInfraModule } from "@diaas/ux-web";
import { CommonService } from "./../app/services/common.service";
import { MenuComponent } from "../app/components/customMenu.component/customMenu.component";
import { FilterComponent } from "../app/components/customFilter.component/customFilter.component";
import { TimerComponent } from "../app/components/timer.component/timer.component";
import { AgentBOBHeaderComponent } from "../app/components/agentBOBHeader.component/agentBOBHeader.component";
import { SearchHighlightComponent } from "../app/components/searchHighlight.component/searchHighlight.component";
import { MessageComponent } from "../app/components/customMessage.component/message.component";
import { WizardComponent } from "../app/components/customWizard.component/customWizard.component";
import { FileUploadModule } from "ng2-file-upload";
import { GroupUploadComponent } from "../app/components/upload.component/upload.component";
import { LoanHistoryTableComponent } from "../app/components/loanHistoryTable.component/loanHistoryTable.component";
import { FundTableComponent } from "../app/components/fundTable.component/fundTable.component";
import { CorrespondenceTableComponent } from "../app/components/correspondenceTable.component/correspondenceTable.component";
import { CallDetailComponent } from "../app/components/customCallDetail.component/customCallDetail.component";
import { BannerAlertComponent } from "../app/components/bannerAlert.component/bannerAlert.component";
import { PartyInformationComponent } from "../app/components/partyInformation.component/partyInformation.component";
import { AddressUpdateComponent } from "../app/components/addressUpdate.component/addressUpdate.component";
import { KnowledgeCenterTableComponent } from "../app/components/knowledgeCenterTable.component/knowledgeCenterTable.component";
import { IulFundTableComponent } from "../app/components/iulfundTable.component/iulfundTable.component";
import { CustomFieldComponent } from "../app/components/customFields.component/customFieldsComponent";
import { TableFilterComponent } from "../app/components/customTableFilter.component/customTableFilter.component";
import { AceFormRequestComponent } from "../app/components/aceFormRequest.component/aceFormRequest.component";
import { CorrespondenceFaxComponent } from "../app/components/correspondenceFax.component/correspondenceFax.component";
import { CorrespondenceEmailComponent } from "../app/components/correspondenceEmail.component/correspondenceEmail.component";
import { TwoDigitDecimaNumberDirective } from "../app/components/aceFormRequest.component/customTwoDigitDecimaNumberDirective";
import { CustomPolicyPortfolioComponent } from "../app/components/customPolicyPortfolio.component/custom-policy-portfolio.component";
import { AgentBookOfBusinessTableComponent } from "../app/components/agentBookOfBusinessComponent/agentBookOfBusinessComponent";
import { WithdrawalsSurrender } from "../app/components/withdrawlsSurrender/withdrawlsSurrenderComponent";
import { WithdrawlSurrenderQuote } from "../app/components/withdrawlsSurrender/quoteComponent";
import { WithdrawlSurrenderSurrender } from "../app/components/withdrawlsSurrender/surrenderComponent";
import { WithdrawlSurrenderPayment } from "../app/components/withdrawlsSurrender/paymentComponent";
import { WithdrawlSurrenderConfirmation } from "../app/components/withdrawlsSurrender/confirmationComponent";
import { SystematicWithdrawlComponent } from "../app/components/withdrawlsSurrender/systematicWithdrawlComponent";
import { EobDetailsComponent } from "../app/components/eobDetails.component/eobDetails.component";
import { CreateDeathClaimComponent } from "../app/components/createDeathClaim/create-death-claim.component";
import { ClaimNotificationDeceasedInformationComponent } from "../app/components/createDeathClaim/claimNotificationDeceasedInformation/claim-notification-deceased-information.component";
import { AdditionalClaimInformationComponent } from "../app/components/createDeathClaim/additionalClaimInformation/additional-claim-information.component";
import { PolicyPortfolioComponent } from "../app/components/createDeathClaim/policyPortfolio/policy-portfolio.component";
import { ClaimPartyInformationComponent } from "../app/components/createDeathClaim/claimPartyInformation/claim-party-information.component";
import { DeathClaimInformationComponent } from "../app/components/createDeathClaim/policyPortfolio/deathClaimInformation/death-claim-information.component";
import { AssociatedPoliciesComponent } from "../app/components/createDeathClaim/policyPortfolio/associatedPolicies/associated-policies.component";
import { AdditionalPoliciesComponent } from "../app/components/createDeathClaim/policyPortfolio/associatedPolicies/additionalPolicies/additional-policies.component";
import { PolicySearchComponent } from "../app/components/createDeathClaim/policyPortfolio/associatedPolicies/policySearch/policy-search.component";
import { HighlightSearchPipe } from "../app/components/createDeathClaim/policyPortfolio/associatedPolicies/policySearch/hight-light-search.pipe";
import { DeathClaimComponent } from "../app/components/death-claim/death-claim.component";
import { GroupPolicyDropdownComponent } from "../app/components/groupPolicyDropdown.component/groupPolicyDropdown.component";
import { GroupPolicyValuesTab } from "../app/components/groupPolicyValuesTab.component/groupPolicyValuesTab.component";
import { GroupValuesAssetTableComponent } from "../app/components/groupValuesAssetTable.component/groupValuesAssetTable.component";
import { PolicyNotesComponent } from "../app/components/policyNotes/policyNotes.component";
import { GroupLoanHistoryTableComponent } from "../app/components/groupLoanHistoryTable.component/groupLoanHistoryTable.component";
import { WorkRequestHistoryComponent } from "../app/components/work-request-history/work-request-history.component";
import { LoanInitiationComponent } from "../app/components/loan-initiation/loan-initiation.component";
import { QuoteLoanInitiationComponent } from "../app/components/loan-initiation/quote-loan-initiation/quote-loan-initiation.component";
import { PaymentLoanInitiationComponent } from "../app/components/loan-initiation/payment-loan-initiation/payment-loan-initiation.component";
import { LoanInfoLoanInitiationComponent } from "../app/components/loan-initiation/loan-info-loan-initiation/loan-info-loan-initiation.component";
import { ConfirmationLoanInitiationComponent } from "../app/components/loan-initiation/confirmation-loan-initiation/confirmation-loan-initiation.component";
import { WorkRequestLogHistoryComponent } from "../app/components/work-request-log-history/work-request-log-history.component";
import { NumberMaskDirective } from "../app/directives/number-mask.directive";
import { NgxMaskModule } from "ngx-mask";
import { DynamicFormFieldComponent } from "../app/components/loan-initiation/payment-loan-initiation/dynamic-form-field/dynamic-form-field.component";
import { AssetAccountDetailsComponent } from "../app/components/asset-account-details/asset-account-details.component";
import { CustomTableComponent } from "../app/components/custom-table/custom-table.component";
import { PaymentHistoryComponent } from "../app/components/payment-history/payment-history.component";
import { ProgramEnrollmentTabComponent } from "../app/components/program-enrollment-tab/program-enrollment-tab.component";
import { OutgoingMoneyComponent } from "../app/components/outgoing-money/outgoing-money.component";
import { DividendHistoryComponent } from "../app/components/dividend-history/dividend-history.component";
import { IncomingMoneyComponent } from "../app/components/incoming-money/incoming-money.component";
import { HistoricalCoiComponent } from "../app/components/historical-coi/historical-coi.component";
import { FundsComponent } from "../app/components/annuityand-ulfunds/annuityand-ulfunds.component";
import { PlanReportingComponent } from "../app/components/plan-reporting/plan-reporting.component";
import { PlanReportComponent } from "../app/components/plan-reporting/plan-report/plan-report.component";
import { FundTransaction } from "../app/components/fundTransactions/fundTransactionsComponent";
import { FundTransferInformationComponent } from "../app/components/fundTransactions/fund-transfer-information/fund-transfer-information.component";
import { FutureAllocationChangeComponent } from "../app/components/fundTransactions/future-allocation-change/future-allocation-change.component";
import { RebalancingComponent } from "../app/components/fundTransactions/rebalancing/rebalancing.component";
import { DollarCostAveragingComponent } from "../app/components/fundTransactions/dollar-cost-averaging/dollar-cost-averaging.component";
import { ConfirmationComponent } from "../app/components/fundTransactions/confirmation/confirmation.component";
import { GroupPolicyLoanQuoteDetailsComponent } from "../app/components/groupPolicyLoanQuoteDetails.component/groupPolicyLoanQuoteDetails.component";
import { AnnuityContractManagementComponent } from "../app/components/annuity-contract-management/annuity-contract-management.component";
import { CheckHistoryAnnuityComponent } from "../app/components/check-history-annuity/check-history-annuity.component";
import { LazyLoadComponent } from "../app/components/lazy-load/lazy-load.component";
import { FullSurrenderQuoteComponent } from "../app/components/withdrawlsSurrender/full-surrender/full-surrender-quote/full-surrender-quote.component";
import { FullSurrenderSurrenderComponent } from "../app/components/withdrawlsSurrender/full-surrender/full-surrender-surrender/full-surrender-surrender.component";
import { RMDComponent } from "../app/components/RMD/RMDComponent";
import { RMDQuoteComponent } from "../app/components/RMD/quote/RMDQuote.component";
import { RMDTabComponent } from "../app/components/RMD/rmdTab/RMDTab.component";
import { RMDPaymentComponent } from "../app/components/RMD/payment/payment.component";
import { RMDConfirmationComponent } from "../app/components/RMD/confirmation/confirmation.component";
import { FutureFundTableComponent } from "../app/components/future-fund-table/future-fund-table.component";
import { CurrentFundTableComponentComponent } from "../app/components/current-fund-table-component/current-fund-table-component.component";
import { PartialSurrenderQuoteComponent } from "../app/components/withdrawlsSurrender/partial-surrender/partial-surrender-quote/partial-surrender-quote.component";
import { PartialSurrenderSurrenderComponent } from "../app/components/withdrawlsSurrender/partial-surrender/partial-surrender-surrender/partial-surrender-surrender.component";
import { PartialSurrenderSurrenderMRPSComponent } from "../app/components/withdrawlsSurrender/partial-surrender/partial-surrender-surrender-mrps/partial-surrender-surrender-mrps.component";
import { FundTableAnnuityComponent } from "../app/components/fund-table-annuity/fund-table-annuity.component";
import { LoantabannuityComponent } from "../app/components/loantabannuity/loantabannuity.component";
import { ClaimsTableComponent } from "../app/components/claim-table/claim-table.component";
import { SystematicWithdrawalWithdrawalComponent } from "../app/components/withdrawlsSurrender/systematic-withdrawal/systematic-withdrawal-withdrawal/systematic-withdrawal-withdrawal.component";
import { MFAComponent} from "../app/components/MFA/MFA.component";
import { WorkrequestRefreshComponent } from "../app/components/workrequest-refresh/workrequest-refresh.component";
import { CloseCallFilterComponent } from "../app/components/close-call-filter/close-call-filter.component";

@NgModule({
  imports: [
    OcInfraModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatStepperModule,
    MatListModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MomentModule,
    MatCardModule,
    MatTabsModule,
    MatPaginatorModule,
    MatChipsModule,
    NgxMaskModule.forChild(),
    CdkTableModule,
    MatTooltipModule,
  ],

  declarations: [
    DeathClaimComponent,
    MenuComponent,
    TimerComponent,
    AgentBOBHeaderComponent,
    MessageComponent,
    FilterComponent,
    WizardComponent,
    GroupUploadComponent,
    LoanHistoryTableComponent,
    FundTableComponent,
    IulFundTableComponent,
    CorrespondenceTableComponent,
    CallDetailComponent,
    BannerAlertComponent,
    PartyInformationComponent,
    KnowledgeCenterTableComponent,
    AddressUpdateComponent,
    CustomFieldComponent,
    TableFilterComponent,
    AceFormRequestComponent,
    CorrespondenceFaxComponent,
    CorrespondenceEmailComponent,
    TwoDigitDecimaNumberDirective,
    CustomPolicyPortfolioComponent,
    AgentBookOfBusinessTableComponent,
    SearchHighlightComponent,
    WithdrawalsSurrender,
    CreateDeathClaimComponent,
    ClaimNotificationDeceasedInformationComponent,
    AdditionalClaimInformationComponent,
    PolicyPortfolioComponent,
    ClaimPartyInformationComponent,
    DeathClaimInformationComponent,
    AssociatedPoliciesComponent,
    AdditionalPoliciesComponent,
    PolicySearchComponent,
    WithdrawlSurrenderQuote,
    WithdrawlSurrenderSurrender,
    WithdrawlSurrenderPayment,
    WithdrawlSurrenderConfirmation,
    SystematicWithdrawlComponent,
    EobDetailsComponent,
    HighlightSearchPipe,
    GroupPolicyDropdownComponent,
    GroupPolicyValuesTab,
    GroupLoanHistoryTableComponent,
    GroupValuesAssetTableComponent,
    PolicyNotesComponent,
    WorkRequestHistoryComponent,
    WorkRequestLogHistoryComponent,
    LoanInitiationComponent,
    QuoteLoanInitiationComponent,
    PaymentLoanInitiationComponent,
    LoanInfoLoanInitiationComponent,
    ConfirmationLoanInitiationComponent,
    NumberMaskDirective,
    DynamicFormFieldComponent,
    AssetAccountDetailsComponent,
    CustomTableComponent,
    PaymentHistoryComponent,
    ProgramEnrollmentTabComponent,
    OutgoingMoneyComponent,
    DividendHistoryComponent,
    IncomingMoneyComponent,
    HistoricalCoiComponent,
    FundsComponent,
    PlanReportingComponent,
    PlanReportComponent,
    FundTransaction,
    FundTransferInformationComponent,
    FutureAllocationChangeComponent,
    RebalancingComponent,
    DollarCostAveragingComponent,
    ConfirmationComponent,
    GroupPolicyLoanQuoteDetailsComponent,
    AnnuityContractManagementComponent,
    CheckHistoryAnnuityComponent,
    LazyLoadComponent,
    FullSurrenderQuoteComponent,
    FullSurrenderSurrenderComponent,
    RMDComponent,
    RMDQuoteComponent,
    RMDTabComponent,
    RMDPaymentComponent,
    RMDConfirmationComponent,
    PartialSurrenderQuoteComponent,
    PartialSurrenderSurrenderComponent,
    FutureFundTableComponent,
    CurrentFundTableComponentComponent,
    FundTableAnnuityComponent,
    LoantabannuityComponent,
    PartialSurrenderSurrenderMRPSComponent,
    ClaimsTableComponent,
    SystematicWithdrawalWithdrawalComponent,
    WorkrequestRefreshComponent,
    MFAComponent,
    CloseCallFilterComponent
  ],
  exports: [
    DeathClaimComponent,
    MenuComponent,
    TimerComponent,
    AgentBOBHeaderComponent,
    MessageComponent,
    FilterComponent,
    WizardComponent,
    GroupUploadComponent,
    LoanHistoryTableComponent,
    FundTableComponent,
    IulFundTableComponent,
    CorrespondenceTableComponent,
    CallDetailComponent,
    BannerAlertComponent,
    PartyInformationComponent,
    KnowledgeCenterTableComponent,
    AddressUpdateComponent,
    CustomFieldComponent,
    TableFilterComponent,
    AceFormRequestComponent,
    CorrespondenceFaxComponent,
    CorrespondenceEmailComponent,
    TwoDigitDecimaNumberDirective,
    CustomPolicyPortfolioComponent,
    AgentBookOfBusinessTableComponent,
    SearchHighlightComponent,
    WithdrawalsSurrender,
    CreateDeathClaimComponent,
    ClaimNotificationDeceasedInformationComponent,
    AdditionalClaimInformationComponent,
    PolicyPortfolioComponent,
    ClaimPartyInformationComponent,
    DeathClaimInformationComponent,
    AssociatedPoliciesComponent,
    AdditionalPoliciesComponent,
    PolicySearchComponent,
    WithdrawlSurrenderQuote,
    WithdrawlSurrenderSurrender,
    WithdrawlSurrenderPayment,
    WithdrawlSurrenderConfirmation,
    SystematicWithdrawlComponent,
    EobDetailsComponent,
    HighlightSearchPipe,
    GroupPolicyDropdownComponent,
    GroupPolicyValuesTab,
    GroupLoanHistoryTableComponent,
    GroupValuesAssetTableComponent,
    PolicyNotesComponent,
    WorkRequestHistoryComponent,
    WorkRequestLogHistoryComponent,
    LoanInitiationComponent,
    QuoteLoanInitiationComponent,
    PaymentLoanInitiationComponent,
    LoanInfoLoanInitiationComponent,
    ConfirmationLoanInitiationComponent,
    NumberMaskDirective,
    DynamicFormFieldComponent,
    AssetAccountDetailsComponent,
    CustomTableComponent,
    PaymentHistoryComponent,
    ProgramEnrollmentTabComponent,
    OutgoingMoneyComponent,
    DividendHistoryComponent,
    IncomingMoneyComponent,
    HistoricalCoiComponent,
    FundsComponent,
    PlanReportingComponent,
    PlanReportComponent,
    FundTransaction,
    FundTransferInformationComponent,
    FutureAllocationChangeComponent,
    RebalancingComponent,
    DollarCostAveragingComponent,
    ConfirmationComponent,
    GroupPolicyLoanQuoteDetailsComponent,
    AnnuityContractManagementComponent,
    CheckHistoryAnnuityComponent,
    LazyLoadComponent,
    FullSurrenderQuoteComponent,
    FullSurrenderSurrenderComponent,
    RMDComponent,
    RMDQuoteComponent,
    RMDTabComponent,
    RMDPaymentComponent,
    RMDConfirmationComponent,
    PartialSurrenderQuoteComponent,
    PartialSurrenderSurrenderComponent,
    FutureFundTableComponent,
    CurrentFundTableComponentComponent,
    FundTableAnnuityComponent,
    LoantabannuityComponent,
    PartialSurrenderSurrenderMRPSComponent,
    ClaimsTableComponent,
    SystematicWithdrawalWithdrawalComponent,
    WorkrequestRefreshComponent,
    MFAComponent,
    CloseCallFilterComponent
  ],
  providers: [CommonService, HighlightSearchPipe],
})
export class SharedModule {}
