﻿import { StatusReport } from './../components/RMD/constant';
import { OcInfraModule, APICallerService, Configuration, NavigationService, IAlias, ResourceService, ResourceSchemaService, OptionParams, ResourceDirectoryService } from '@diaas/ux-web';
import { CommonFactory } from './commonFactory';
import { CommonService } from '../services/common.service';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { WithdrawalSurrenderAction } from '../components/withdrawlsSurrender/withdrawalSurrenderAction';
import * as _ from 'lodash';
import { value } from 'jsonpath';

export class PartySearchFactory extends CommonFactory {

    savedProperties: any = {};
    checkedProperties: any = {};
    requiredPayload: any = {};
    authResponse: any = {};
    otherRolePayload: any = {};
    closeCallPayLoad: any = {};
    html = '';
    coveragehtml = '';
    coveragerArray: any;
    covereages: any = [];
    selectdValues = [];
    currentChecked = {};
    selectedSuspence = {};
    statusMsg = '';
    comments = '';
    res: any = {};
    searchResults: any = [];

    currFutFunds : any = [];
    rmdGroup : any = [];

    zipExists: boolean;
    commonService = OcInfraModule.AppInjector.get(CommonService);

    navigateTo(params) {
        super.navigateTo(params);
    }

    public resourceService = OcInfraModule.AppInjector.get(ResourceService);

    async openPolicyDetails(params) {
        OcInfraModule.AppInjector.get(CommonService).agentPolicyDetailAlert = false;
        let isClientId = OcInfraModule.AppInjector.get(CommonService).getNested(params, 'summary', 'clientId');
        let isMsg = OcInfraModule.AppInjector.get(CommonService).getNested(params, 'summary','msg');
        if (!isClientId && !isMsg && isMsg !="") {
            let alertMsg = [];
            let popUpMsg = params.summary.msg.split(".")
            alertMsg.push(params.summary.msg.substring(params.summary.msg.indexOf("."), 0) + '.', popUpMsg[1].substring(popUpMsg[1].indexOf(":"), 0) + ':')
            alertMsg.push(params.summary.contactLabel1 ? params.summary.contactLabel1 + ": " + params.summary.contactNumber1 : '', params.summary.contactLabel2 ? params.summary.contactLabel2 + ": " + params.summary.contactNumber2 : '', params.summary.contactLabel3 ? params.summary.contactLabel3 + ": " + params.summary.contactNumber3 : '');
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
            dialogRef.afterClosed().subscribe(res => {
                res;
            });
        } else {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
            OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
            OcInfraModule.AppInjector.get(CommonService).productInfo = params.summary;
            OcInfraModule.AppInjector.get(CommonService).isAnnuity = params.summary.product_type == 'A1' || params.summary.product_type == 'A2';
            const navigationParams: any = {};

            Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
                (headerObj.headers.hasOwnProperty("product_type") && headerObj.headers.product_type != null) ? headerObj.headers.product_type = params.summary.product_type : '';
                (headerObj.headers.hasOwnProperty("client_id") && headerObj.headers.client_id != null) ? headerObj.headers.client_id = params.summary.client ? params.summary.client :'' : '';
                (headerObj.headers.hasOwnProperty("role_name") && headerObj.headers.role_name != null) ? headerObj.headers.role_name = "owner" : '';
                (headerObj.headers.hasOwnProperty("user_role") && headerObj.headers.user_role != null) ? headerObj.headers.user_role = OcInfraModule.AppInjector.get(CommonService).userRole.toUpperCase() : '';
                (headerObj.headers.hasOwnProperty("caller_role") && headerObj.headers.caller_role != null) ? headerObj.headers.caller_role = params.summary.role : '';
                (headerObj.headers.hasOwnProperty("region_code") && headerObj.headers.region_code != null) ? headerObj.headers.region_code = params.summary.REGION : 'PANO';
                (headerObj.headers.hasOwnProperty("wma_user") && headerObj.headers.wma_user != null) ? headerObj.headers.wma_user = window.sessionStorage.getItem('username') : '';
                (headerObj.headers.hasOwnProperty("statuaory_company_code") && headerObj.headers.statuaory_company_code != null) ? headerObj.headers.statuaory_company_code = params.summary.statutory_company_code : '';
                (headerObj.headers.hasOwnProperty("company_code") && headerObj.headers.company_code != null) ? headerObj.headers.company_code  = params.summary.company_code : '';
                (headerObj.headers.hasOwnProperty("duplicate_policy_ind") && headerObj.headers.duplicate_policy_ind != null) ? headerObj.headers.duplicate_policy_ind =(params.summary.duplicate_policy_ind ? (params.summary.duplicate_policy_ind).toString():'') : '';
            });
            navigationParams.url = 'policyDetails';
            navigationParams.link = params.href;
            navigationParams.alias = 'policyDetails';
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let optionParams = new OptionParams();
            optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("policyDetails");
            optionParams.alias = "policyDetails";   
            // PANOCSR-3491 changes
            let productInfo =OcInfraModule.AppInjector.get(CommonService).productInfo;
            if(productInfo.company_code == 'MET' || productInfo.company_code == 'MTL'){
                OcInfraModule.AppInjector.get(CommonService).mfaApplicable= true;
            }else{
                OcInfraModule.AppInjector.get(CommonService).mfaApplicable= false;
                OcInfraModule.AppInjector.get(CommonService).mfaAuthorized = true;
            }       
            OcInfraModule.AppInjector.get(APICallerService).get(navigationParams.link, optionParams).subscribe(async response => {
                 resourceService.updateCreateAliasName('policyDetails', navigationParams.link, true, optionParams.headers);
                 await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                super.navigateTo(navigationParams);
                this.groupAnnuityProcess(params, customBanner);
            }, async (error: any) => {
                    await OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                    super.navigateTo(navigationParams);
            });

            // super.navigateTo(navigationParams);
            this.getPolicyCallHistory(params, 'contact_policy_history_url');
            this.getCustomIdentity(params);
             this.alertMessages(params);
            this.setPaymentsUrl();
            this.resetPolicyResources();

            let customBanner;
            let hContractUrl = _.filter(params.summary.links, function (o) { return o.rel == "Hcontracts" });
            if(hContractUrl && hContractUrl.length > 0 && hContractUrl[0].href){
                    let optionParams = new OptionParams();
                    optionParams.alias = 'Hcontracts';
                    optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");
                    OcInfraModule.AppInjector.get(APICallerService).refresh(hContractUrl[0].href, optionParams, 'Reset').subscribe(response => {       
                });
             }
            let alertMsgUrl = _.filter(params.summary.links, function (o) { return o.rel == "alert_Messages_url" });
            const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");
            if (alertMsgUrl.length && alertMsgUrl[0].href) {
                OcInfraModule.AppInjector.get(CommonService).getCallback(alertMsgUrl[0].href, headers).subscribe(responce => {
                    let status_report = {
                        "consistent": true,
                        "messages": [
                            {
                                "message": responce._embedded && responce._embedded.status_report && responce._embedded.status_report.messages.length ? responce._embedded.status_report.messages[0].message : '',
                                "severity": "ALERT"
                            }
                        ],
                        url: params.href,
                        alias: "policyDetails"
                    }
                    setTimeout(() => {
                        responce._embedded && responce._embedded.status_report && responce._embedded.status_report.messages.length ? OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(status_report) : null;
                    }, 7000);
                });
            }

            params.summary && params.summary.duplicatePolicyMsg && params.summary.duplicatePolicyMsg != '' ? customBanner = setInterval(function () {
                const resourceService = OcInfraModule.AppInjector.get(ResourceService);
                let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
                if (policyDetails) {
                    let status_report = {
                        "consistent": true,
                        "messages": [
                            {
                                "message": params.summary.duplicatePolicyMsg,
                                "severity": "ALERT"
                            }
                        ],
                        url: params.href,
                        alias: "policyDetails"
                    }
                    OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(status_report);
                    clearInterval(customBanner);
                }
            }, 500) : '';                    
        }
    }

    groupAnnuityProcess = (params: any, customBanner: any) => {
        setTimeout(async () => {
            let policyDetailss =  await OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');            
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            if (this.isPolicyAvailable()) {
               
                var allUrls = OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails();
                if (allUrls["assetaccountvalues"] && allUrls["assetaccountvalues"].href) {

                    let optionParams = new OptionParams();
                    optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("groupAssetValue");
                    let assetaccountvaluesUrl = allUrls["assetaccountvalues"].href;
                    await OcInfraModule.AppInjector.get(CommonService).getCallback(assetaccountvaluesUrl, optionParams.headers).subscribe(response => {
                        if(response){
                            OcInfraModule.AppInjector.get(CommonService).assetAccountValuesResp =  response;
                        }
                    }
                    );

                    
                }
                if (this.isOnlyGroupAnn()) {
                    const  callHeaders = { ...{ wma_user: window.sessionStorage.getItem('username'), client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client }, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search") };

                    if( allUrls['clientproducts'] &&  allUrls['clientproducts'].href){
                        let getPlanCodeUrl =  allUrls['clientproducts'].href;
                        
                        OcInfraModule.AppInjector.get(CommonService).getCallback(getPlanCodeUrl, callHeaders).subscribe(response => {
                            if (response && response._links) {
                                OcInfraModule.AppInjector.get(CommonService).clientProducts =  response._links.item;
                            }
                            
                        }, error => {
                            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
                            }
                        });
                    }

                    if(allUrls['clientassetaccounts']){
                        const getClientAssetAccountUrl =allUrls['clientassetaccounts'].href ;
                        OcInfraModule.AppInjector.get(CommonService).getCallback( getClientAssetAccountUrl, callHeaders).subscribe(response => {
                            OcInfraModule.AppInjector.get(CommonService).clientAssetAccounts = response._links.item;
                          }, error => {
                            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                              OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
                            }
                          });

                          const  callHeadersNew = { ...{ wma_user: window.sessionStorage.getItem('username'), client_id: OcInfraModule.AppInjector.get(CommonService).productInfo.client }, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search"), isFinancialHistory : true };
                          OcInfraModule.AppInjector.get(CommonService).getCallback( getClientAssetAccountUrl, callHeadersNew).subscribe(response => {
                            OcInfraModule.AppInjector.get(CommonService).clientAssetAccountsAll = response._links.item;
                          }, error => {
                            if (error && error._embedded && error._embedded.status_report && error._embedded.status_report.consistent === false) {
                              OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0);
                            }
                          });
                    }
                }

                let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
                if (policyDetails._embedded && policyDetails._embedded.status_report) {
                    let message = policyDetails._embedded.status_report.messages;
                    message.forEach((obj) => {
                        obj.severity = (obj.severity).toUpperCase();
                    })
                    let status_report = {
                        "consistent": true,
                        "messages": message,
                        url: params.href,
                        alias: "policyDetails"
                    }
                    OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(status_report);
                    clearInterval(customBanner);
                }
            }
         
            let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
            if (this.isOnlyGroupAnn()) {
                OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('policyRevolvingError', policyDetails._links.revolvingError.href + policyDetails.data.client_id, true)
               }
            else {
                OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('policyRevolvingError', policyDetails._links.revolvingError.href, true);
                OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('policyValues', policyDetails._links.financialvalues.href, true);
            }

        }, 500);
    }

    /* This function is used for open Agent details */
    openAgentDetails(params) {
        OcInfraModule.AppInjector.get(CommonService).selectedAgentSummary = params.summary;
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        OcInfraModule.AppInjector.get(CommonService).productInfo = params.summary;
        const navigationParams: any = {};
        Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
            (headerObj.headers.hasOwnProperty("product_type") && headerObj.headers.product_type != null) ? headerObj.headers.product_type = params.summary && params.summary.product_type ? params.summary.product_type : 'U1' : '';
            (headerObj.headers.hasOwnProperty("role_name") && headerObj.headers.role_name != null) ? headerObj.headers.role_name = "Agent" : '';
            (headerObj.headers.hasOwnProperty("region_code") && headerObj.headers.region_code != null) ? headerObj.headers.region_code = params.summary && params.summary.REGION ? params.summary.REGION : 'BHF1' : '';
            (headerObj.headers.hasOwnProperty("statuaory_company_code") && headerObj.headers.statuaory_company_code != null) ? headerObj.headers.statuaory_company_code = "105" : '';
            (headerObj.headers.hasOwnProperty("user_role") && headerObj.headers.user_role != null) ? headerObj.headers.user_role = OcInfraModule.AppInjector.get(CommonService).userRole : '';
            (headerObj.headers.hasOwnProperty("caller_role") && headerObj.headers.caller_role != null) ? headerObj.headers.caller_role = 'Agent' : '';
        });
        navigationParams.url = 'agentDetails';
        navigationParams.alias = 'agentDetails';
        super.navigateTo(navigationParams);
        this.getPolicyCallHistory(params, 'agent_contact_history_url');
        this.getAgentRoleDetais(params);
        this.alertBannerMessages(params);
        this.resetPolicyResources();
    }

    /* This function is used for open Customer details */
    openCustomerDetails(params) {
        let isClientId = OcInfraModule.AppInjector.get(CommonService).getNested(params, 'summary', 'clientId');
        let isMsg = OcInfraModule.AppInjector.get(CommonService).getNested(params, 'summary','msg');
        if (!isClientId && isMsg && isMsg != '') {
            let alertMsg = [];
            let popUpMsg = params.summary.msg.split(".")
            alertMsg.push(params.summary.msg.substring(params.summary.msg.indexOf("."), 0) + '.', popUpMsg[1].substring(popUpMsg[1].indexOf(":"), 0) + ':')
            alertMsg.push(params.summary.contactLabel1 ? params.summary.contactLabel1 + ":" + params.summary.contactNumber1 : '', params.summary.contactLabel2 ? params.summary.contactLabel2 + ":" + params.summary.contactNumber2 : '', params.summary.contactLabel3 ? params.summary.contactLabel3 + ":" + params.summary.contactNumber3 : '');
            let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
            dialogRef.afterClosed().subscribe(res => {
                res;
            });
        } else {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
            OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
            OcInfraModule.AppInjector.get(CommonService).customerDetails = params;
            OcInfraModule.AppInjector.get(CommonService).productInfo = params.summary;
            const navigationParams: any = {};
            Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
                (headerObj.headers.hasOwnProperty("product_type") && headerObj.headers.product_type != null) ? headerObj.headers.product_type = params.summary && params.summary.product_type ? params.summary.product_type : 'U1' : '';
                (headerObj.headers.hasOwnProperty("role_name") && headerObj.headers.role_name != null) ? headerObj.headers.role_name = "Owner" : '';
                (headerObj.headers.hasOwnProperty("region_code") && headerObj.headers.region_code != null) ? headerObj.headers.region_code = params.summary && params.summary.REGION ? params.summary.REGION : 'BHF1' : '';
                (headerObj.headers.hasOwnProperty("statuaory_company_code") && headerObj.headers.statuaory_company_code != null) ? headerObj.headers.statuaory_company_code = "105" : '';
                (headerObj.headers.hasOwnProperty("user_role") && headerObj.headers.user_role != null) ? headerObj.headers.user_role = OcInfraModule.AppInjector.get(CommonService).userRole : '';
                (headerObj.headers.hasOwnProperty("caller_role") && headerObj.headers.caller_role != null) ? headerObj.headers.caller_role = 'Owner' : '';
            });
            navigationParams.url = 'customerDetails';
            navigationParams.alias = 'customerDetails';
            super.navigateTo(navigationParams);
            this.getPolicyCallHistory(params, 'customer_contact_history_url');
            this.getCustomerRoleDetails(params);
            this.alertBannerMessages(params);
            this.resetPolicyResources();
            let isduplicatePolicyMsg = OcInfraModule.AppInjector.get(CommonService).getNested(params, 'summary','duplicatePolicyMsg');
            if (isduplicatePolicyMsg && isduplicatePolicyMsg != '') {
                let status_report = {
                    "showAll": true,
                    "consistent": true,
                    "messages": [
                        {
                            "message": params.summary.duplicatePolicyMsg,
                            "severity": "ALERT"
                        }
                    ],
                    url: params.href,
                    alias: "customerDetails"
                }
                // to make sure  customerDetails view is loaded  before banner shows up.
                setTimeout(() => {
                    OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(status_report);
                }, 700);
            }
        }
    }

    policyDetailsDisable(): boolean {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetailsWidget = resourceService.getResourceFromAliasName('policyDetails');
        if (policyDetailsWidget && OcInfraModule.AppInjector.get(CommonService).termAndFia.indexOf(policyDetailsWidget.response.product_type) != -1) {
            OcInfraModule.AppInjector.get(CommonService).disableTermandFia = policyDetailsWidget.response.product_type == "A2" ? policyDetailsWidget.response.plan_code == "FIA001" ? true : false : OcInfraModule.AppInjector.get(CommonService).termAndFia.indexOf(policyDetailsWidget.response.product_type) != -1 ? true : false;;
            return policyDetailsWidget.response.product_type == "A2" ? policyDetailsWidget.response.plan_code == "FIA001" ? true : false : OcInfraModule.AppInjector.get(CommonService).termAndFia.indexOf(policyDetailsWidget.response.product_type) != -1 ? true : false;
        } else {
            OcInfraModule.AppInjector.get(CommonService).disableTermandFia = false;
            return false;
        }
    }

    financialHistoryDisable(): boolean {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let financialHistWidget = resourceService.getResourceFromAliasName('policyDetails');
        let productInfo = this.commonService.productInfo;
        if (productInfo && productInfo.product_type && productInfo["product_type"].toLowerCase() == "h") {
            return true;
        } else if (financialHistWidget && OcInfraModule.AppInjector.get(CommonService).termAndFia.indexOf(financialHistWidget.response.product_type) != -1) {
            return financialHistWidget.response.product_type == "A2" ? financialHistWidget.response.plan_code == "FIA001" ? true : false : OcInfraModule.AppInjector.get(CommonService).termAndFia.indexOf(financialHistWidget.response.product_type) != -1 ? true : false;
        }
        else {
            return false;
        }
    }

    resetPolicyResources(): void {
        let resourceDirectory: any = JSON.parse(window.sessionStorage.getItem("resourceDirectory"));
        if (resourceDirectory) {
            const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
            resourceDirectory.forEach(resource => {
                if (resource.alias !== "callHistory" || resource.alias !== "instances" || resource.alias !== "searchContracts") {
                    resourceDirectoryService.deleteElementResourceDirectory(resource.href);
                }
            });
        }
    }

    setPaymentsUrl() {
        let PaymentsUrl = setInterval(function () {
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
            if (policyDetails) {
                let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'payments' });
                if (header.length > 0 && header[0].headers) {
                    header[0].headers.region_code = OcInfraModule.AppInjector.get(CommonService).productInfo.REGION;
                }
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
                OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('payments', policyDetails._links.claims.href + '/payments', true);
                clearInterval(PaymentsUrl);
            }
        }, 500);
    }

    refreshPolicyDetails() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
            if (headerObj.headers.hasOwnProperty("remote_user") && headerObj.alias == 'refreshPolicyDetails') {
                headerObj.headers.remote_user = null;
                headerObj.headers.role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
            }
        });
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'refreshPolicyDetails' });
        OcInfraModule.AppInjector.get(CommonService).changeHeaders(header[0].headers);
        let refreshPolicyDetails = setInterval(function () {
            let authorizeSucess = resourceService.getResourceFromAliasName('authorizeSucess');
            if (authorizeSucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess) {
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
                let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
                let key = Object.keys(policyDetails._links);
                let refreshAlias = ["billing"]
                key.forEach(linksObj => {
                    if (refreshAlias.indexOf(linksObj) != -1) {
                        header[0].headers["roleCode"] = "own";
                        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
                        OcInfraModule.AppInjector.get(ResourceDirectoryService).deleteElementResourceDirectory(policyDetails._links[linksObj].href);
                        OcInfraModule.AppInjector.get(APICallerService).get(policyDetails._links[linksObj].href).subscribe(response => {
                            let patch = _.filter(response._options.links, function (o) { return o.method == 'PATCH' })
                            if (patch.length > 0) {
                                OcInfraModule.AppInjector.get(CommonService).billingPatchHref = patch[0].href;
                                OcInfraModule.AppInjector.get(CommonService).showEditIcon(linksObj, true);
                            } else {
                                OcInfraModule.AppInjector.get(CommonService).showEditIcon(linksObj, false);
                            }
                        });
                    }
                });
            }
            clearInterval(refreshPolicyDetails);
        }, 500);
    }

    alertMessages(params) {
        let alertMsgUrl = _.filter(params.summary.links, function (o) { return o.rel == "alert_Messages_url" });
        const headers = Configuration.config.headers.defaultHeader;
        if (alertMsgUrl.length && alertMsgUrl[0].href) {
            OcInfraModule.AppInjector.get(CommonService).getCallback(alertMsgUrl[0].href, headers).subscribe(responce => {
               if(responce && responce._embedded && responce._embedded.status_report){
                responce._embedded.status_report.url = params.href;
                responce._embedded.status_report.alias = "policyDetails";
                responce._embedded.status_report.showAll = true;
                let policyCsrAlert = setInterval(function () {
                    if (OcInfraModule.AppInjector.get(CommonService).agentPolicyDetailAlert) {
                        OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(responce._embedded.status_report);
                        clearInterval(policyCsrAlert);
                    }
                }, 500);
              }
            });
        }     
    }

    alertBannerMessages(params) {
        OcInfraModule.AppInjector.get(CommonService).newBannerAlertAarray = [];
        OcInfraModule.AppInjector.get(CommonService).alertMessaggeHideShow.next();
        OcInfraModule.AppInjector.get(CommonService).agentBannerAlertMessage = '';
        let alertMsgUrl = _.filter(params.summary.links, function (o) { return o.rel == "alert_Messages_url" });
        const headers = Configuration.config.headers.defaultHeader;
        if (alertMsgUrl.length && alertMsgUrl[0].href) {
            OcInfraModule.AppInjector.get(CommonService).getCallback(alertMsgUrl[0].href, headers).subscribe(responce => {
                responce._embedded.status_report.url = OcInfraModule.AppInjector.get(CommonService).searchAgentUrl;
                responce._embedded.status_report.alias = "searchAgents";
                responce._embedded.status_report.showAll = true;
                OcInfraModule.AppInjector.get(CommonService).agentBannerAlertMessage = responce._embedded.status_report;
                OcInfraModule.AppInjector.get(CommonService).pushAlertBannerMessage(responce._embedded.status_report);
                OcInfraModule.AppInjector.get(CommonService).alertMessaggeHideShow.next();
            });
        }
    }

    showBillingEditIcon() {
        return OcInfraModule.AppInjector.get(CommonService).billing;
    }

    showBlueBar() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
        let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
        let billing = resourceService.getResourceFromAliasName('billingInfo');
        let check = this.payorRoleEditIcon();
        if (check) {
            return false;
        } else if ((billing && billing.response && selectedIdentity.data.role != 'Payor') || (!authsucess && selectedIdentity.data.role == 'Payor')) {
            return true;
        } else return true;
    }

    showNotesBlueBar() {
        let addButtonDisplay = this.annuitiesAddButton();
        if(addButtonDisplay) {
            return false;
        } else {
            return true;
        }
    }

    showMesseage(params) {
        let valuesMesseage;
        // to handle multiple messages scenario for groups
        if (this.isOnlyGroupAnn && document.getElementsByClassName("valuesMesseage").length > 1) {
            for (let i = 0; i < document.getElementsByClassName("valuesMesseage").length; i++) {
                valuesMesseage += document.getElementsByClassName("valuesMesseage")[i];
            }
        } else {
            valuesMesseage = document.getElementsByClassName("valuesMesseage")[0];
        }
        if (valuesMesseage) {
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let fincialValues = resourceService.getResourceFromAliasName(params.alias);
            if (fincialValues && fincialValues.response._embedded && fincialValues.response._embedded.status_report && fincialValues.response._embedded.status_report.messages.length>0) {
                fincialValues._embedded.status_report.messages.forEach(msgObj => {
                    this.statusMsg += '<ul class="message-loan">' + '<li>' + msgObj.message + '</li>' + '</ul>';
                });
                valuesMesseage.innerHTML = '<ul class="message-loan">' + '<li>' + this.statusMsg + '</li>' + '</ul>';
                OcInfraModule.AppInjector.get(CommonService).valuesMesseage = '<ul class="message-loan">' + '<li>' + this.statusMsg + '</li>' + '</ul>';
            }
        }
        return true;
    }

    showLoanMesseage(param) {
        let loanMessageElement = document.getElementsByClassName("loanMesseage")[0];
        let loanMesseage = OcInfraModule.AppInjector.get(CommonService).loanMessageId
        if (loanMesseage && loanMessageElement) {
            if (loanMesseage && loanMesseage.response._embedded) {
                loanMesseage._embedded.status_report.messages.forEach(msgObj => {
                    this.statusMsg += '<ul class="message-loan">' + '<li>' + msgObj.message + '</li>' + '</ul>';
                });
                loanMessageElement.innerHTML = '<ul class="message-loan">' + '<li>' + this.statusMsg + '</li>' + '</ul>';
                OcInfraModule.AppInjector.get(CommonService).loanMesseage = '<ul class="message-loan">' + '<li>' + this.statusMsg + '</li>' + '</ul>';
            }
        }
        return true;
    }
    deliveryMethod(field) {
        let delivary = OcInfraModule.AppInjector.get(CommonService).deliveryMethod;
        field.value == "" ? field.value = "Mail" : '';
        return delivary;
    }

    checkedQuoteValuesMesseage(field) {
        this.policyValues(field, true);
    }

    showQuoteValuesFinalHtml() {
        if (OcInfraModule.AppInjector.get(CommonService).quoteMesseageHtml != '' || OcInfraModule.AppInjector.get(CommonService).quoteValuesHtml != '') {
            OcInfraModule.AppInjector.get(CommonService).quoteValuesFinalHtml = '<span style="font-size:15px" class="pad-lAr-10px">POLICY VALUES</span><dl class="pad-lAr-10px">' + OcInfraModule.AppInjector.get(CommonService).quoteMesseageHtml + OcInfraModule.AppInjector.get(CommonService).quoteValuesHtml + '</dl>';
        } else {
            OcInfraModule.AppInjector.get(CommonService).quoteValuesFinalHtml = '';
        }

    }

    checkedLoanMesseage(field) {
        this.loanValues(field, true);
    }

    getCustomIdentity(params) {
        let selectedRole = _.filter(params.summary.links, function (o) { return o.rel == "authorize_url" });
        OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('roles', selectedRole[0].href, true);
        OcInfraModule.AppInjector.get(APICallerService).get(selectedRole[0].href).subscribe(response => {
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let optionParams = new OptionParams();
            optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");
            let selectedRole = _.filter(response._links.item, function (o) { return o.summary.clientId == params.summary.clientId; });
            OcInfraModule.AppInjector.get(CommonService).requestedParams = optionParams;
            OcInfraModule.AppInjector.get(CommonService).selectedRoleFirst = selectedRole;
            resourceService.updateCreateAliasName('selectedRoles', selectedRole[0].href, true, optionParams);
            if (selectedRole[0].summary.partType === 'corporate' && response && response._embedded && response._embedded.status_report) {
                response._embedded.status_report.showAll = true;
                OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(response._embedded.status_report);
            } else {
                OcInfraModule.AppInjector.get(CommonService).corporateOwner = false;
                OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
            }

            // adding api call to get group name using group number
            let groupDetailsObj = _.filter(response._links.item, function (o) { return o.summary.groupNumber})
            if(groupDetailsObj.length > 0){                
                if(groupDetailsObj[0].summary && groupDetailsObj[0].summary._links && groupDetailsObj[0].summary._links.self.href){
                    resourceService.updateCreateAliasName('selectedGroup', groupDetailsObj[0].summary._links.self.href + "?groupNumber=" + groupDetailsObj[0].summary.groupNumber, true);
                }
            }

            OcInfraModule.AppInjector.get(CommonService).selectedRole = selectedRole[0].summary.role;
            OcInfraModule.AppInjector.get(CommonService).clientId = selectedRole[0].summary.clientId;
        });
    }

    getAgentRoleDetais(params) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let optionParams = new OptionParams();
        let customIdentity = _.filter(OcInfraModule.AppInjector.get(CommonService).selectedAgentSummary.links, function (o) { return o.rel == "authorize_url" });
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("roles");
        optionParams.alias = "roles"
        OcInfraModule.AppInjector.get(APICallerService).refresh(customIdentity[0].href, optionParams, 'Reset').subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).agentRoleDetails = response;
            resourceService.updateCreateAliasName('selectedRoles', response.response._links.item[0].href, true, optionParams.headers);
        });
    }

    getCustomerRoleDetails(params) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let optionParams = new OptionParams();
        let customIdentity = _.filter(params.summary.links, function (o) { return o.rel == "authorize_url" });
        OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('roles', customIdentity[0].href, true);
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("roles");
        optionParams.alias = "roles"
        OcInfraModule.AppInjector.get(APICallerService).get(customIdentity[0].href).subscribe(response => {
            let selectedRole = _.filter(response._links.item, function (o) { return o.summary.clientId == params.summary.clientId; });
            resourceService.updateCreateAliasName('selectedRoles', selectedRole[0].href, true, optionParams.headers);
            OcInfraModule.AppInjector.get(CommonService).customerRoleDetails = selectedRole;
            OcInfraModule.AppInjector.get(CommonService).selectedRole = selectedRole[0].summary.role;
        });
    }

    getPolicyCallHistory(params, rel) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
        let optionParams = new OptionParams();
        if (params.summary) {
            const aliasToDelete: IAlias = resourceService.getAliasByAliasName('policyCallHistory');
            if (aliasToDelete) {
                resourceDirectoryService.deleteElementResourceDirectory(OcInfraModule.AppInjector.get(CommonService).policyHistoryUrl);
                resourceService.updateCreateAliasName(aliasToDelete.id, '', true);
            }
            this.commonService.checkHistoryUrl = params;
            let selectedPolicy = _.filter(params.summary.links, function (o) { return o.rel == rel });
            OcInfraModule.AppInjector.get(CommonService).policyHistoryUrl = selectedPolicy[0].href.split('?')[0];
            let newhref = selectedPolicy[0].href.substr(0, selectedPolicy[0].href.lastIndexOf('?'));
            let optionParams = new OptionParams();
            optionParams.params = [];
            const pairs = selectedPolicy[0].href.split('?')[1].split('&');
            pairs.forEach(pair => {
                const queryParam = pair.split('=');
                optionParams.params.push({ key: queryParam[0], value: queryParam[1] });
            });
            optionParams.alias='policyCallHistory';
            optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");
            OcInfraModule.AppInjector.get(APICallerService).refresh(selectedPolicy[0].href.split('?')[0], optionParams).subscribe(callHistoryResponse => {
            if(callHistoryResponse && callHistoryResponse._embedded && callHistoryResponse._embedded.status_report){
                callHistoryResponse._embedded.status_report.showAll = true;
                callHistoryResponse._embedded.status_report.alias = 'policyCallHistory';
                callHistoryResponse._embedded.status_report.id = 'policyCallHistory';
                //  OcInfraModule.AppInjector.get(CommonService).newBannerAlertAarray = [];
                //  OcInfraModule.AppInjector.get(CommonService).pushAlertBannerMessage(callHistoryResponse._embedded.status_report);
                //  OcInfraModule.AppInjector.get(CommonService).alertMessaggeHideShow.next();
                 }
            });
        }
        else {
            const aliasToDelete: IAlias = resourceService.getAliasByAliasName('policyCallHistory');
            resourceDirectoryService.deleteElementResourceDirectory(aliasToDelete.href);
            resourceService.updateCreateAliasName(aliasToDelete.id, '', true);
            OcInfraModule.AppInjector.get(ResourceService).updateCreateAliasName('policyCallHistory', OcInfraModule.AppInjector.get(CommonService).policyHistoryUrl, true, optionParams);
        }
    }

    policyCoverageDetails(params) {
        const navigationParams: any = {};
        navigationParams.url = 'policyCoverageDetails';
        navigationParams.link = params.field.href;
        super.navigateTo(navigationParams);
    }

    openHomePage(params) {
        const navigationParams: any = {};
        navigationParams.url = 'home';
        navigationParams.link = params.href;
        super.navigateTo(navigationParams);
        OcInfraModule.AppInjector.get(CommonService).myCallHistory();
        OcInfraModule.AppInjector.get(CommonService).resetPolicyDetails();
    }

    editParty(params) {
        return true
    }

    showOnlyForHClient() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let hProduct = resourceService.getResourceFromAliasName('Hcontracts');
        let isProduct_type = OcInfraModule.AppInjector.get(CommonService).getNested(hProduct, 'data', 'product_type');

        if (isProduct_type && isProduct_type == 'H') {
            return true;
        } else {
            return false;
        }
    }

    hideOnlyForHClient() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let hProduct = resourceService.getResourceFromAliasName('Hcontracts');
        let isProduct_type = OcInfraModule.AppInjector.get(CommonService).getNested(hProduct, 'data', 'product_type');
        if (isProduct_type && isProduct_type == 'H') {
            return false;
        } else {
            return true;
        }
    }  

    customIdentitySeprator(params) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
        if (selectedIdentity && !(selectedIdentity.data.partType == 'corporate') && selectedIdentity.data.role == 'Owner') {
            return true;
        } else {
            return false;
        }
    }

    corporateOwnerSeprator() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
        if (selectedIdentity && selectedIdentity.data) {
            if (selectedIdentity.data.role == 'Agent' || selectedIdentity.data.partType == 'corporate') {
                if (selectedIdentity.data.role == 'Assignee') {
                    return false;
                } else {
                    return true;
                }
            }
        }
    }
    
    agentRoleNonAnnuitySeprator() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
        if (selectedIdentity && selectedIdentity.data) {
            if ((selectedIdentity.data.role == 'Agent') && this.hideNonAnnuityFields()) {
                return true;
            } else {
                return false;
            }
        }
    }

    agentRoleAnnuitySeprator(){
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
        if (selectedIdentity && selectedIdentity.data) {
            if ((selectedIdentity.data.role == 'Agent') && this.isAnnuity()) {
                return true;
            } else {
                return false;
            }
        }
    }

    coOwnerRoleSeprator() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
        if (selectedIdentity && selectedIdentity.data) {
            if (selectedIdentity.data.role == 'Co-Owner' && !this.isAnnuity()) {
                return true;
            } else {
                return false;
            }
        }
    }
    InsuredRoleSeprator() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
        if (selectedIdentity && selectedIdentity.data) {
            if (selectedIdentity.data.role == 'Insured') {
                return true;
            } else {
                return false;
            }
        }
    }
    payorRoleSeprator() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
        if (selectedIdentity && selectedIdentity.data) {
            if (selectedIdentity.data.role == 'Payor') {
                return true;
            } else {
                return false;
            }
        }
    }

    payorRoleEditIcon() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let defaultSelectedRole = OcInfraModule.AppInjector.get(CommonService).selectedRole;

        let authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
        let selectedRole = OcInfraModule.AppInjector.get(CommonService).selectedRoleObj;
        let allRoleList = resourceService.getResourceFromAliasName('roles');
        let billing = resourceService.getResourceFromAliasName('billingInfo');
        let isPAC =  OcInfraModule.AppInjector.get(CommonService).getNested(billing,"response","bill_option");
        let PAC = isPAC == 'P';

        // Getting Owner Object
        let ownerRole = allRoleList.response._links.item.filter((obj) => {
            if ((obj.summary.role).toLowerCase() == 'owner') {
                return obj;
            }
        });
        // Getting Payor object 
        let payoyRole = allRoleList.response._links.item.filter((obj) => {
            if ((obj.summary.role).toLowerCase() == 'payor') {
                return obj;
            }
        });

        if (selectedRole.length) { // If user selected Role as Owner
            let selectedRoleObj = selectedRole[0].summary.fullName ? selectedRole[0].summary.fullName : selectedRole[0].summary.payorFullName;
            let ownerRoleSelected = (selectedRole[0].summary.role).toLowerCase().trim() == 'owner';
            let samePayerAndOwnerName = allRoleList.response._links.item.filter((obj) => {
                let name = obj.summary.fullName ? obj.summary.fullName : obj.summary.payorFullName;
                let payor = (obj.summary.role).toLowerCase() == 'payor';
                if (name.trim() == selectedRoleObj.trim() && ownerRoleSelected && payor) {
                    return obj;
                }
            });

            let selectedOwnerRole = samePayerAndOwnerName.length ? (samePayerAndOwnerName[0]['summary']['role']).toLowerCase() == 'payor' : 0;
            if ((authsucess && PAC && selectedOwnerRole) ||
                (authsucess && PAC && selectedRole[0].summary.role).toLowerCase() == 'payor') {
                return true;
            } else {
                return false;
            }
        } else if (defaultSelectedRole && defaultSelectedRole.toLowerCase() == 'owner') { // If default selcted Role as OWner

            // Checking if payor and Owner has same name
            if (ownerRole.length && payoyRole.length) {
                let ownerName = ownerRole[0].summary.fullName ? ownerRole[0].summary.fullName : ownerRole[0].summary.payorFullName;
                let payorName = payoyRole[0].summary.fullName ? payoyRole[0].summary.fullName : payoyRole[0].summary.payorFullName;
                let samePayorAndOwnerName = ownerName ? ownerName.trim() == payorName ? payorName.trim():"" : "";
                // Payor and owner has same name the checking authorize and PAC- bill_option
                if (authsucess && PAC && samePayorAndOwnerName) {
                    return true;
                }
            } else false

        } else if (defaultSelectedRole.toLowerCase() == 'payor') {
            if (authsucess && PAC && payoyRole.length) {
                return true
            } else return false;
        }
        else return false;
    }

    thirdPartyRoleSeprator() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
        if (selectedIdentity && selectedIdentity.data) {
            if (selectedIdentity.data.role == 'Third Party Administrator') {
                return true;
            } else {
                return false;
            }
        }
    }

    hideBillingFields() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let billing = resourceService.getResourceFromAliasName('policyDetails');
        if (billing && billing.response.product_type == 'T1') {
            return true;
        }
        else return false;
    }

    showListBillField() {
        if(this.isAnnuity()) {
            return true;
        } else {
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let billing = resourceService.getResourceFromAliasName('billingInfo');
            if (billing && billing.response.bill_option == 'P') {
                return false;
            } else return true;
        }
    }

    showBillingFields() {
        if(this.isAnnuity()) {
            return false;
        } else {
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let billing = resourceService.getResourceFromAliasName('billingInfo');
            if (billing && billing.response.bill_option == 'P') {
                return true;
            } else return false;
        }
    }

    showBankFields() {       
      const resourceService = OcInfraModule.AppInjector.get(ResourceService);
      let billing = resourceService.getResourceFromAliasName('billingInfo');
      if (billing && billing.response.bill_option == 'P') {
          return true;
      } else return false;        
    }

    showBankingFields(){
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let billing = resourceService.getResourceFromAliasName('billingInfo');
        if (billing && billing.response.bill_option == 'P' && this.isAnnuity()) {
            return true;
        } else return false;
    }

    hideNonAnnuityFields() {
        return this.isAnnuity() ? false : true; 
    }

    hideNonAnnuityAndUlFields() {
        return (this.isAnnuity()||this.isUL()) ? false : true; 
    }

    isNotAnnuityorUL(){
        return (this.isAnnuity() || this.isUL()) ? false : true;
    }

    wholeAndULPolicy(){
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        if ((policyDetails && policyDetails.response.product_type_desc == 'WHOLE LIFE BASE') || (policyDetails && policyDetails.data.product_type.includes('U'))) {
            return true;
        }
        else {
            return false;
        }
    }

    hidePolicyDetailsFields() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        if (policyDetails && policyDetails.response.product_type_desc == 'WHOLE LIFE BASE') {
            return true;
        }
        else {
            return false;
        }
    }

    nfoInfo() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        if (policyDetails && policyDetails.response.product_type_desc == 'T1') {
            return true;
        }
        else {
            return false;
        }
    }

    etiCoverage() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        if (policyDetails && policyDetails.response.product_type_desc == 'WHOLE LIFE BASE' && policyDetails.response.non_forfeit_override_indicator == 'E') {
            return true
        }
        else {
            return false;
        }
    }

    rpuCoverage() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        if (policyDetails && policyDetails.response.product_type_desc == 'WHOLE LIFE BASE' && policyDetails.response.non_forfeit_override_indicator == 'R') {
            return true
        }
        else {
            return false;
        }
    }

    showAcceleratedPayment() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let billing = resourceService.getResourceFromAliasName('billingInfo');
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        if (billing && billing.response.bill_option == 'P') {
            OcInfraModule.AppInjector.get(CommonService).billingAcceleratedPayment = true;
        }
        return policyDetails.data.product_type_desc === 'WHOLE LIFE BASE' ? true : false;
    }

    deleteParty(params) {
        return true;
    }

    processWorkItem(params) {
        this.createWorkItem(params, 'COMPLETED');
    }

    showAttachDocumentSection() { 
       let showAttach = (this.commonService.isAttachDoc && this.commonService.isWorkNotCompleted) ? true : false;
       return showAttach;
    }

    createWorkItem(params, reqStatus) {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
        const modalProperties = params.modal && params.modal.renderer ? params.modal.renderer.data.sections[0].properties : params.modal.modal.componentInstance.renderer.data.sections[0].properties;
        const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
        const authResponse = resourceService.getResourceFromAliasName('authRes');
        const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory");
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails'); 
        let groupAnuity = (policyDetails.data.product_type == "A3" || policyDetails.data.product_type == "A4") ? true : false;
        headers['role'] = OcInfraModule.AppInjector.get(CommonService).selectedRole;
        headers['product_type'] = policyDetails.data.product_type;
        const requiredProperties = [];
        
        let workReqStatus = reqStatus == 'COMPLETED' ? 'COMPLETED' : 'CREATED';
        let cltyValue = OcInfraModule.AppInjector.get(CommonService).selectedWorkItemType;
        if((cltyValue == "WITHDRAW" ||cltyValue == "DIVDRAW"||cltyValue == "LOAN"||cltyValue == "SURR")&& workReqStatus == 'COMPLETED'){
            workReqStatus = 'AUTOCHECK';
        }
        const payload = {
            "status": workReqStatus,
            "CLTY": OcInfraModule.AppInjector.get(CommonService).selectedWorkItemType,
            "POLN": groupAnuity ? selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number : (selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number),
            "hostKey": selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
            "type": OcInfraModule.AppInjector.get(CommonService).selectedWorkItemValue,
            "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
            "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
            "clientId": selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
            "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
            "origin_app": "csr_portal",
            "lob": policyDetails.data.product_type.charAt(0).toUpperCase(),
            "ICID": authResponse && authResponse.data.contactID ? authResponse.data.contactID : '',
        };
        let commentsValue = '';
        let fullName = selectedRoleSummary.data.fullName ? selectedRoleSummary.data.fullName : selectedRoleSummary.data.ownerFullName ? selectedRoleSummary.data.ownerFullName : selectedRoleSummary.data.annuitantFullName ? selectedRoleSummary.data.annuitantFullName : selectedRoleSummary.data.beneficiaryName ? selectedRoleSummary.data.beneficiaryName : selectedRoleSummary.data.jointOwnerFullName ? selectedRoleSummary.data.jointOwnerFullName : selectedRoleSummary.data.agentFullName ? selectedRoleSummary.data.agentFullName : selectedRoleSummary.data.coOwnerFullName ? selectedRoleSummary.data.coOwnerFullName : "";
        if (selectedRoleSummary.data.role != undefined) {
            if (selectedRoleSummary.data.role == 'Payor') {
                commentsValue = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.payorFullName;
            } else {
                commentsValue = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + fullName;
            }
        }
        else {
            let name = document.getElementById("selected-roles-name-text")["value"];
            let number = document.getElementById("selected-roles-phone-number-text")["value"];
            if (document.getElementById("selected-roles-other-role-name-text")) {
                commentsValue = 'Role: ' + document.getElementById("selected-roles-other-role-name-text")["value"] + ', Full Name: ' + name + ', Phone Number: ' + number;
            }
            else {
                commentsValue = 'Role: ' + OcInfraModule.AppInjector.get(CommonService).otherRole + ', Full Name: ' + name + ', Phone Number: ' + number;
            }
        }
        modalProperties.forEach(propertiesObj => {
            if (propertiesObj.required == true) {
                requiredProperties.push(propertiesObj.id);
            }
            if (propertiesObj.value == '' && propertiesObj.id == 'comments') {
                payload[propertiesObj.id] = commentsValue;
            }
            if (propertiesObj.value) {
                if((propertiesObj.inputClass && propertiesObj.inputClass.indexOf('formatCurrency')>-1||
                    propertiesObj.inputClass && propertiesObj.inputClass.indexOf('formatChckAmountMaxLength')>-1)){
                    payload[propertiesObj.id] = parseFloat(propertiesObj.value).toFixed(2);
                }else
                    payload[propertiesObj.id] = propertiesObj.value
            }
        });
        var blankRequiredfields = requiredProperties.filter(id => {
            return !payload[id] || payload[id] === '';
        })

        if (blankRequiredfields.length) {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Please fill required fields", 'Error', 0);
        } else {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false })
            var customButton = <HTMLInputElement>document.querySelector("#modal__custom");
            var okButton = <HTMLInputElement>document.querySelector(".createWorkItemModal .okButtonModal");
            if (customButton && okButton) {
                customButton.disabled = true;
                okButton.disabled = true;
            }

            OcInfraModule.AppInjector.get(CommonService).postCallback(workRequestHref, payload, headers).subscribe(response => {
                if (response.success) {
                    let workRequestAttachHref = response._links.self.href + '/' + response.instanceid + '/sources';
                    resourceService.updateCreateAliasName('selectedWorkRequestSource', workRequestAttachHref, true);
                    if (this.commonService.attachDocWorkReq) {      
                        this.commonService.isAttachDoc = true;                  
                     }
                    this.commonService.isWorkNotCompleted = workReqStatus == 'CREATED' ? true : false;
                    OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
                    setTimeout(() => {
                        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false })
                    }, 6000)
                    setTimeout(() => {
                        OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': headers }).subscribe(refreshResponse => {
                            OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
                            OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
                            this.commonService.attachDocWorkReq = false;

                            if(!this.commonService.isAttachDoc) {
                                document.getElementById("modal__cross").click();
                             } else {
                                if (!this.commonService.isWorkNotCompleted) {
                                   document.getElementById("modal__cross").click();
                                }
                             }
                            setTimeout(() => {
                                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                            }, 7000)
                        });
                    }, 5000);
                } else {
                    this.commonService.isAttachDoc = false;
                    OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
                    if (customButton && okButton) {
                        customButton.disabled = false;
                        okButton.disabled = false;
                    }
                }
            },
                error => {
                    error && error.code == 401 ? error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Something went wrong please try again', 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Something went wrong please try again', 'Error', 0);
                    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
                    if (customButton && okButton) {
                        customButton.disabled = false;
                        okButton.disabled = false;
                    }
                });
        }
    }

    addOlsWorkItemsComments() {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false })
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'addComments' });
        OcInfraModule.AppInjector.get(CommonService).changeHeaders(header[0].headers);
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        this.comments = document.getElementById("selected-work-request-awd-comments-textarea_")["value"];
        const payload = {
            "AWDComments": this.comments
        };
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        OcInfraModule.AppInjector.get(CommonService).postCallback(OcInfraModule.AppInjector.get(CommonService).commentsUrl, payload, header[0].headers).subscribe(response => {
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.Status, 'Success');
            document.getElementById("selected-work-request-awd-comments-textarea_")["value"] = '';
        },
            error => {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            });
    }

    addWorkItemsComments() {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false })
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'addComments' });
        OcInfraModule.AppInjector.get(CommonService).changeHeaders(header[0].headers);
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const workRequestCommentsHref = resourceService.getHrefByAliasName("selectedWorkRequestComments");
        const workRequestDetailHistoryHref = resourceService.getHrefByAliasName("selectedWorkRequestDetailsHistory");
        this.comments = document.getElementById("selected-work-request-details-history-comments-textarea_")["value"];
        const payload = {
            "comment": this.comments
        };
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        OcInfraModule.AppInjector.get(APICallerService).post(workRequestCommentsHref, payload).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.updateResponse.data.message, 'Success');
            document.getElementById("selected-work-request-details-history-comments-textarea_")["value"] = '';
            OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestCommentsHref).subscribe(refreshResponse => {
            });
        },
            error => {
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            });
    }

    disableAddCommentBtn() {
        let comments = document.getElementById("selected-work-request-details-history-comments-textarea_") ? document.getElementById("selected-work-request-details-history-comments-textarea_")["value"] : undefined;
        return comments ? true : false;
    }

    disableOlsAddCommentBtn() {
        let comments = document.getElementById("selected-work-request-awd-comments-textarea_") ? document.getElementById("selected-work-request-awd-comments-textarea_")["value"] : undefined;
        return comments ? true : false;
    }

    showAnnuityValues() {
        let selectedPolicyInfo = OcInfraModule.AppInjector.get(CommonService).productInfo;
        if (selectedPolicyInfo && (selectedPolicyInfo.product_type == 'A1' || selectedPolicyInfo.product_type == 'A2')) {
            return true;
        }
        else return false;
    }

    showIulValues() {
        let selectedPolicyInfo = OcInfraModule.AppInjector.get(CommonService).productInfo;
        return selectedPolicyInfo && (selectedPolicyInfo.product_type == 'A1' || selectedPolicyInfo.product_type == 'A2') ? false : true;
    }

    showMecDate() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        return this.showIulValues() && (policyDetails && policyDetails.data && policyDetails.data.product_type_desc === 'WHOLE LIFE BASE' || this.isUL()) ? true : false;
    }

    selectedRoleDetails(params) {
        let optionParams = new OptionParams();
        let headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedRoles");
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        OcInfraModule.AppInjector.get(CommonService).changeHeaders({ statuaory_company_code: null });
        let roleList = resourceService.getResourceFromAliasName('roles');
        let selectedRoleSummary = _.filter(roleList._links.item, function (o) { return o.summary.nameRoleTittle == params.value; });
        OcInfraModule.AppInjector.get(CommonService).selectedRole = selectedRoleSummary[0].summary.role;
        OcInfraModule.AppInjector.get(CommonService).clientId = selectedRoleSummary[0].summary.clientId;
        OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(APICallerService).refresh(selectedRoleSummary[0].href, { 'alias': "selectedRoles", 'headers': headers }).subscribe(refreshResponse => {
            OcInfraModule.AppInjector.get(CommonService).corporateOwner = refreshResponse && refreshResponse.response && refreshResponse.response._embedded && refreshResponse.response._embedded.status_report.messages.length ? true : false;
            OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        })
    }

    selectedOtherRoleDetails(params) {
        let headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedRoles");
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let roleList = resourceService.getResourceFromAliasName('selectedRoles');
        let otherRolesList = resourceService.getResourceFromAliasName('roles');
        let selectedRoleSummary = _.filter(roleList._links.item, function (o) { return o.summary.otherRole == params.viewValue; });
        OcInfraModule.AppInjector.get(CommonService).clientId = selectedRoleSummary[0].summary.clientId;
        OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(APICallerService).refresh(selectedRoleSummary[0].href, { 'alias': "selectedRoles", 'headers': headers }).subscribe(refreshResponse => {
            resourceService.aliasDirectory.map((obj) => {
                if (obj.id == 'selectedRoles') {
                    obj.href = otherRolesList.response._links.otherParty.href;
                }
            })
            OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        })
    }

    selectedOtherRole(params) {
        OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
        OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
        OcInfraModule.AppInjector.get(CommonService).otherRoleNameText =params.value;
        if (params.value == 'Other') {
            OcInfraModule.AppInjector.get(CommonService).selectedOther = true;
            this.showOtherRoleName();
        }
        else if ((params.value).toLowerCase() == "funeral home") {
            let status = {
                "consistent": true,
                "messages": [
                    {
                        "message": "Please check  Your Procedures for Funeral Home Calls.",
                        "severity": "ALERT"
                    }
                ]
            }
            OcInfraModule.AppInjector.get(CommonService).alertMessaggeHideShow.next(OcInfraModule.AppInjector.get(CommonService).newBannerAlertAarray = []);
            OcInfraModule.AppInjector.get(CommonService).pushAlertBannerMessage(status);
        } else {
            OcInfraModule.AppInjector.get(CommonService).selectedOther = false;
        }
        this.selectedOtherRoleDetails(params);
        OcInfraModule.AppInjector.get(CommonService).otherRole = params.value;
    }

    changeRoleSelect() {        
        OcInfraModule.AppInjector.get(CommonService).selectedChangeRole = true;        
        OcInfraModule.AppInjector.get(CommonService).showChangeRoleButton = false;
        if(OcInfraModule.AppInjector.get(CommonService).showOtherRoleSection){
            OcInfraModule.AppInjector.get(CommonService).role = '';
            OcInfraModule.AppInjector.get(CommonService).otherRole = '';
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let roleList = resourceService.getResourceFromAliasName('roles');
            let selectedRoleSummary = _.filter(roleList._links.item, function (o) { return o.summary.role == OcInfraModule.AppInjector.get(CommonService).selectedRole });
            selectedRoleSummary.length == 0 ? selectedRoleSummary.push(roleList._links.item[0]) :'';
            resourceService.updateCreateAliasName('selectedRoles', selectedRoleSummary[0].href, true);
            OcInfraModule.AppInjector.get(CommonService).selectedOther = false;
            OcInfraModule.AppInjector.get(CommonService).showOtherRoleSection = false;
            OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess = false;
            OcInfraModule.AppInjector.get(CommonService).otherRoleSummary  = null;
        }
        this.showOtherRoleButton();
        this.showAuthorizeAndOther();
    }

    showChangeRoleSection() {
        return OcInfraModule.AppInjector.get(CommonService).showChangeRoleButton;        
    }

    otherRoleSelect() {
        OcInfraModule.AppInjector.get(CommonService).role = 'OtherRole';
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let roleList = resourceService.getResourceFromAliasName('roles');
        resourceService.updateCreateAliasName('selectedRoles', roleList.response._links.otherParty.href, true);
        OcInfraModule.AppInjector.get(CommonService).changeRoleAuthsucess = false;
        OcInfraModule.AppInjector.get(CommonService).showOtherRoleSection = true;
    }

    showOtherRoleSection(){
        return OcInfraModule.AppInjector.get(CommonService).showOtherRoleSection;
    }

    cancelOtherRole() {
        let currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;
        OcInfraModule.AppInjector.get(CommonService).role = '';
        OcInfraModule.AppInjector.get(CommonService).otherRole = '';
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let roleList = resourceService.getResourceFromAliasName('roles');
        let selectedRoleSummary = _.filter(roleList._links.item, function (o) { return o.summary.role == OcInfraModule.AppInjector.get(CommonService).selectedRole; });
        let agentDetais = OcInfraModule.AppInjector.get(CommonService).agentRoleDetails;
        currentPage == '/screen/agentDetails' ? selectedRoleSummary = agentDetais.response._links.item : '';
        selectedRoleSummary.length == 0 ? selectedRoleSummary.push(roleList._links.item[0]) :'';
        resourceService.updateCreateAliasName('selectedRoles', selectedRoleSummary[0].href, true);
        OcInfraModule.AppInjector.get(CommonService).selectedOther = false;
        OcInfraModule.AppInjector.get(CommonService).showOtherRoleSection = false;
        OcInfraModule.AppInjector.get(CommonService).otherRoleNameText = "";
    }
    quoteDate(field) {
        let year = field.value.substring(0, 4);
        let month = field.value.substring(5, 7);
        let day = field.value.substring(8, 10);
        OcInfraModule.AppInjector.get(CommonService).quoteDate = month + '/' + day + '/' + year;
    }
    loanDate(field) {
        let year = field.value.substring(0, 4);
        let month = field.value.substring(5, 7);
        let day = field.value.substring(8, 10);
        OcInfraModule.AppInjector.get(CommonService).loanDate = month + '/' + day + '/' + year;
    }
    validateDate(testdate) {
        let date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
        return date_regex.test(testdate);
    }

    isDateEditable() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details.data.product_type_desc === 'WHOLE LIFE BASE'){
                return false;
            }
            else {
                return true;
            }
        }
    }

    quoteValues() {
        let associateSpinnerInterval = setInterval(function () {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        }, 0);
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let quoteDate = OcInfraModule.AppInjector.get(CommonService).quoteDate;
        let quoteValueDetails = resourceService.getResourceFromAliasName('policyValues');
        let quoteDefaultDate = quoteValueDetails.data.quote_effective_date;
        if (quoteDate == "") {
            quoteDate = quoteDefaultDate;
        }
        let isDateCorrect = this.validateDate(quoteDate);
        setTimeout(() => {
            clearInterval(associateSpinnerInterval);
            let valuesSpinnerInterval = setInterval(function () {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            }, 0);
        }, 3500);
        if (isDateCorrect) {
            let url = OcInfraModule.AppInjector.get(CommonService).quoteDate ? policyDetails && policyDetails._links && policyDetails._links.financialvalues && policyDetails._links.financialvalues.href + "?quoteEffectiveDate=" + OcInfraModule.AppInjector.get(CommonService).quoteDate : policyDetails && policyDetails._links && policyDetails._links.financialvalues && policyDetails._links.financialvalues.href;
            let optionParams = new OptionParams();
            optionParams.alias = "policyValues";
            OcInfraModule.AppInjector.get(CommonService).quoteDate ? optionParams.params = [{ 'key': "quoteEffectiveDate", value: OcInfraModule.AppInjector.get(CommonService).quoteDate }] : null;
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
            // instead of calling refresh call, called updateCreateAliasName with optionParams //
            resourceService.updateCreateAliasName('policyValues', url, true);
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
        }
        else {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Incorrect date selected for quote.", 'Error', 0);
            document.getElementById("policy-values-quote-effective-date-date_input")["value"] = quoteDefaultDate;
        }
    }

    quoteLoans() {
        let associateSpinnerInterval = setInterval(function () {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        }, 0);
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let loansDate = OcInfraModule.AppInjector.get(CommonService).loansDate;
        let quoteLoansDetails = resourceService.getResourceFromAliasName('loanValues');
        let quotesDefaultDate = quoteLoansDetails.data.quote_effective_date;
        if (loansDate == "") {
            loansDate = quotesDefaultDate;
        }
        let isDateCorrect = this.validateDate(loansDate);
        setTimeout(() => {
            clearInterval(associateSpinnerInterval);
            let valuesSpinnerInterval = setInterval(function () {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            }, 0);
        }, 3500);
        if (isDateCorrect) {
            let url = OcInfraModule.AppInjector.get(CommonService).quoteDate ? policyDetails && policyDetails._links && policyDetails._links.financialvalues && policyDetails._links.financialvalues.href + "?quoteEffectiveDate=" + OcInfraModule.AppInjector.get(CommonService).quoteDate : policyDetails && policyDetails._links && policyDetails._links.financialvalues && policyDetails._links.financialvalues.href;
            let optionParams = new OptionParams();
            optionParams.alias = "loanValues";
            OcInfraModule.AppInjector.get(CommonService).quoteDate ? optionParams.params = [{ 'key': "quoteEffectiveDate", value: OcInfraModule.AppInjector.get(CommonService).quoteDate }] : null;
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
            // instead of calling refresh call, called updateCreateAliasName with optionParams //
            resourceService.updateCreateAliasName('loanValues', url, true);
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
        }
        else {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Incorrect date selected for quote.", 'Error', 0);
            document.getElementById("policy-values-quote-effective-date-date_input")["value"] = quotesDefaultDate;
        }
    }

    loansDate(field) {
        let year = field.value.substring(0, 4);
        let month = field.value.substring(5, 7);
        let day = field.value.substring(8, 10);
        OcInfraModule.AppInjector.get(CommonService).quoteDate = month + '/' + day + '/' + year;
    }

    quoteGroupValues() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let quoteDate = OcInfraModule.AppInjector.get(CommonService).quoteDate;
        let quoteValueDetails = resourceService.getResourceFromAliasName('policyValues');
        let quoteDefaultDate = quoteValueDetails && quoteValueDetails.data.quote_effective_date ? quoteValueDetails.data.quote_effective_date : new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
        let selectedDrpdwnObj =  OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues;
        let selectedPlanCode;
        let selectedAssetAccount;
        if (selectedDrpdwnObj['VALUES']) {
            selectedPlanCode = selectedDrpdwnObj['VALUES']['planCode'];
            selectedAssetAccount = selectedDrpdwnObj['VALUES']['assetAccount'];
        }
        if (quoteDate == "") {
            quoteDate = quoteDefaultDate;
        }
        let isDateCorrect = this.validateDate(quoteDate);
        if (isDateCorrect) {
            let grpValuesObj = OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues;
            grpValuesObj['VALUES']['effectiveDate'] = quoteDate;
            OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues = grpValuesObj;
            let url = policyDetails && policyDetails._links && policyDetails._links.financialvalues && policyDetails._links.financialvalues.href;
            let optionParams = new OptionParams();
            optionParams.alias = "policyValues";
            optionParams.headers = { ...{ plan_id: selectedPlanCode, asset_account: selectedAssetAccount }, ...OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("policyValues") };
            optionParams.params = [{ 'key': "quoteEffectiveDate", value: quoteDate}];
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
            OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams).subscribe(res => {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true })
            }, error => {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true })
            });
        }
        else {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Incorrect date selected for quote.", 'Error', 0);
            document.getElementById("policy-values-quote-effective-date-date_input")["value"] = quoteDefaultDate;
        }
    }

    emailValuesQuote() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let quoteValueDetails = resourceService.getResourceFromAliasName('policyValues');
        let url = quoteValueDetails && quoteValueDetails._links && quoteValueDetails._links.emailvaluequote && quoteValueDetails._links.emailvaluequote.href;
        if (url) {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
            let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'policyValues' });
            header[0].headers['role'] = OcInfraModule.AppInjector.get(CommonService).selectedRole;
            header[0].headers['user_role'] = OcInfraModule.AppInjector.get(CommonService).userRole.toUpperCase();
            OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
            OcInfraModule.AppInjector.get(CommonService).getCallback(url, header[0].headers).subscribe(response => {
                OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.messages[0].message, 'Success');
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            }, error => {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            });
        }
    }

    emailLoanQuote() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let quoteValueDetails = resourceService.getResourceFromAliasName('loanValues');
        let quoteDate = OcInfraModule.AppInjector.get(CommonService).quoteDate;
        let quoteDefaultDate = quoteValueDetails.data.quote_effective_date;
        let mailUrl = quoteValueDetails && quoteValueDetails._links && quoteValueDetails._links.emailloanquote && quoteValueDetails._links.emailloanquote.href;
        if (quoteDate == "") {
            quoteDate = quoteDefaultDate;
            mailUrl = quoteValueDetails && quoteValueDetails._links && quoteValueDetails._links.emailloanquote && quoteValueDetails._links.emailloanquote.href + "?quoteEffectiveDate=" + quoteDate;
        }
        if (mailUrl) {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
            let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'policyValues' });
            header[0].headers['role'] = OcInfraModule.AppInjector.get(CommonService).selectedRole;
            header[0].headers['user_role'] = OcInfraModule.AppInjector.get(CommonService).userRole.toUpperCase();
            OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
            OcInfraModule.AppInjector.get(CommonService).getCallback(mailUrl, header[0].headers).subscribe(response => {
                OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.messages[0].message, 'Success');
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            }, error => {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            });
        }
    }

    quoteLoan() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let quoteDate = OcInfraModule.AppInjector.get(CommonService).quoteDate;
        let quoteValueDetails = resourceService.getResourceFromAliasName('loanValues');
        let quoteDefaultDate = quoteValueDetails.data.quote_effective_date;
        if (quoteDate == "") {
            quoteDate = quoteDefaultDate;
        }
        let isDateCorrect = this.validateDate(quoteDate);
        if (isDateCorrect) {
            let url = OcInfraModule.AppInjector.get(CommonService).quoteDate ? policyDetails._links.financialvalues.href + '?quoteEffectiveDate=' + OcInfraModule.AppInjector.get(CommonService).quoteDate : policyDetails._links.financialvalues.href;
            resourceService.updateCreateAliasName('loanValues', url, true);
        }
        else {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Incorrect date selected for quote.", 'Error', 0);
            document.getElementById("loan-values-quote-effective-date-date_input")["value"] = quoteDefaultDate;
        }
    }

    showAuthorizeAndOther() {
        return !OcInfraModule.AppInjector.get(CommonService).showOtherRoleSection;
    }

    showOtherRoleButton(){
        return !OcInfraModule.AppInjector.get(CommonService).showOtherRoleSection && !OcInfraModule.AppInjector.get(CommonService).showChangeRoleButton;
    }

    showCancelAndContinue() {
        return OcInfraModule.AppInjector.get(CommonService).showOtherRoleSection;
    }

    showCancel() {
        return OcInfraModule.AppInjector.get(CommonService).showOtherRoleSection && !OcInfraModule.AppInjector.get(CommonService).showChangeRoleButton;
    }

    disableContinueButton() {
        let otherRole = OcInfraModule.AppInjector.get(CommonService).otherRole;
        let authsucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
        let number = document.getElementById("selected-roles-phone-number-text") ? document.getElementById("selected-roles-phone-number-text")["value"] : undefined;
        let name = document.getElementById("selected-roles-name-text") ? document.getElementById("selected-roles-name-text")["value"] : undefined;
        if (number && number.length == 12 && name && otherRole != undefined && otherRole != '' && authsucess != true) {
            if (otherRole == 'Other') {
                if (document.getElementById("selected-roles-other-role-name-text")["value"] != '') {
                    return true;
                }
                return false;
            }
            return true;
        }
        else {
            return false;
        }
    }

    disableSelectedRoleDetails() {
        let authsuccess = OcInfraModule.AppInjector.get(CommonService).authsucess;
        let selectedChangeRole = OcInfraModule.AppInjector.get(CommonService).selectedChangeRole
        return (!authsuccess || selectedChangeRole);
    }

    disableOtherRoleDetails() {
        let otherRoleAuthsucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
        let selectedChangeRole = OcInfraModule.AppInjector.get(CommonService).selectedChangeRole
        return (!otherRoleAuthsucess || selectedChangeRole);
    }

    disableAuthorizeButton() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedRoles = resourceService.getResourceFromAliasName('selectedRoles');
        return !OcInfraModule.AppInjector.get(CommonService).authsucess && selectedRoles || OcInfraModule.AppInjector.get(CommonService).selectedChangeRole;
    }

    disableAgentAuthorizeAndOtherRoleButton() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedRoles = resourceService.getResourceFromAliasName('selectedRoles');
        return !OcInfraModule.AppInjector.get(CommonService).authsucess && selectedRoles;
    }

    disableCancelButton() {
        return !OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
    }

    disableOtherRoleButton() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedRoles = resourceService.getResourceFromAliasName('selectedRoles');
        return !OcInfraModule.AppInjector.get(CommonService).authsucess && selectedRoles || OcInfraModule.AppInjector.get(CommonService).selectedChangeRole;
    }

    disableSaveAndCloseButton() {
        let reason = OcInfraModule.AppInjector.get(CommonService).selectedReason;
        let phoneNumField = document.getElementById("close-call-phone-number-text") ? document.getElementById("close-call-phone-number-text")["value"] : undefined;
        let authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
        let otherRoleSucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
        let closeCallButton = document.getElementById("save-close-close-call-button");
        let notes = document.getElementById("close-call-notes-textarea_");
        if (reason != ""  && reason != undefined && phoneNumField != "" && phoneNumField.length == 12 && (authsucess || otherRoleSucess)) {
            if (closeCallButton) {
                closeCallButton.setAttribute('title', '');
            }
            return true;
        }
        else {
            if (!(authsucess || otherRoleSucess) && closeCallButton) {
                closeCallButton.setAttribute('title', 'Caller was not Authorized');
            } else if ((authsucess || otherRoleSucess) && (!reason || !phoneNumField || phoneNumField.length < 12 || closeCallButton || reason == undefined)) {
                closeCallButton.setAttribute('title', 'Enable button by completing required items');
            }
            return false;
        }
    }

    showconfirmationNo(field) {
        OcInfraModule.AppInjector.get(CommonService).confirmationNo = field.value == undefined ? false : field.value;
        if (field.value) {
            this.authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse;
            document.getElementById('close-call-confirmation-checkbox_label').innerHTML = 'Confirmation #' + this.authResponse.updateResponse.data.confirmationNumber;
        } else {
            document.getElementById('close-call-confirmation-checkbox_label').innerHTML = 'Confirmation';
        }
    }

    authorizeOtherRole() {
        this.authorize('otherRole');
    }

    showAlert() {
        return OcInfraModule.AppInjector.get(CommonService).corporateOwner;
    }

    showNotesBaner() {
        return (!OcInfraModule.AppInjector.get(CommonService).corporateOwner) ? true : false;
    }
    noteMesseage() {
        let policyNotesDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyNotes');
        if (policyNotesDetails) {
            return policyNotesDetails.response._links.item && !policyNotesDetails.response._links.item.length;
        }
    }

    sponsorMesseage() {
        let policyNotesDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policySponsor');
        if (policyNotesDetails) {
            return policyNotesDetails.response._links.item && !policyNotesDetails.response._links.item.length;
        }
    }


    riderMesseage() {
        let policyRiderDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyCoverage');
        if (policyRiderDetails) {
            return policyRiderDetails.response._links.item && !policyRiderDetails.response._links.item.length;
        }
    }

    riderBlueBar() {
        let policyRiderDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyCoverage');
        if ((policyRiderDetails && policyRiderDetails.response._links.item && policyRiderDetails.response._links.item.length) && this.isAnnuity()) {
            return false;
        }
        else return true;
    }

    getRiderMsg(params) {
        if (params.value) {
            let coveragemsg = {}
            coveragemsg['insured'] = {};
            coveragemsg['insured']['Message'] = 'This Policy does not have any riders'
            localStorage.setItem('selectedCoverage', JSON.stringify(coveragemsg));
            OcInfraModule.AppInjector.get(CommonService).coverageHtml = ' <span style="font-size:15px" class="pad-lAr-10px">RIDERS</span><dl class="pad-lAr-10px">' + '<dt class="pull-left">Riders</dt><dd>&nbsp;This Policy does not have any riders</dd>' + '</dl>'
        } else {
            localStorage.removeItem('selectedCoverage');
            OcInfraModule.AppInjector.get(CommonService).coverageHtml = '';
        }
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    }

    getFundsMsg(params) {
        if (params.value) {
            let fundsMsg = {}
            fundsMsg['term'] = {};
            fundsMsg['term']['Message'] = "Funds are not applicable for this product.";
            localStorage.setItem('fundInfo', JSON.stringify(fundsMsg));
            this.html = '<dl class="pad-lAr-10px">' + '<dt class="pull-left">Funds</dt><dd>&nbsp;Funds are not applicable for this product.</dd>' + '</dl>'
            OcInfraModule.AppInjector.get(CommonService).fundHtml = this.html;
        } else {
            localStorage.removeItem('fundInfo');
            OcInfraModule.AppInjector.get(CommonService).fundHtml = '';
        }
        OcInfraModule.AppInjector.get(CommonService).showfundFinalDetails();
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    }

    getRestrictFundsMsg(params) {
        if (params.value) {
            let fundsMsg = {}
            fundsMsg['term'] = {};
            fundsMsg['term']['Message'] = "This policy is Restricted - values are not to be displayed.";
            localStorage.setItem('fundInfo', JSON.stringify(fundsMsg));
            this.html = '<dl class="pad-lAr-10px">' + '<dt class="pull-left">Funds</dt><dd>&nbsp;This policy is Restricted - values are not to be displayed.</dd>' + '</dl>'
            OcInfraModule.AppInjector.get(CommonService).fundHtml = this.html;
        } else {
            localStorage.removeItem('fundInfo');
            OcInfraModule.AppInjector.get(CommonService).fundHtml = '';
        }
        OcInfraModule.AppInjector.get(CommonService).showfundFinalDetails();
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    }

    showMsg(params) {
        this.showMesseage(params);
        return true;
    }
    showFundsFilter() {
        const resource = this.resourceService.getResourceFromAliasName("historyFunds");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.historyFunds ? true : resource && resource.response && resource.response._links && resource.response._links.item.length > 0;
    }
    showHCoiFilter() {
        const resource = this.resourceService.getResourceFromAliasName("historicalcoi");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.historicalcoi ? true : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }

    showPHFilter() {
        const resource = this.resourceService.getResourceFromAliasName("paymenthistory");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.paymenthistory ? true : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }

    showIncomingMoneyFilter() {
        const resource = this.resourceService.getResourceFromAliasName("incomingmoney");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.incomingmoney ? true : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }

    showProgramEnrollmentFilter() {
        const resource = this.resourceService.getResourceFromAliasName("programenrollment");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.programenrollment ? true : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }

    showOGMFilter() {
        const resource = this.resourceService.getResourceFromAliasName("outgoingmoney");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.outgoingmoney ? true : resource && resource.response && resource.response._links && resource.response._links.item.length > 0;
    }

    authorize(params) {
        OcInfraModule.AppInjector.get(CommonService).mfaDataReset();
        let optionParams = new OptionParams();
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const resourceDirectoryService = OcInfraModule.AppInjector.get(ResourceDirectoryService);
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("authorize");
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true })
        let authorize = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias == 'authorize' });
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'authorize' });
        let selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        policyDetails && policyDetails._links && policyDetails._links.notes && policyDetails._links.notes.href ? resourceService.updateCreateAliasName('policyNotes', policyDetails._links.notes.href, true) : null;
        OcInfraModule.AppInjector.get(CommonService).showChangeRoleButton = true;
        if (params != 'otherRole') {
            this.requiredPayload.policyNumber = this.isOnlyGroupAnn() ? selectedRoleSummary && selectedRoleSummary.data && selectedRoleSummary.data.superiorClient : selectedRoleSummary && selectedRoleSummary.data && selectedRoleSummary.data.policyNumber;
            Configuration.config.headers["multiHostUrlHeader"].forEach(headerObj => {
                if (headerObj.alias == 'billing') {
                    headerObj.headers.role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
                    headerObj.headers.wma_user = window.sessionStorage.getItem('username');
                }
            });
            OcInfraModule.AppInjector.get(CommonService).selectedRole = selectedRoleSummary.data.role.toLowerCase();
            if (selectedRoleSummary && selectedRoleSummary.data) {
                this.requiredPayload.callerRole = selectedRoleSummary.data.role;
                this.requiredPayload.contractId = selectedRoleSummary.data.hostKey;
                if (selectedRoleSummary.data.thirdPartyFullName) {
                    this.requiredPayload.callerName = selectedRoleSummary.data.thirdPartyFullName;
                    this.requiredPayload.thirdPartyFullName = selectedRoleSummary.data.thirdPartyFullName
                } else if (selectedRoleSummary.data.payorFullName) {
                    this.requiredPayload.callerName = selectedRoleSummary.data.payorFullName;
                } else {
                    this.requiredPayload.callerName = selectedRoleSummary.data.fullName;
                }
            }

            this.requiredPayload.userId = OcInfraModule.AppInjector.get(CommonService).incontactId;
            this.requiredPayload.csrName = sessionStorage.getItem('username');
            if(OcInfraModule.AppInjector.get(CommonService).selectedChangeRole){
                this.authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse;
                this.requiredPayload.previousSessionId = this.authResponse.updateResponse.data.contactSessionID;                
            }

            let productInfo = this.commonService.productInfo;
            const productType = productInfo ? productInfo["product_type"].toLowerCase():'';
            if (productType && ( productType == "h")) {
                this.requiredPayload.groupNumber = selectedRoleSummary.data && selectedRoleSummary.data.hgroupNumber;
            } else if (productType && ( this.isAnnuity())) {
                this.requiredPayload.groupNumber = selectedRoleSummary.data && selectedRoleSummary.data.groupNumber;
            }
            this.requiredPayload.csrFullName = OcInfraModule.AppInjector.get(CommonService).fullUserName;
            let payload = JSON.stringify(this.requiredPayload);
            OcInfraModule.AppInjector.get(CommonService).authorizedRole = selectedRoleSummary && selectedRoleSummary.data && selectedRoleSummary.data.role;
            header[0].headers.incontact_user = OcInfraModule.AppInjector.get(CommonService).incontactEmailId;
            OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
            OcInfraModule.AppInjector.get(APICallerService).post(authorize[0].url, payload, 'autorization').subscribe(response => {
                OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
                OcInfraModule.AppInjector.get(CommonService).newBannerAlertAarray = [];
                OcInfraModule.AppInjector.get(CommonService).corporateOwner = false;
                OcInfraModule.AppInjector.get(CommonService).authsucess = true;
                OcInfraModule.AppInjector.get(CommonService).changeRoleAuthsucess = true;
                OcInfraModule.AppInjector.get(CommonService).selectedChangeRole = false;
                OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess = false;
                OcInfraModule.AppInjector.get(CommonService).otherRoleSummary = null;

                if(OcInfraModule.AppInjector.get(CommonService).changeotherRoleAuthsucess){
                    OcInfraModule.AppInjector.get(CommonService).changeotherRoleAuthsucess = false;
                }                
                OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                OcInfraModule.AppInjector.get(CommonService).alertMessaggeHideShow.next();
                this.authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse = response;
                if ((<HTMLInputElement>document.getElementById('close-call-confirmation-checkbox-input')).checked) {
                    document.getElementById('close-call-confirmation-checkbox-input').click();
                    document.getElementById('close-call-confirmation-checkbox-input').click();
                }
                else {
                    document.getElementById('close-call-confirmation-checkbox-input').click();
                }
                OcInfraModule.AppInjector.get(CommonService).resetSelectedReason();
                document.getElementById('close-call-confirmation-checkbox_label').innerHTML = 'Confirmation #' + this.authResponse.updateResponse.data.confirmationNumber;
                document.getElementById("close-call-phone-number-text")["value"] = response.updateResponse && response.updateResponse.data && response.updateResponse.data.phoneNumber && response.updateResponse.data.phoneNumber.replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
                OcInfraModule.AppInjector.get(CommonService).selectedReason = '';
                OcInfraModule.AppInjector.get(CommonService).startCall();

                setTimeout(function () {
                    Array.from(document.querySelectorAll(".expandOnAuthorize mat-expansion-panel-header")).forEach(accordian => {
                        if (!accordian["classList"].contains("mat-expanded")) {
                            document.getElementById(accordian.id).click();
                        }
                    });


                    Array.from(document.querySelectorAll("#correspondenceTable .mat-checkbox-input")).forEach(correspondenceRowCheckbox => {
                        const checkBox = correspondenceRowCheckbox as HTMLInputElement;
                        if (checkBox.checked) {
                            Array.from(document.querySelectorAll("#correspondenceTable .correspondence-btn")).forEach(correspondenceButton => {
                                var corrBtn = correspondenceButton as HTMLInputElement
                                corrBtn.disabled = false;
                                if (OcInfraModule.AppInjector.get(CommonService).isDuplicatePoliciesAction()) {
                                    corrBtn.disabled = true;
                                } 
                            });
                        }
                    });

                    Array.from(document.querySelectorAll(".collapseOnAuthorize mat-expansion-panel-header")).forEach(accordian => {
                        if (accordian["classList"].contains("mat-expanded")) {
                            document.getElementById(accordian.id).click();
                        }
                    });
                }, 2000);
                let fullName = selectedRoleSummary.data.fullName ? selectedRoleSummary.data.fullName : selectedRoleSummary.data.ownerFullName ? selectedRoleSummary.data.ownerFullName : selectedRoleSummary.data.annuitantFullName ? selectedRoleSummary.data.annuitantFullName : selectedRoleSummary.data.beneficiaryName ? selectedRoleSummary.data.beneficiaryName :  selectedRoleSummary.data.jointOwnerFullName ? selectedRoleSummary.data.jointOwnerFullName : selectedRoleSummary.data.agentFullName ? selectedRoleSummary.data.agentFullName : selectedRoleSummary.data.coOwnerFullName ? selectedRoleSummary.data.coOwnerFullName : selectedRoleSummary.data.coOwnerFullName ? selectedRoleSummary.data.coOwnerFullName : selectedRoleSummary.data.irrevocableBeneficiaryName ? selectedRoleSummary.data.irrevocableBeneficiaryName  :  selectedRoleSummary.data.powerOfAttorneyName ? selectedRoleSummary.data.powerOfAttorneyName : selectedRoleSummary.data.insuredFullName ? selectedRoleSummary.data.insuredFullName : selectedRoleSummary.data.insuredName ? selectedRoleSummary.data.insuredName : "";
                let thirdPartyFullName = selectedRoleSummary.data.thirdPartyFullName ? selectedRoleSummary.data.thirdPartyFullName : selectedRoleSummary.data.insuredName ? selectedRoleSummary.data.insuredName : "";
                
                
                let productInfo = this.commonService.productInfo;
                const productType = productInfo ? productInfo["product_type"].toLowerCase():'';
                let hgroupText = "";
                if (productType && ( productType == "h")) {
                    hgroupText = (selectedRoleSummary.data && selectedRoleSummary.data.hgroupNumber) ? "Group Number: " + selectedRoleSummary.data.hgroupNumber : '';
                } else if (productType && ( this.isAnnuity())) {
                    hgroupText = (selectedRoleSummary.data && selectedRoleSummary.data.groupNumber) ? "Group Number: " + selectedRoleSummary.data.groupNumber : '';
                }               

                if (document.getElementById("close-call-notes-textarea_")["value"] != '') {
                    let notesText = document.getElementById("close-call-notes-textarea_")["value"];
                    if (selectedRoleSummary.data.role == 'Payor') {
                        document.getElementById('close-call-notes-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.payorFullName + ', ' + notesText;
                    } else if (selectedRoleSummary.data.role == 'Third Party Administrator') {
                        document.getElementById('close-call-notes-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + thirdPartyFullName + ', ' + notesText;
                    } else {
                        document.getElementById('close-call-notes-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + fullName + ', ' + notesText;
                    }
                }
                else {
                    if (selectedRoleSummary.data.role == 'Payor') {
                        document.getElementById('close-call-notes-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.payorFullName;
                    } else if (selectedRoleSummary.data.role == 'Third Party Administrator') {
                        document.getElementById('close-call-notes-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + thirdPartyFullName;
                    } else {
                        document.getElementById('close-call-notes-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + fullName;
                    }
                }

                
                document.getElementById('close-call-notes-textarea_')["value"] = document.getElementById('close-call-notes-textarea_')["value"] + ', ' + hgroupText;
                let noteText= document.getElementById("close-call-notes-textarea_")["value"];
                noteText = noteText.replace(/,\s*$/, "");
                document.getElementById('close-call-notes-textarea_')["value"] = noteText;
                
                

                // If Policy become lapsed then open Bill Tab
                let policyStatus = policyDetails ? this.policyStatus() : "";
                if (policyStatus) {
                    (document.querySelector('.policyInformationTab .mat-tab-label-container .mat-tab-labels').children[2] as HTMLInputElement).click();
                }

                // Billing Tab - Reinstatement quote check box disabled
                OcInfraModule.AppInjector.get(CommonService).disableReinstatementCheckbox();

                let notes = resourceService.getResourceFromAliasName('policyNotes');
                if (notes && notes._links.item.length > 0) {
                    OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                } else {
                    OcInfraModule.AppInjector.get(CommonService).notesMsg = true;
                }
                let suspense = resourceService.getResourceFromAliasName('policySuspense');
                if (suspense && suspense._links.item.length > 0) {
                    OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                } else {
                    OcInfraModule.AppInjector.get(CommonService).SuspenseMsg = true;
                }
                const aliasToDelete: IAlias = resourceService.getAliasByAliasName('authRes');
                if (aliasToDelete) {
                    resourceDirectoryService.deleteElementResourceDirectory(response.updateResponse._options.links[0].href);
                    resourceService.updateCreateAliasName(aliasToDelete.id, '', true);
                }
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ statuaory_company_code: null });
                resourceService.updateCreateAliasName('authRes', response.updateResponse._options.links[0].href, true);
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ remote_user: null })
                resourceService.updateCreateAliasName('authorizeSucess', selectedRoleSummary.resourceURL, true);
                policyDetails ? this.refreshPolicyDetails() : null;

            });
        }
        else {
            let selectdPolicyInfo = OcInfraModule.AppInjector.get(CommonService).productInfo;
            let roleList = resourceService.getResourceFromAliasName('roles');
            let selectRole = _.filter(roleList.response._links.item, function (o) { return o.summary.clientId == OcInfraModule.AppInjector.get(CommonService).clientId });
            selectRole && selectRole.length ? this.otherRolePayload = _.pickBy(selectRole[0].summary, function (value, key) {
                return key == 'policyNumber';
            }) : this.otherRolePayload.policyNumber = this.isOnlyGroupAnn() ? selectdPolicyInfo && selectdPolicyInfo.client : selectdPolicyInfo && selectdPolicyInfo.policy_no;
            if(this.isOnlyGroupAnn()){
                selectRole && selectRole.length ? this.otherRolePayload.policyNumber = selectdPolicyInfo && selectdPolicyInfo.client : "";
            }
            OcInfraModule.AppInjector.get(CommonService).selectedRole = OcInfraModule.AppInjector.get(CommonService).otherRole;
            this.otherRolePayload.callerRole = OcInfraModule.AppInjector.get(CommonService).otherRole;
            let number = document.getElementById("selected-roles-phone-number-text")["value"];
            this.otherRolePayload.callerName = document.getElementById("selected-roles-name-text")["value"];
            if (OcInfraModule.AppInjector.get(CommonService).selectedOther) {
                this.otherRolePayload.callerRole = document.getElementById("selected-roles-other-role-name-text")["value"];
            }
            this.otherRolePayload.phoneNumber = number;
            this.otherRolePayload.csrName = sessionStorage.getItem('username');
            if (selectRole.length) {
                this.otherRolePayload.contractId = selectRole[0].summary.hostKey;
            }
            this.otherRolePayload.userId = OcInfraModule.AppInjector.get(CommonService).incontactId;
            if(OcInfraModule.AppInjector.get(CommonService).selectedChangeRole){
                this.authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse;
                this.otherRolePayload.previousSessionId = this.authResponse.updateResponse.data.contactSessionID; 
            }

            let productInfo = this.commonService.productInfo;
            const productType = productInfo ? productInfo["product_type"].toLowerCase():'';
            let hgroupText = "";
            if (productType && ( productType == "h")) {
                this.otherRolePayload.groupNumber = selectRole[0].summary && selectRole[0].summary.hgroupNumber;
                hgroupText = (selectRole[0].summary && selectRole[0].summary.hgroupNumber) ? "Group Number: " + selectRole[0].summary.hgroupNumber : '';
            } else if (productType && ( this.isAnnuity())) {
                this.otherRolePayload.groupNumber = selectRole[0].summary && selectRole[0].summary.groupNumber;
                hgroupText = (selectRole[0].summary && selectRole[0].summary.groupNumber) ? "Group Number: " + selectRole[0].summary.groupNumber : '';
            }  

            header[0].headers.wma_user = window.sessionStorage.getItem('username');
            header[0].headers.incontact_user = OcInfraModule.AppInjector.get(CommonService).incontactEmailId;
            OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
            OcInfraModule.AppInjector.get(APICallerService).post(authorize[0].url, this.otherRolePayload).subscribe(response => {
                OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
                OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess = true;
                OcInfraModule.AppInjector.get(CommonService).otherRoleSummary = selectRole[0].summary;
                OcInfraModule.AppInjector.get(CommonService).selectedChangeRole = false;   
                OcInfraModule.AppInjector.get(CommonService).changeotherRoleAuthsucess = true;
                if(OcInfraModule.AppInjector.get(CommonService).changeRoleAuthsucess){
                    OcInfraModule.AppInjector.get(CommonService).changeRoleAuthsucess = false;
                } 
                OcInfraModule.AppInjector.get(CommonService).authsucess = false;
                OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                OcInfraModule.AppInjector.get(CommonService).startCall();
                document.getElementById("close-call-phone-number-text")["value"] = response.updateResponse && response.updateResponse.data && response.updateResponse.data.phoneNumber && response.updateResponse.data.phoneNumber.replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
                const aliasToDelete: IAlias = resourceService.getAliasByAliasName('authRes');
                if (aliasToDelete) {
                    resourceDirectoryService.deleteElementResourceDirectory(response.updateResponse._options.links[0].href);
                    resourceService.updateCreateAliasName(aliasToDelete.id, '', true);
                }
                resourceService.updateCreateAliasName('authRes', response.updateResponse._options.links[0].href, true);
                policyDetails ? this.refreshPolicyDetails() : null;
                setTimeout(function () {
                    Array.from(document.querySelectorAll(".expandOnAuthorize mat-expansion-panel-header")).forEach(accordian => {
                        if (!accordian["classList"].contains("mat-expanded")) {
                            document.getElementById(accordian.id).click();
                        }
                    });

                    Array.from(document.querySelectorAll(".collapseOnAuthorize mat-expansion-panel-header")).forEach(accordian => {
                        if (accordian["classList"].contains("mat-expanded")) {
                            document.getElementById(accordian.id).click();
                        }
                    });
                    Array.from(document.querySelectorAll("#correspondenceTable .mat-checkbox-input")).forEach(correspondenceRowCheckbox => {
                        const checkBox = correspondenceRowCheckbox as HTMLInputElement;
                        if (checkBox.checked) {
                            Array.from(document.querySelectorAll("#correspondenceTable .correspondence-btn")).forEach(correspondenceButton => {
                                var corrBtn = correspondenceButton as HTMLInputElement
                                corrBtn.disabled = false;
                                if (OcInfraModule.AppInjector.get(CommonService).isDuplicatePoliciesAction()) {
                                    corrBtn.disabled = true;
                                }
                            });
                        }
                    });
                }, 2000);
                setTimeout(function () {
                    let name = document.getElementById("selected-roles-name-text")["value"]
                    let notesText = document.getElementById("close-call-notes-textarea_")["value"];
                    OcInfraModule.AppInjector.get(CommonService).authorizedRole = OcInfraModule.AppInjector.get(CommonService).otherRole;
                    if (document.getElementById("selected-roles-other-role-name-text")) {
                        let callNotes = 'Role: ' + document.getElementById("selected-roles-other-role-name-text")["value"] + ', Full Name: ' + name + ', Phone Number: ' + number + ', ' + notesText;
                        document.getElementById('close-call-notes-textarea_')["value"] = callNotes.replace(/,\s*$/, "");
                    }
                    else {
                        let callNotes = 'Role: ' + OcInfraModule.AppInjector.get(CommonService).otherRole + ', Full Name: ' + name + ', Phone Number: ' + number + ', ' + notesText;
                        document.getElementById('close-call-notes-textarea_')["value"] = callNotes.replace(/,\s*$/, "");;
                    }

                    
                    
                    document.getElementById('close-call-notes-textarea_')["value"] = document.getElementById('close-call-notes-textarea_')["value"] + ', ' + hgroupText;
                    let noteText= document.getElementById("close-call-notes-textarea_")["value"];
                    noteText = noteText.replace(/,\s*$/, "");
                    document.getElementById('close-call-notes-textarea_')["value"] = noteText;

                    if (document.getElementById("selected-roles-other-role-name-text")) {
                        document.getElementById('other-role-auth-role-label_label').innerHTML = 'Role: ' + document.getElementById("selected-roles-other-role-name-text")["value"];
                    }
                    else {
                        document.getElementById('other-role-auth-role-label_label').innerHTML = 'Role: ' + OcInfraModule.AppInjector.get(CommonService).otherRole;
                    }
                    document.getElementById('other-role-auth-name-label_label').innerHTML = 'Full Name: ' + name;
                    this.authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse = response;
                    if ((<HTMLInputElement>document.getElementById('close-call-confirmation-checkbox-input')).checked) {
                        document.getElementById('close-call-confirmation-checkbox-input').click();
                        document.getElementById('close-call-confirmation-checkbox-input').click();
                    }
                    else {
                        document.getElementById('close-call-confirmation-checkbox-input').click();
                    }
                    document.getElementById('close-call-confirmation-checkbox_label').innerHTML = 'Confirmation #' + this.authResponse.updateResponse.data.confirmationNumber;
                }, 1000);
                let notes = resourceService.getResourceFromAliasName('policyNotes');
                if (notes && notes._links.item.length > 0) {
                    OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                } else {
                    OcInfraModule.AppInjector.get(CommonService).notesMsg = true;
                }
                let suspense = resourceService.getResourceFromAliasName('policySuspense');
                if (suspense && suspense._links.item.length > 0) {
                    OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                } else {
                    OcInfraModule.AppInjector.get(CommonService).SuspenseMsg = true;
                }
            });
        }
    }

    pendingTransactionDeleteVisible(param) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        if (param.href) {
            return resourceService.getResourceFromAliasName('authorizeSucess')
                && !(param.field.data.body.items.find(item => item.href == param.href).fields.find(col => col.id == "status").value.trim().toLowerCase() == "deleted");
        }

        return false;
    }

    pendingTransactionEditVisible(param) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        if (param.href && this.hideNonAnnuityFields()&& !this.isUL()) {
            if (resourceService.getResourceFromAliasName('authorizeSucess')
                && !(param.field.data.body.items.find(item => item.href == param.href).fields.find(col => col.id == "status").value.trim().toLowerCase() == "deleted")) {
                var hrefParts = param.href.split("/");
                let pendingTransactions = resourceService.getResourceFromAliasName('pendingTransactions');
                let pendingTransactionItems = pendingTransactions._links.item;
                let currentPend = pendingTransactionItems.find(element => element.summary.trx_key == hrefParts[hrefParts.length - 1].replace(/%20/g, " "));
                if (currentPend)
                    return currentPend.summary._options.links.find(link => link.method.toLowerCase() == "get");
            }
        }

        return false;
    }

    deletePendingTransaction(Params) {
        let msgShow = " ";
        let authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
        let otherRoleSucess = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let pendingTransactions = resourceService.getResourceFromAliasName('pendingTransactions');
        let roles = ["Owner", "joint-owner", "Agent"];
        if (otherRoleSucess || authsucess) {
            if (roles.includes(OcInfraModule.AppInjector.get(CommonService).authorizedRole)) {
                let pendingTransactionItems = pendingTransactions._links.item;
                let selectedPend = pendingTransactionItems.find(element => element.summary.trx_key == Params.rowId.replace(/%20/g, " "));
                if (selectedPend) {
                    let deleteUrl = selectedPend.summary._options.links.find(link => link.method.toLowerCase() == "delete");
                    const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("pendingTransactions");
                    const header = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("pendingTransactions");
                    headers['role'] = OcInfraModule.AppInjector.get(CommonService).selectedRole;
                    headers['trx_key'] = Params.rowId;
                    headers['status'] = selectedPend.summary.status ? selectedPend.summary.status : '';
                    OcInfraModule.AppInjector.get(CommonService).deleteCallback(deleteUrl.href, headers).subscribe(response => {
                        if (response._embedded.status_report.messages[0].message == "DataCollect Record Deletion is Successful") {
                            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response._embedded.status_report.messages[0].message, 'Success');
                            OcInfraModule.AppInjector.get(APICallerService).refresh(response._links.up.href, { 'alias': "pendingTransactions", 'headers': header }).subscribe(refreshResponse => {
                                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false })
                                setTimeout(() => {
                                    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                                    Params.modal.dialogRef.close();
                                }, 4000)
                            });
                        } else {
                            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong", 'Error', 0);
                            Params.modal.dialogRef.close();
                        }
                    });
                }
                else {
                    msgShow = "Authenticated party does not have authorization to make changes";
                }
            }
        }

        return msgShow;
    }

    editPendingTransaction(pendingTransactionRow) {
        var linkParts = pendingTransactionRow.href.split("/");
        let withdrawalSurrenderAction = new WithdrawalSurrenderAction();
        //set the type of withdrawal before opening the modal.
        switch (linkParts[linkParts.length - 2].toLowerCase()) {
            case "partial": {
                withdrawalSurrenderAction.createPartialSurrenderPendingTransactionAction(pendingTransactionRow.rowId.replace(/%20/g, " "));
                OcInfraModule.AppInjector.get(CommonService).withdrawalSurrenderAction =
                    withdrawalSurrenderAction;
                break;
            }
            case "full": {
                withdrawalSurrenderAction.createFullSurrenderPendingTransactionAction(pendingTransactionRow.rowId.replace(/%20/g, " "));
                OcInfraModule.AppInjector.get(CommonService).withdrawalSurrenderAction =
                    withdrawalSurrenderAction;
                break;
            }
            case "systematic": {
                withdrawalSurrenderAction.createSystematicWithdrawalPendingTransactionAction(pendingTransactionRow.rowId.replace(/%20/g, " "));
                OcInfraModule.AppInjector.get(CommonService).withdrawalSurrenderAction =
                    withdrawalSurrenderAction;
                break;
            }

        }
        // this is to open withdrawl modal window.
        document.getElementById("create-withdrawals-create-withdrawals-item-anchor").click();
    }

    authorizeCurrentPageRole(params) {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        let otherRoleName = OcInfraModule.AppInjector.get(CommonService).otherRole ? document.getElementById("selected-roles-name-text")["value"] : "";
        OcInfraModule.AppInjector.get(CommonService).otherRoleCallerName = otherRoleName.toUpperCase();
        let otherRoleNumber = OcInfraModule.AppInjector.get(CommonService).otherRole ? document.getElementById("selected-roles-phone-number-text")["value"] : "";
        let currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;
        let optionParams = new OptionParams();
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("authorize");
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
        let authorize = _.filter(Configuration.config.hostURL.multiHostUrl, function (o) { return o.alias == 'authorize' });
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'authorize' });
        let roleDetails = resourceService.getResourceFromAliasName('selectedRoles');
        let agentRoleDetails = OcInfraModule.AppInjector.get(CommonService).agentRoleDetails;
        let customerRoleDetails = OcInfraModule.AppInjector.get(CommonService).customerRoleDetails;
        this.requiredPayload.callerName = OcInfraModule.AppInjector.get(CommonService).otherRole ? otherRoleName.toUpperCase() : roleDetails.data.fullName;
        this.requiredPayload.callerRole = OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRole : roleDetails.data.role;
        let currentRole = customerRoleDetails && customerRoleDetails[0].summary && customerRoleDetails[0].summary.role + ' Ssn';
        let key = _.camelCase(currentRole);
        currentPage == '/screen/customerDetails' ? this.requiredPayload.personKey = customerRoleDetails[0].summary[key] : '';
        currentPage == '/screen/agentDetails' ? this.requiredPayload.agentKey = agentRoleDetails.response._links.item[0].summary.agentNIPR : '';
        this.requiredPayload.userId = OcInfraModule.AppInjector.get(CommonService).incontactId;
        this.requiredPayload.csrName = sessionStorage.getItem('username');
        header[0].headers.wma_user = window.sessionStorage.getItem('username');
        let payload = JSON.stringify(this.requiredPayload);
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        OcInfraModule.AppInjector.get(APICallerService).post(authorize[0].url, payload, 'autorization').subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
            OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
            OcInfraModule.AppInjector.get(CommonService).authResponse = response;
            OcInfraModule.AppInjector.get(CommonService).selectedReason = '';
            OcInfraModule.AppInjector.get(CommonService).startCall();
            document.getElementById("close-call-phone-number-text")["value"] = response.updateResponse && response.updateResponse.data && response.updateResponse.data.phoneNumber && response.updateResponse.data.phoneNumber.replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
            resourceService.updateCreateAliasName('authRes', response.updateResponse._options.links[0].href, true);
            if (OcInfraModule.AppInjector.get(CommonService).otherRole) {
                OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess = true;
                OcInfraModule.AppInjector.get(CommonService).changeotherRoleAuthsucess = true;
            } else {
                OcInfraModule.AppInjector.get(CommonService).authsucess = true;
                OcInfraModule.AppInjector.get(CommonService).changeRoleAuthsucess = true;
            }
            setTimeout(function () {
                Array.from(document.querySelectorAll(".expandOnAuthorize mat-expansion-panel-header")).forEach(accordian => {
                    if (!accordian["classList"].contains("mat-expanded")) {
                        document.getElementById(accordian.id).click();
                    }
                });
            }, 2000);
            setTimeout(function () {
                OcInfraModule.AppInjector.get(CommonService).authorizedRole = OcInfraModule.AppInjector.get(CommonService).otherRole;
                let notesText = document.getElementById("close-call-notes-textarea_")["value"];
                let displayNote = "";
                if (OcInfraModule.AppInjector.get(CommonService).otherRole) {
                    displayNote = 'Role:' + OcInfraModule.AppInjector.get(CommonService).otherRole + ', Full Name:' + otherRoleName.toUpperCase() + ', Phone Number:' + otherRoleNumber;
                    document.getElementById('selected-roles-agent-otherrole-label_label').innerHTML = 'Role: ' + OcInfraModule.AppInjector.get(CommonService).otherRole;
                    document.getElementById('selected-roles-other-role-full-name-label_label').innerHTML = 'Full Name: ' + otherRoleName.toUpperCase();
                } else {
                    displayNote = 'Role:' + roleDetails.data.role + ', Full Name:' + roleDetails.data.fullName;
                }
                document.getElementById('close-call-notes-textarea_')["value"] = notesText ? displayNote + ',' + notesText : displayNote;
                OcInfraModule.AppInjector.get(CommonService).authResponse = response;
            }, 1000);
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
        });
        setTimeout(function () {
            Array.from(document.querySelectorAll(".collapseOnAuthorize mat-expansion-panel-header")).forEach(accordian => {
                if (accordian["classList"].contains("mat-expanded")) {
                    document.getElementById(accordian.id).click();
                }
            });
        }, 2000);
    }

    selectReason(params) {
        OcInfraModule.AppInjector.get(CommonService).selectedReason = params.value;
    }

    disabkeOk() {
        return true;
    }

    closeCallAgent() {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        let currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'closeCall' });
        OcInfraModule.AppInjector.get(CommonService).changeHeaders(header[0].headers);
        this.authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse;
        let authResponseSummary = _.filter(this.authResponse.updateResponse._options.links, function (o) { return o.method === 'PATCH'; });
        let conformation = _.pickBy(this.authResponse.updateResponse.data, function (value, key) {
            return key == 'confirmationNumber' || key == 'contactType' || key == 'contactSessionID';
        });
        OcInfraModule.AppInjector.get(CommonService).confirmationNo || OcInfraModule.AppInjector.get(CommonService).tir2Ticket ? null : delete conformation['confirmationNumber'];
        let agentdetails = OcInfraModule.AppInjector.get(CommonService).agentRoleDetails;
        let customerRoleDetails = OcInfraModule.AppInjector.get(CommonService).customerRoleDetails;
        let currentRole = customerRoleDetails && customerRoleDetails[0].summary && customerRoleDetails[0].summary.role + ' Ssn';
        let selectedAgentSummary = OcInfraModule.AppInjector.get(CommonService).selectedAgentSummary;
        let key = _.camelCase(currentRole);
        currentPage == '/screen/customerDetails' ? this.closeCallPayLoad.personKey = customerRoleDetails[0].summary[key].replace(/[^a-zA-Z0-9]/g, '') : '';
        currentPage == '/screen/customerDetails' ? this.closeCallPayLoad.callerName = OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRoleCallerName : customerRoleDetails[0].summary.fullName : '';
        this.closeCallPayLoad.contactType = "9";
        this.closeCallPayLoad.callStatus = "end";
        if (currentPage == '/screen/agentDetails') {
            this.closeCallPayLoad.agentKey = agentdetails && agentdetails.response && agentdetails.response._links && agentdetails.response._links.item.length && agentdetails.response._links.item[0].summary.agentNIPR ? agentdetails.response._links.item[0].summary.agentNIPR : '';
            this.closeCallPayLoad.callerName = OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRoleCallerName : agentdetails && agentdetails.response && agentdetails.response._links && agentdetails.response._links.item.length && agentdetails.response._links.item[0].summary.fullName ? agentdetails.response._links.item[0].summary.fullName : '';
            this.closeCallPayLoad.agencyName = selectedAgentSummary.agency_name;
            this.closeCallPayLoad.firmName = agentdetails && agentdetails.response && agentdetails.response._links && agentdetails.response._links.item.length && agentdetails.response._links.item[0].summary.agentFirmName ? agentdetails.response._links.item[0].summary.agentFirmName : '',
                this.closeCallPayLoad.agentFirstName = agentdetails && agentdetails.response && agentdetails.response._links && agentdetails.response._links.item.length && agentdetails.response._links.item[0].summary.agentFirstName ? agentdetails.response._links.item[0].summary.agentFirstName : '';
            this.closeCallPayLoad.agentLastName = agentdetails && agentdetails.response && agentdetails.response._links && agentdetails.response._links.item.length && agentdetails.response._links.item[0].summary.agentLastName ? agentdetails.response._links.item[0].summary.agentLastName : '';
        }
        this.closeCallPayLoad.reason = OcInfraModule.AppInjector.get(CommonService).selectedReason;
        this.closeCallPayLoad.phoneNumber = document.getElementById("close-call-phone-number-text")["value"];
        if (document.getElementById("close-call-notes-textarea_")["value"] != '') {
            this.checkedProperties.notes = document.getElementById("close-call-notes-textarea_")["value"];
            let checkedProperty = this.checkedProperties.notes.replace(/\r?\n/g, ' ');
            this.checkedProperties.notes = '';
            this.checkedProperties.notes = checkedProperty;
        }
        _.isEmpty(this.checkedProperties['LOAN']) ? delete this.checkedProperties['LOAN'] : false;
        _.isEmpty(this.checkedProperties['RIDERS']) ? delete this.checkedProperties['RIDERS'] : false;
        _.isEmpty(this.checkedProperties['BILLING']) ? delete this.checkedProperties['BILLING'] : false;
        _.isEmpty(this.checkedProperties['SUSPENSE']) ? delete this.checkedProperties['SUSPENSE'] : false;
        _.isEmpty(this.checkedProperties['SPONSOR']) ? delete this.checkedProperties['SPONSOR'] : false;
        _.isEmpty(this.checkedProperties['REVOLVING']) ? delete this.checkedProperties['REVOLVING'] : false;
        _.isEmpty(this.checkedProperties['DETAILS']) ? delete this.checkedProperties['DETAILS'] : false;
        _.isEmpty(this.checkedProperties['VALUES']) ? delete this.checkedProperties['VALUES'] : false;
        _.isEmpty(this.checkedProperties['FUNDS']) ? delete this.checkedProperties['FUNDS'] : false;
        _.isEmpty(this.checkedProperties['NOTES']) ? delete this.checkedProperties['NOTES'] : false;
        this.closeCallPayLoad.text = JSON.stringify(this.checkedProperties);
        let payload = JSON.stringify(Object.assign(this.closeCallPayLoad, conformation));
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ role: null });
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        let header1 = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'transactions' });
        header1[0].headers.incontact_user = OcInfraModule.AppInjector.get(CommonService).incontactId;
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ 'statuaory_company_code': null });
        OcInfraModule.AppInjector.get(APICallerService).patch(authResponseSummary[0].href, payload, header1[0].headers).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
            OcInfraModule.AppInjector.get(CommonService).myCallHistory();
            setTimeout(function () {
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ remote_user: null });
                OcInfraModule.AppInjector.get(NavigationService).navigateTo('/screen/home');
                OcInfraModule.AppInjector.get(CommonService).resetPolicyDetails();
            }, 1000);
        },
            error => {
                error && error.msg ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.msg, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Something went wrong please try again', 'Error', 0);
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            });
    }

    closeCallAndGoToHome(): void {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'closeCall' });
        OcInfraModule.AppInjector.get(CommonService).changeHeaders(header[0].headers);
        let roleList = resourceService.getResourceFromAliasName('roles');
        let selectRole = _.filter(roleList.response._links.item, function (o) { return o.summary.role == 'Owner'; })
        let selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
        this.authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse;
        let authResponseSummary = _.filter(this.authResponse.updateResponse._options.links, function (o) { return o.method === 'PATCH'; });
        let conformation = _.pickBy(this.authResponse.updateResponse.data, function (value, key) {
            return key == 'confirmationNumber' || key == 'contactType' || key == 'contactSessionID';
        });
        OcInfraModule.AppInjector.get(CommonService).confirmationNo || OcInfraModule.AppInjector.get(CommonService).confirmation ? null : delete conformation['confirmationNumber'];
        // OcInfraModule.AppInjector.get(CommonService).confirmationNo == false ? delete conformation['confirmationNumber'] : '';
        for (var i = 0; i < localStorage.length; i++) {
            let namesObj = { 'policyDetails': 'DETAILS','policySponsor': 'SPONSOR', 'policySuspense': 'SUSPENSE', "loanInfo": "LOAN", "fundInfo": "FUNDS", "fundInfoFuture":"FUNDS", "selectedCoverage": "RIDERS", "policyNFOInformation": "NFO INFORMATION", "selectedNote": "NOTES", "policyValues": "VALUES", "billingInfo": "BILLING", "beneficiaryRoles": "BENEFICIARY DETAILS", "loanQuote": "LOAN QUOTE" }
            if (localStorage.key(i) == "policyDetails"|| localStorage.key(i) == "policySponsor" || localStorage.key(i) == 'policySuspense' || localStorage.key(i) == 'loanInfo' || localStorage.key(i) == 'fundInfo' || localStorage.key(i) == 'fundInfoFuture'
                    || localStorage.key(i) == 'selectedCoverage' || localStorage.key(i) == 'policyNFOInformation' || localStorage.key(i) == 'selectedNote' || localStorage.key(i) == 'policyValues' || localStorage.key(i) == 'billingInfo' 
                    || localStorage.key(i) == 'beneficiaryRoles' || localStorage.key(i) == 'loanQuote') {
                for (let key in namesObj) {
                    let item = {};
                    key == localStorage.key(i) ? item[namesObj[localStorage.key(i)]] = JSON.parse(localStorage.getItem(localStorage.key(i))) : '';
                    _.assign(this.checkedProperties, item);
                    if(key === localStorage.key(i) && (localStorage.key(i)=="fundInfo" || localStorage.key(i)=="fundInfoFuture" ) && (this.isOnlyGroupAnn() || this.isRetailAnnuity())) {
                        if(key === localStorage.key(i) && (localStorage.key(i)=="fundInfo")){
                            this.currFutFunds.push(_.get(item, 'FUNDS'));
                        } else {
                            this.currFutFunds.push(_.get(item, 'FUNDS'));
                            this.currFutFunds[0]['futureFunds'] =true;
                        }
                    }
                }
            }
        }
        if((this.isOnlyGroupAnn() || this.isRetailAnnuity()) && this.currFutFunds.length > 0) {
            this.checkedProperties["FUNDS"] = this.currFutFunds;
        }
        let authSucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
        let fullName: any = '';
        if (authSucess) {
            if (selectedRoleSummary.data.role == 'Payor') {
                fullName = selectedRoleSummary.data.payorFullName ? selectedRoleSummary.data.payorFullName : "";
            } else if(selectedRoleSummary.data.role == 'Third Party Administrator'){
                fullName = selectedRoleSummary.data.thirdPartyFullName ? selectedRoleSummary.data.thirdPartyFullName : "";
            } else {
                fullName = selectedRoleSummary.data.fullName ? selectedRoleSummary.data.fullName : selectedRoleSummary.data.ownerFullName ? selectedRoleSummary.data.ownerFullName : selectedRoleSummary.data.annuitantFullName ? selectedRoleSummary.data.annuitantFullName : selectedRoleSummary.data.beneficiaryName ? selectedRoleSummary.data.beneficiaryName : selectedRoleSummary.data.jointOwnerFullName ? selectedRoleSummary.data.jointOwnerFullName : selectedRoleSummary.data.agentFullName ? selectedRoleSummary.data.agentFullName : selectedRoleSummary.data.coOwnerFullName ? selectedRoleSummary.data.coOwnerFullName : selectedRoleSummary.data.insuredName ? selectedRoleSummary.data.insuredName : selectedRoleSummary.data.irrevocableBeneficiaryName ? selectedRoleSummary.data.irrevocableBeneficiaryName : selectedRoleSummary.data.powerOfAttorneyName ? selectedRoleSummary.data.powerOfAttorneyName : selectedRoleSummary.data.insuredFullName ? selectedRoleSummary.data.insuredFullName : "";
            }
        } else {
            fullName = document.getElementById("selected-roles-name-text") ? document.getElementById("selected-roles-name-text")["value"] : "";
        }
        let transactions = OcInfraModule.AppInjector.get(CommonService).allTransactions;
        transactions ? _.assign(this.checkedProperties, transactions) : null;
        OcInfraModule.AppInjector.get(CommonService).allTransactions = {};
        let quotes = OcInfraModule.AppInjector.get(CommonService).quoteValues;
        quotes ? _.assign(this.checkedProperties, quotes) : null;
        let loanQuotes = OcInfraModule.AppInjector.get(CommonService).quoteInitiation;
        loanQuotes ? _.assign(this.checkedProperties, loanQuotes) : null;
        this.closeCallPayLoad.callerName = fullName ? fullName : "";

        if(this.isOnlyGroupAnn()) {
            let rmdDetails = JSON.parse(localStorage.getItem('rmd-quote-values'));
            if(rmdDetails!==null) {
                this.rmdGroup.push(rmdDetails);
                this.checkedProperties['RMD QUOTE'] = this.rmdGroup;   
            }
        } 

        let rmdDetails = OcInfraModule.AppInjector.get(CommonService).rmdDetails;
        rmdDetails ? _.assign(this.checkedProperties, rmdDetails) : null;
           

        let fullSurrDetails = OcInfraModule.AppInjector.get(CommonService).fullSurrDetails;
        fullSurrDetails ? _.assign(this.checkedProperties, fullSurrDetails) : null;
        
        let systematicWithdrawalDetails = OcInfraModule.AppInjector.get(CommonService).systematicWithdrawalDetails;
        systematicWithdrawalDetails ? _.assign(this.checkedProperties, systematicWithdrawalDetails) : null;
      
        let fundDetails = OcInfraModule.AppInjector.get(CommonService).fundDetails;
        fundDetails ? _.assign(this.checkedProperties, fundDetails) : null;
        const authResponse = resourceService.getResourceFromAliasName('authRes');
        this.closeCallPayLoad.contactType = "9";
        this.closeCallPayLoad.contactID = this.authResponse.updateResponse.data.contactID;
        this.closeCallPayLoad.callStatus = "end";
        this.closeCallPayLoad.callStart = authResponse && authResponse.data.callStart ? authResponse.data.callStart : '';
        this.closeCallPayLoad.reason = OcInfraModule.AppInjector.get(CommonService).selectedReason;
        this.closeCallPayLoad.phoneNumber = document.getElementById("close-call-phone-number-text")["value"];

        let selectOtherRole = _.filter(roleList.response._links.item, function (o) { return o.summary.clientId == OcInfraModule.AppInjector.get(CommonService).clientId });

        if(selectRole[0] && selectRole[0].summary && selectRole[0].summary.hostKey) {
            this.closeCallPayLoad.contractId = selectRole[0].summary.hostKey;
        } else if(selectedRoleSummary && selectedRoleSummary.data && selectedRoleSummary.data.hostKey) {
            this.closeCallPayLoad.contractId = selectedRoleSummary.data.hostKey;
        } else if(selectOtherRole[0] && selectOtherRole[0].summary && selectOtherRole[0].summary.hostKey) {
            this.closeCallPayLoad.contractId = selectOtherRole[0].summary.hostKey
        } else {
            this.closeCallPayLoad.contractId = '';
        }

        if(selectedRoleSummary && selectedRoleSummary.data && selectedRoleSummary.data.hgroupNumber) {
            this.closeCallPayLoad.groupNumber = selectedRoleSummary.data.hgroupNumber;
        } else if(selectOtherRole[0] && selectOtherRole[0].summary && selectOtherRole[0].summary.hgroupNumber) {
            this.closeCallPayLoad.groupNumber = selectOtherRole[0].summary.hgroupNumber;
        } else {
            this.closeCallPayLoad.groupNumber = '';
        }
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        policyDetails && policyDetails.data && policyDetails.data.policy_number ? this.closeCallPayLoad.policyNumber = policyDetails.data.policy_number :"";

        this.isOnlyGroupAnn() ? this.closeCallPayLoad.superiorClient =   selectedRoleSummary && selectedRoleSummary.data.superiorClient ? selectedRoleSummary.data.superiorClient : '' : "";
        if (document.getElementById("close-call-notes-textarea_")["value"] != '') {
            this.checkedProperties.notes = document.getElementById("close-call-notes-textarea_")["value"];
            let checkedProperty = this.checkedProperties.notes.replace(/\r?\n/g, ' ');
            this.checkedProperties.notes = '';
            this.checkedProperties.notes = checkedProperty;
        }
        if(OcInfraModule.AppInjector.get(CommonService).mfaCallNotesMessage){
            this.checkedProperties['MFA'] = OcInfraModule.AppInjector.get(CommonService).mfaCallNotesMessage;
        }
        if(OcInfraModule.AppInjector.get(CommonService).notesAdditionHtml){
            this.checkedProperties['NOTES ADDITION'] = OcInfraModule.AppInjector.get(CommonService).notesAdditionHtml;
        }
        _.isEmpty(this.checkedProperties['LOAN']) ? delete this.checkedProperties['LOAN'] : false;
        _.isEmpty(this.checkedProperties['RIDERS']) ? delete this.checkedProperties['RIDERS'] : false;
        _.isEmpty(this.checkedProperties['BILLING']) ? delete this.checkedProperties['BILLING'] : false;
        _.isEmpty(this.checkedProperties['SUSPENSE']) ? delete this.checkedProperties['SUSPENSE'] : false;
        _.isEmpty(this.checkedProperties['SPONSOR']) ? delete this.checkedProperties['SPONSOR'] : false;
        _.isEmpty(this.checkedProperties['REVOLVING']) ? delete this.checkedProperties['REVOLVING'] : false;
        _.isEmpty(this.checkedProperties['DETAILS']) ? delete this.checkedProperties['DETAILS'] : false;
        _.isEmpty(this.checkedProperties['VALUES']) ? delete this.checkedProperties['VALUES'] : false;
        _.isEmpty(this.checkedProperties['FUNDS']) ? delete this.checkedProperties['FUNDS'] : false;
        _.isEmpty(this.checkedProperties['NOTES']) ? delete this.checkedProperties['NOTES'] : false;
        _.isEmpty(this.checkedProperties['BENEFICIARY DETAILS']) ? delete this.checkedProperties['BENEFICIARY DETAILS'] : false;

        this.closeCallPayLoad.text = JSON.stringify(this.checkedProperties);
        let payload = JSON.stringify(Object.assign(this.closeCallPayLoad, conformation));
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ role: null });
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        let header1 = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'transactions' });
        header1[0].headers.incontact_user = OcInfraModule.AppInjector.get(CommonService).incontactId;
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ 'statuaory_company_code': null });
        OcInfraModule.AppInjector.get(CommonService).patchCallback(authResponseSummary[0].href, payload, header1[0].headers).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
            OcInfraModule.AppInjector.get(CommonService).myCallHistory();
            /* While close call we doing global service re-instatement total 
            remit due value setting empty and checkbox disable */
            OcInfraModule.AppInjector.get(CommonService).total_remit_due = '';
            // Billing Tab - Reinstatement quote check box disabled
            OcInfraModule.AppInjector.get(CommonService).disableReinstatementCheckbox();

            if(OcInfraModule.AppInjector.get(CommonService).updateClaimPartyUrl) {
                let payloadAWDClaims = {comment: JSON.stringify(this.checkedProperties) };
                // comment for PANOCSR-3134
                this.closeCallForAWDClaimsAssociate(payloadAWDClaims, header1[0].headers);
            }
            setTimeout(function () {
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders({ remote_user: null });
                OcInfraModule.AppInjector.get(CommonService).resetPolicyDetails();
                OcInfraModule.AppInjector.get(NavigationService).navigateTo('/screen/home');
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
            }, 1000);

        },
            error => {
                error && error.msg ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.msg, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Something went wrong please try again', 'Error', 0);
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
            });
    }

    closeCallForAWDClaimsAssociate = (payload: any, headers: any) => {       
        let updateClaimPartyUrl = OcInfraModule.AppInjector.get(CommonService).updateClaimPartyUrl;
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(CommonService).postCallback(updateClaimPartyUrl, payload, headers).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
        },
            error => {
                error && error.msg ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.msg, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Something went wrong please try again', 'Error', 0);
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
        });
    }

    hideProperties(field) {

        if (field && (field.value || field.type === "button")) {
            return true;
        }
        return false;
    }
    hideShowProperties(field) {
        if (field && (field.value == '' || field.value || field.type === "button")) {
            return true;
        }
        return false;
    }

    hideShowHGrpProperties(field) {

       
        if( this.hideShowProperties(field)){
            let productInfo = this.commonService.productInfo;
            return productInfo && productInfo.product_type && productInfo["product_type"].toLowerCase() == "h"
        } else
            return false; 
    }

    showAuthSucessFields(field) {
        if (OcInfraModule.AppInjector.get(CommonService).authsucess && field && field.value) {
            return OcInfraModule.AppInjector.get(CommonService).changeotherRoleAuthsucess ?  false :  true;            
        }
        return false;
    }

    showInsuredField(field) {
        const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles');        
        if (selectedRoleSummary && selectedRoleSummary.data && !selectedRoleSummary.data.coOwnerFullName && !selectedRoleSummary.data.thirdPartyFullName && !selectedRoleSummary.data.payorFullName && !selectedRoleSummary.data.fullName && !selectedRoleSummary.data.beneficiaryName && OcInfraModule.AppInjector.get(CommonService).authsucess && !OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess && OcInfraModule.AppInjector.get(CommonService).changeRoleAuthsucess && field && field.value) {
            return true;
        }        
        return false;
    }

    showGroupNumber(field) {
        const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles'); 
        let productInfo = this.commonService.productInfo;
             
        if (((selectedRoleSummary && selectedRoleSummary.data && selectedRoleSummary.data.role == 'Third Party Administrator') || OcInfraModule.AppInjector.get(CommonService).otherRoleNameText == 'Plan Administrator') && field && field.value &&
                productInfo && productInfo["product_type"].toLowerCase() != "h") {
            return true;
        }        
        return false;
    }

    agentAuthSucessFields(field){
        if (OcInfraModule.AppInjector.get(CommonService).authsucess && field && (field.value == '' || field.value || field.type === "button")) {
            return OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess ?  false :  true;
        }
        return false;
    }

    showAgentAuthSucessFields() {
        return OcInfraModule.AppInjector.get(CommonService).authsucess;
    }

    otherRoleAuthSucessFields() {
        if (OcInfraModule.AppInjector.get(CommonService).changeotherRoleAuthsucess) {
            return OcInfraModule.AppInjector.get(CommonService).changeRoleAuthsucess ?  false :  true;            
        }
        return false;
    }
  
    showOtherRoleName() {
        if (OcInfraModule.AppInjector.get(CommonService).selectedOther) {
            return true;
        }
        return false;
    }

    getSelectedWorkItem(params) {
        OcInfraModule.AppInjector.get(CommonService).deliveryMethod = false;
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItemType = params.value;
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItemValue = params.viewValue;
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItemParams = params;
        let forms = ["STOPSUSP", "ANNSTPREIS", "STOPREISSU", "STOPREISS", "VOIDREISSU", "VOIDSUSP", "FORMS", "GRPFORMS", "LTCNOTICE", "STOPPRECNV", "STOPPRECONV", "CDSCHKCOPY", "CORRESFCOM", "CORRESVCOM", "INVSTRAT", "ANNMRDAUT", "ANNMRD", "ANNWTHDRW", "ANNSURR", "ANNSWP", "GRPINVSTRT", "GRPMRDAUT", "GRPMRD", "GRPWTHDRAW", "GRPSURR", "GRPSWP"];
        let attachDocForms = ["INVSTRAT", "ANNMRDAUT", "ANNMRD", "ANNWTHDRW", "ANNSURR", "ANNSWP", "GRPINVSTRT", "GRPMRDAUT", "GRPMRD", "GRPWTHDRAW", "GRPSURR", "GRPSWP", "ANTZQUOTES", "TAXINQ", "GRPANTZQTS", "GRPTAXINQ"];
        OcInfraModule.AppInjector.get(CommonService).attachDocWorkReq = attachDocForms.includes(params.value) ? true : false;
        OcInfraModule.AppInjector.get(CommonService).otherKey = false;
        OcInfraModule.AppInjector.get(CommonService).oAmount = '';
        if (forms.includes(params.value)) {
            if ((params.value == "FORMS" || params.value == "GRPFORMS")) {
                let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'types1' });
                let formsUrl = params.href.slice(0, -5) + 'schemas/types/'+params.value;
                OcInfraModule.AppInjector.get(CommonService).getCallback(formsUrl, header[0].headers).subscribe(response => {
                    OcInfraModule.AppInjector.get(CommonService).formsList = response[params.value].properties.formsList;
                    document.getElementById("work-items-open-modal-icon-open-form-request-items-anchor").click();
                    params.value = null;
                })
            } else {
                document.getElementById("work-items-open-modal-icon-open-form-request-items-anchor").click();
                params.value = null;
            }
        } else {
            this.openWorkItemsModal(params);
        }
    }

    openWorkItemsModal(params) {
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workitemsSchema' });
        OcInfraModule.AppInjector.get(CommonService).changeHeaders({ remote_user: null });
        let selectedWorkItem = 'types/' + params.value;
        let optionParams = {
            "resourceSchema": selectedWorkItem
        };
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItem = selectedWorkItem;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const resourceSchemaService = OcInfraModule.AppInjector.get(ResourceSchemaService);
        resourceSchemaService.getSchema(selectedWorkItem, optionParams, null, 'workitemsSchema').subscribe(schemaResponse => {
            OcInfraModule.AppInjector.get(CommonService).deliveryMethod = schemaResponse.data && schemaResponse.data.deliveryMethod ? true : false;
            document.getElementById("work-items-open-modal-icon-open-work-items-anchor").click();
            let workItemsSchemaAlias = resourceService.getAliasByAliasName("workitemsSchema");
            workItemsSchemaAlias.resourceSchema = selectedWorkItem;
            params.value = null;
        });
    }

    selectedWorkItem(params) {
        OcInfraModule.AppInjector.get(CommonService).preFillIndicator = false;
        let policyDetails = this.resourceService.getResourceFromAliasName('policyDetails');
        const selectedRoleSummary = this.resourceService.getResourceFromAliasName('selectedRoles');
        OcInfraModule.AppInjector.get(CommonService).policyNumber = OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess ? policyDetails && policyDetails.data && policyDetails.data.policy_number : selectedRoleSummary.data.policyNumber;
        OcInfraModule.AppInjector.get(CommonService).attachedFiles = [];
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        let currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;
        OcInfraModule.AppInjector.get(CommonService).deliveryMethod = false;
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItemType = params.value;
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItemValue = params.viewValue;
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItemParams = params;
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workitemsSchema' });
        OcInfraModule.AppInjector.get(CommonService).changeHeaders({ remote_user: null });
        let selectedWorkItem = 'types/' + params.value;
        let optionParams = {
            "resourceSchema": selectedWorkItem
        };
        OcInfraModule.AppInjector.get(CommonService).selectedWorkItem = selectedWorkItem;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const resourceSchemaService = OcInfraModule.AppInjector.get(ResourceSchemaService);
        resourceSchemaService.getSchema(selectedWorkItem, optionParams, true, 'workitemsSchema').subscribe(schemaResponse => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            resourceSchemaService.updateSchemaMap(schemaResponse);
            OcInfraModule.AppInjector.get(CommonService).deliveryMethod = schemaResponse.data && schemaResponse.data.deliveryMethod ? true : false;
            currentPage == '/screen/customerDetails' ? document.getElementById("work-items-open-modal-icon-open-work-items-customer-anchor").click() : '';
            currentPage == '/screen/agentDetails' ? document.getElementById("work-items-open-modal-icon-open-work-items-agent-anchor").click() : '';
            let workItemsSchemaAlias = resourceService.getAliasByAliasName("workitemsSchema");
            workItemsSchemaAlias.resourceSchema = selectedWorkItem;
            params.value = null;
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
        });
    }

    createWorkRequestItem(params) {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        let attachedFiles = OcInfraModule.AppInjector.get(CommonService).attachedFiles;
        let supportedFiletype = [];
        let fileName = ["img", "jpg", "pdf", "jpeg", "docx", "xlsx",];
        if (!(!attachedFiles || !attachedFiles.length)) {
            attachedFiles.forEach(headerObj => {
                let key = Object.keys(headerObj)
                let filetype = fileName.includes(key[0].split('.').pop().toLowerCase());
                supportedFiletype.push(filetype);
            })
        };
        if (!(supportedFiletype.includes(false))) {
            const modalProperties = params.modal.renderer.data.sections[0].properties;
            let modalPayloadProperties = {};
            let agentRoleDetails = OcInfraModule.AppInjector.get(CommonService).agentRoleDetails;
            modalProperties.forEach(propertiesObj => {
                if (propertiesObj.value) {
                    modalPayloadProperties[propertiesObj.id] = propertiesObj.value
                    propertiesObj.id == "callerPhoneNumber" ? modalPayloadProperties[propertiesObj.id] = propertiesObj.value != '' ? propertiesObj.value.replace(/[^a-zA-Z0-9]/g, '') : '' : '';
                    propertiesObj.id == "phoneCallRequested" ? modalPayloadProperties[propertiesObj.id] = propertiesObj.value ? "Yes" : "No" : '';
                    propertiesObj.id == "firmName" ? modalPayloadProperties["agencyName"] = propertiesObj.value : null;
                    propertiesObj.id == "description" ? modalPayloadProperties[propertiesObj.id] = document.getElementById('workitems-schema-description-of-issue-textarea_')["value"] : '';
                } else {
                    propertiesObj.id == "NIPR" ? modalPayloadProperties[propertiesObj.id] = agentRoleDetails.response._links.item[0].summary.agentNIPR : '';

                    propertiesObj.id == "summaryOfIssue" ? modalPayloadProperties[propertiesObj.id] = document.getElementById('workitems-schema-summary-of-issue-text')["value"] : '';
                    propertiesObj.id == "phoneCallRequested" ? modalPayloadProperties[propertiesObj.id] = propertiesObj.value ? "Yes" : "No" : '';
                    propertiesObj.id == "firmName" ? modalPayloadProperties["agencyName"] = '' : null;
                }
            });
            let currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            const refreshWorkRequestHref = resourceService.getHrefByAliasName("workRequestHistory");
            const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
            const authResponse = resourceService.getResourceFromAliasName('authRes');
            let roleDetails = resourceService.getResourceFromAliasName('selectedRoles');
            const headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("workRequestHistory")
            headers['role'] = OcInfraModule.AppInjector.get(CommonService).selectedRole;
            this.requiredPayload.callerName = OcInfraModule.AppInjector.get(CommonService).otherRole ? document.getElementById("selected-roles-name-text")["value"].toUpperCase : roleDetails.data.fullName;
            this.requiredPayload.callerRole = OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRole : roleDetails.data.role;
            const payload = {
                "workType": "COMMOLS",
                "type": "OLS Tier 2 Ticket",
                "callerName": OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRoleCallerName : roleDetails.data.fullName,
                "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
                "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
                "inContactID": OcInfraModule.AppInjector.get(CommonService).incontactId,
                "ticketPriority": "MODERATE",
                "origin_app": "csr_portal",
            };
            _.assign(payload, modalPayloadProperties)
            if (currentPage == '/screen/agentDetails') {
                let agentInfo = OcInfraModule.AppInjector.get(CommonService).selectedAgentSummary;
                let agentPayLoad = {
                    "advisorName": OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRoleCallerName : agentRoleDetails && agentRoleDetails.response && agentRoleDetails.response._links && agentRoleDetails.response._links.item.length ? agentRoleDetails.response._links.item[0].summary.fullName : '',
                    "agencyName": agentInfo && agentInfo.agency_name ? agentInfo.agency_name : '',
                    "clientId": agentRoleDetails && agentRoleDetails.response && agentRoleDetails.response._links && agentRoleDetails.response._links.item.length && agentRoleDetails.response._links.item[0].summary.clientId ? agentRoleDetails.response._links.item[0].summary.clientId : '',
                    "callerRole": OcInfraModule.AppInjector.get(CommonService).otherRole ? "Agent" : roleDetails.data.role,
                    "agentFirstName": OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRoleCallerName : agentRoleDetails && agentRoleDetails.response && agentRoleDetails.response._links && agentRoleDetails.response._links.item.length && agentRoleDetails.response._links.item[0].summary.agentFirstName ? agentRoleDetails.response._links.item[0].summary.agentFirstName : '',
                    "agentLastName": OcInfraModule.AppInjector.get(CommonService).otherRole ? "" : agentRoleDetails && agentRoleDetails.response && agentRoleDetails.response._links && agentRoleDetails.response._links.item.length && agentRoleDetails.response._links.item[0].summary.agentLastName ? agentRoleDetails.response._links.item[0].summary.agentLastName : '',
                    "agentNumber": agentRoleDetails && agentRoleDetails.response && agentRoleDetails.response._links && agentRoleDetails.response._links.item.length ? agentRoleDetails.response._links.item[0].summary.agentNIPR : '',
                    "AWDComments": document.getElementById('workitems-schema-description-of-issue-textarea_')["value"],
                    "emailRole": "ADVISOR",
                }
                _.assign(payload, agentPayLoad);
            }
            let roledetails = {};
            if (currentPage == '/screen/customerDetails') {
                let roleList = resourceService.getResourceFromAliasName('roles');
                let customerdetails = OcInfraModule.AppInjector.get(CommonService).customerDetails;
                let roleName = OcInfraModule.AppInjector.get(CommonService).otherRole ? customerdetails.summary.role : OcInfraModule.AppInjector.get(CommonService).selectedRole;
                let selectedRole = _.filter(roleList._links.item, function (o) { return o.summary.role == roleName; });
                let role = OcInfraModule.AppInjector.get(CommonService).otherRole ? customerdetails.summary.role : selectedRole[0].summary.role

                if (role == "Owner") {
                    roledetails = {
                        "clientId": selectedRole[0].summary.clientId,
                        "callerRole": OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRole : roleDetails.data.role,
                        "socialSecurityNumber": selectedRole[0].summary.ownerSsn.replace(/-/g, ''),
                        "ownerFirstName": selectedRole && selectedRole[0].summary && selectedRole[0].summary.ownerFirstName ? selectedRole[0].summary.ownerFirstName : '',
                        "ownerLastName": selectedRole && selectedRole[0].summary && selectedRole[0].summary.ownerLastName ? selectedRole[0].summary.ownerLastName : '',
                        "ownerCompanyName": "",
                        "emailRole": "CLIENT",
                    }
                } else if (role == "Annuitant") {
                    roledetails = {
                        "clientId": selectedRole[0].summary.clientId,
                        "callerRole": OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRole : roleDetails.data.role,
                        "socialSecurityNumber": selectedRole[0].summary.annuitantSsn.replace(/-/g, ''),
                        "insuredFirstName": selectedRole && selectedRole[0].summary && selectedRole[0].summary.annuitantFirstName ? selectedRole[0].summary.annuitantFirstName : '',
                        "insuredLastName": selectedRole && selectedRole[0].summary && selectedRole[0].summary.annuitantLastName ? selectedRole[0].summary.annuitantLastName : '',
                        "emailRole": "CLIENT",
                    }
                }
                else if (role == "Joint-Owner") {
                    roledetails = {
                        "clientId": selectedRole[0].summary.clientId,
                        "callerRole": OcInfraModule.AppInjector.get(CommonService).otherRole ? OcInfraModule.AppInjector.get(CommonService).otherRole : roleDetails.data.role,
                        "socialSecurityNumber": selectedRole[0].summary.jointOwnerSsn.replace(/-/g, ''),
                        "jointOwnerFirstName": selectedRole && selectedRole[0].summary && selectedRole[0].summary.jointOwnerFirstName ? selectedRole[0].summary.jointOwnerFirstName : '',
                        "jointOwnerLastName": selectedRole && selectedRole[0].summary && selectedRole[0].summary.jointOwnerLastName ? selectedRole[0].summary.jointOwnerLastName : '',
                        "emailRole": "CLIENT",
                    }
                }
                _.assign(payload, roledetails);
            }
            for (const property in payload) {
                payload[property] == "" ? delete payload[property] : null;
            }
            !attachedFiles || !attachedFiles.length ? '' : payload['attachments'] = OcInfraModule.AppInjector.get(CommonService).attachedFiles;
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
            OcInfraModule.AppInjector.get(CommonService).postCallback(workRequestHref, payload, headers).subscribe(response => {
                if (response.success) {
                    OcInfraModule.AppInjector.get(CommonService).tir2Ticket = true;
                    OcInfraModule.AppInjector.get(CommonService).attachedFiles = [];
                    OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
                    setTimeout(() => {
                        let optionParams = new OptionParams();
                        optionParams.alias = "workRequestHistory";
                        optionParams.params = OcInfraModule.AppInjector.get(CommonService).instanceParams;
                        optionParams.headers = headers;
                        OcInfraModule.AppInjector.get(APICallerService).refresh(refreshWorkRequestHref, optionParams, 'RESET').subscribe(refreshResponse => {
                            params.modal.dialogRef.close();
                            setTimeout(() => {
                                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
                            }, 7000)
                        });
                    }, 5000);
                }
            }, error => {
                error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) :
                    OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Something went wrong please try again', 'Error', 0);
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            });
        } else {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Attachment format not allowed', 'Error', 0);
        }
    }

    chanageInsuredNameLabel(field) {
        if (field && (field.value || field.type === "button")) {
            let element = document.getElementsByClassName("insuredNameLabel");
            let policy = OcInfraModule.AppInjector.get(CommonService).productInfo;
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
            if (policy && (policy.product_type == 'A1' || policy.product_type == 'A2') && element.length && selectedIdentity) {
                selectedIdentity.data.role == 'Agent' ? element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Annuitant / Joint Annuitant Full Name' : element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Annuitant Name';
            } else if (selectedIdentity && element.length) {
                selectedIdentity.data.role == 'Agent' ? element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Insured / Joint Insured Full Name' : '';
            }
            return true;
        }
        return false;
    }

    changeRoleFullNameLabel(field) {
        if (field && (field.value == '' || field.value || field.type === "button")) {
            let element = document.getElementsByClassName("roleNameLabel");
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
            if (element.length && selectedIdentity) {
                selectedIdentity.data.role == 'Owner' && selectedIdentity.data.partType == 'individual' ? element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Owner Full Name'
                    : selectedIdentity.data.role == 'Owner' && selectedIdentity.data.partType == 'corporate' ? element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Owner Name'
                        : selectedIdentity.data.role == 'Insured' ? element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Insured Full Name'
                            : selectedIdentity.data.role == 'Co-Owner' ? element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Co-Owner Full Name'
                                : selectedIdentity.data.role == 'Agent' ? element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Agent Full Name'
                                    : selectedIdentity.data.role == 'Irrevocable Beneficiary' ? element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Irrevocable Beneficiary Name'
                                        : selectedIdentity.data.role == 'Power of Attorney' ? element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Power of Attorney Name'
                                            : selectedIdentity.data.role == 'Beneficiary' ? element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Beneficiary Name'
                                                : element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Full Name';
            }
            return true;
        }
        return false;
    }

    changeRoleOwnerAddressLabel(field) {
        if (field && (field.value || field.type === "button" || field.value == '')) {
            let element = document.getElementsByClassName("ownerAddLabel");
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
            if (element.length && selectedIdentity) {
                selectedIdentity.data.role == 'Co-Owner' ? element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Primary Owner Address'
                    : element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Owner Address';
            }
            return true;
        }
        return false;
    }

    changeRoleOwnerSSNLabel(field) {
        if (field && (field.value || field.type === "button" || field.type === "label" || field.value == '')) {
            let element = document.getElementsByClassName("ownerSsnlabel");
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
            //this else if block added for resolving PANOCSR-599
            if (field && (field.value || field.type === "button" || field.value === "" ))
                return true;
            else if (!field.value && selectedIdentity && selectedIdentity.data && selectedIdentity.data.ownerSsn == '') {
                return true;
            }
        }
        return false;
    }

    chanageInsuredSsnLabel(field) {
        if (field && (field.value || field.type === "button" || field.value == '')) {
            let element = document.getElementsByClassName("insuredSsnLabel");
            let policy = OcInfraModule.AppInjector.get(CommonService).productInfo;
            if (policy && (policy.product_type == 'A1' || policy.product_type == 'A2') && element.length) {
                element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Annuitant SSN';
            }
            return true;
        }
        return false;
    }

    chanageInsuredDobLabel(field) {
        if (field && (field.value || field.type === "button" || field.value == '')) {
            let element = document.getElementsByClassName("insuredDobLabel");
            let policy = OcInfraModule.AppInjector.get(CommonService).productInfo;
            if (policy && (policy.product_type == 'A1' || policy.product_type == 'A2') && element.length) {
                element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'Annuitant DOB';
            }
            return true;
        }
        return false;
    }

    selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
    covrageChecked(field) {
        let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
        this.selectdValues = OcInfraModule.AppInjector.get(CommonService).selectedCoverages;
        let keys = Object.keys(this.selectdValues[0][field.alias]);
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let getResourceByHref = resourceService.getResourceByHref(field.href);
        let response = getResourceByHref.response;
        let checkedId = field.id.slice(0, -7);
        let checkedFields = OcInfraModule.AppInjector.get(CommonService).checkedFields
        switch (field.alias) {
            case 'selectedCoverage':
                if (this.isAnnuity()) {
                    let slicedClass = field.class.slice(0, 2);
                    let store = JSON.parse(localStorage.getItem('selectedCoverage' + OcInfraModule.AppInjector.get(CommonService).currentPolicyNumber) || '{}');
                    if (field.value) {
                        field['lab'] = this.selectedLang[field.label];
                        field['val'] = field['lab'] == 'Max Benefit Percent' && response[checkedId] ? (response[checkedId] + "%") : currencyFields.includes(field['lab']) ? response[checkedId] ? ("$" + response[checkedId]) : 'No data in admin system.' : response[checkedId] ? response[checkedId] : 'No data in admin system.';
                        checkedFields[slicedClass].push(field);
                        if (!store[slicedClass]) {
                            store[slicedClass] = { [field.lab]: field.val }
                        } else {
                            store[slicedClass] = Object.assign(store[slicedClass], { [field.lab]: field.val });
                        }
                    } else {
                        if (store[slicedClass]) { delete store[slicedClass][field.lab]; }
                        const index = checkedFields[slicedClass].findIndex(i => i.id === field.id);
                        if (index > -1) checkedFields[slicedClass].splice(index, 1);
                    }
                    localStorage.setItem('selectedCoverage' + OcInfraModule.AppInjector.get(CommonService).currentPolicyNumber, JSON.stringify(store));
                    let finalHTML = '';
                    if (checkedFields.IN.length > 0) {
                        finalHTML = `<span style="font-size:15px" class=""><b>Insured and Coverage Information</b></span><dl class="">${checkedFields.IN.map(f => `<dt class="pull-left">${f.lab}:</dt><dd class="mb-1">&nbsp;${f.val}</dd>`).join('')}</dl>`;
                    }
                    if (checkedFields.DA.length > 0) {
                        finalHTML += `<span style="font-size:15px" class=""><b>Date and Age Information</b></span><dl class="">${checkedFields.DA.map(f => `<dt class="pull-left">${f.lab}:</dt><dd class="mb-1">&nbsp;${f.val}</dd>`).join('')}</dl>`
                    }
                    if (checkedFields.TR.length > 0) {
                        finalHTML += `<span style="font-size:15px" class=""><b>Termination Information</b></span><dl class="">${checkedFields.TR.map(f => `<dt class="pull-left">${f.lab}:</dt><dd class="mb-1">&nbsp;${f.val}</dd>`).join('')}</dl>`
                    }
                    if (checkedFields.ST.length > 0) {
                        finalHTML += `<span style="font-size:15px" class=""><b>Step Up information</b></span><dl class="">${checkedFields.ST.map(f => `<dt class="pull-left">${f.lab}:</dt><dd class="mb-1">&nbsp;${f.val}</dd>`).join('')}</dl>`
                    }
                    if (checkedFields.RB.length > 0) {
                        finalHTML += `<span style="font-size:15px" class=""><b>Rider/Benefit Rules</b></span><dl class="">${checkedFields.RB.map(f => `<dt class="pull-left">${f.lab}:</dt><dd class="mb-1">&nbsp;${f.val}</dd>`).join('')}</dl>`
                    }
                    if (checkedFields.IN.length > 0 || checkedFields.DA.length > 0 || checkedFields.TR.length > 0 || checkedFields.ST.length > 0 || checkedFields.RB.length > 0) {
                        OcInfraModule.AppInjector.get(CommonService).coverageHtml = '<span style="font-size:15px" class="pad-lAr-10px">RIDERS</span><dl class="pad-lAr-10px">' + finalHTML + '</dl>';
                    } else {
                        OcInfraModule.AppInjector.get(CommonService).coverageHtml = '';
                    }
                    localStorage.setItem(field.alias, JSON.stringify(Object.assign(this.checkedProperties, store)));
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                } else {
                let finalHtml = '';
                if (keys.length > 0) {
                    for (let i = 0; i < this.selectdValues.length; i++) {
                        let subKeys = Object.keys(this.selectdValues[0][field.alias]);
                        this.coveragehtml += '';
                        for (var j = 0; j < subKeys.length; j++) {
                            Object.keys(this.selectdValues[0][field.alias][subKeys[j]]).forEach(key => {
                                currencyFields.includes(key) ? (this.selectdValues[0][field.alias][subKeys[j]][key].indexOf('$') >= 0 || this.selectdValues[0][field.alias][subKeys[j]][key] == "No data in admin system.") ? '' : this.selectdValues[0][field.alias][subKeys[j]][key] = '$' + this.selectdValues[0][field.alias][subKeys[j]][key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                                this.coveragehtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.selectdValues[0][field.alias][subKeys[j]][key] + '</dd>';
                            })
                            localStorage.setItem(field.alias, JSON.stringify(Object.assign(this.checkedProperties, this.selectdValues[0].selectedCoverage)));
                        }
                        finalHtml += this.coveragehtml
                    }
                    OcInfraModule.AppInjector.get(CommonService).coverageHtml = ' <span style="font-size:15px" class="pad-lAr-10px">RIDERS</span><dl class="pad-lAr-10px">' + finalHtml + '</dl>'
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                } else {
                    OcInfraModule.AppInjector.get(CommonService).coverageHtml = '';
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                    }
                }
                break;
            case 'selectedNote':
                let notesHtml = '';
                if (keys.length > 0) {
                    this.selectdValues = OcInfraModule.AppInjector.get(CommonService).selectedCoverages;
                    for (let i = 0; i < this.selectdValues.length; i++) {
                        var subKeys = Object.keys(this.selectdValues[0][field.alias]);
                        this.coveragehtml += '';
                        localStorage.setItem(field.alias, JSON.stringify(Object.assign(this.checkedProperties, this.selectdValues[0].selectedNote)));
                        for (var j = 0; j < subKeys.length; j++) {
                            this.coveragehtml += '';
                            Object.keys(this.selectdValues[0][field.alias][subKeys[j]]).forEach(key => {
                                currencyFields.includes(key) ? this.selectdValues[0][field.alias][subKeys[j]][key].indexOf('$') >= 0 ? '' : this.selectdValues[0][field.alias][subKeys[j]][key] = '$' + this.selectdValues[0][field.alias][subKeys[j]][key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                                this.coveragehtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.selectdValues[0][field.alias][subKeys[j]][key] + '</dd>';
                            })
                        }
                        notesHtml += this.coveragehtml
                    }
                    OcInfraModule.AppInjector.get(CommonService).notesHtml = '<span style="font-size:15px" class="pad-lAr-10px">NOTES</span><dl class="pad-lAr-10px">' + notesHtml + '</dl>'
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                } else {
                    OcInfraModule.AppInjector.get(CommonService).notesHtml = '';
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                }
                break;
        }
    }

    policyValues(field, message) {
        let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
        let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
        let response = this.resourceService.getResourceFromAliasName(field.alias);
        let effectivedate = response.data.quote_effective_date;
        this.checkedProperties = JSON.parse(localStorage.getItem('policyValues'));
        this.checkedProperties == null ? this.checkedProperties = {} : '';
        if (field.value) {
            if (message) {
                if (this.checkedProperties[effectivedate]) {
                    this.checkedProperties[effectivedate]['Message'] = OcInfraModule.AppInjector.get(CommonService).valuesMesseage;
                } else {
                    this.checkedProperties[effectivedate] = JSON.parse("{}");
                    this.checkedProperties[effectivedate]["Effective Date"] = effectivedate;
                    this.checkedProperties[effectivedate]['Message'] = OcInfraModule.AppInjector.get(CommonService).valuesMesseage ? OcInfraModule.AppInjector.get(CommonService).valuesMesseage : 'No data in admin system.';
                }
            } else {
                let checkedId = field.id.slice(0, -7);
                if (this.checkedProperties[effectivedate]) {
                    this.checkedProperties[effectivedate][selectedLang[field.label]] = response.data[checkedId] ? response.data[checkedId] : 'No data in admin system.';
                } else {
                    this.checkedProperties[effectivedate] = JSON.parse("{}");
                    this.checkedProperties[effectivedate]["Effective Date"] = effectivedate;
                    this.checkedProperties[effectivedate][selectedLang[field.label]] = response.data[checkedId] ? response.data[checkedId] : 'No data in admin system.';
                }
            }
        }
        else {
            let unCheckedId = message ? 'Message' : selectedLang[field.label];
            Object.keys(this.checkedProperties).map(keys => {
                if (effectivedate === keys) {
                    Object.keys(this.checkedProperties[keys]).map(key => {
                        if (unCheckedId === key) {
                            delete this.checkedProperties[keys][unCheckedId];
                            localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties))
                        }
                    })
                    if (Object.keys(this.checkedProperties[keys]).length < 2) {
                        delete this.checkedProperties[keys];
                        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties))

                    }
                }
            })
        }
        Object.keys(this.checkedProperties).forEach(keys => {
            Object.keys(this.checkedProperties[keys]).map(key => {
                currencyFields.includes(key) ? this.checkedProperties[keys][key].indexOf('$') >= 0 ? '' : this.checkedProperties[keys][key] = '$' + this.checkedProperties[keys][key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                this.html += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.checkedProperties[keys][key] + '</dd>'
            })
        })
        OcInfraModule.AppInjector.get(CommonService).quoteValuesHtml = _.isEmpty(this.checkedProperties) ? '' : '<span style="font-size:15px" class="pad-lAr-10px">POLICY VALUES</span><dl class="pad-lAr-10px">' + this.html + '</dl>';;
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties))

    }

    riderPremiumValueLabel(field) {
        let coverageType = document.getElementById("selected-coverage-coverage-name-label");
        if (coverageType != undefined && coverageType.innerText) {
            if (coverageType.innerText != 'PREMIUM WAIVER') {
                return true;
            }
            else {
                return false;
            }
        }
        return false;
    }

    totalRiderPremiumValue(field) {
        let coverageType = document.getElementById("selected-coverage-coverage-name-label");
        if (coverageType != undefined && coverageType.innerText) {
            if (coverageType.innerText == 'PREMIUM WAIVER') {
                return true;
            }
            else {
                return false;
            }
        }
        return false;
    }

    getCheckedValues(field) {

        let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
        let reinstatementQuoteObj = OcInfraModule.AppInjector.get(CommonService).selectedDateObj;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let response = resourceService.getResourceFromAliasName(field.alias);
        let aliasName: boolean = false;
        field.alias == 'policyValues' ? aliasName = true : aliasName = false;
        let selctedCoverage = []
        this.checkFormat(field);
        selctedCoverage = OcInfraModule.AppInjector.get(CommonService).selectedCoverages;
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == field.alias });
        header[0].headers.wma_user = window.sessionStorage.getItem('username');
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        let getResourceByHref = resourceService.getResourceByHref(field.href);
        if (field.id == "total_remit_due_action") {
            getResourceByHref['data']['total_remit_due'] = OcInfraModule.AppInjector.get(CommonService).total_remit_due;
            getResourceByHref['response']['total_remit_due'] = OcInfraModule.AppInjector.get(CommonService).total_remit_due;
        }
        let currentId = field.alias == 'selectedCoverage' ? "coverage_id" : "note_type_desc";
        let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
        if (field.value) {
            let checkedId;
            let coverageType = document.getElementById("selected-coverage-coverage-name-label");
            if (field.id == "rider_premium_action") {
                if (coverageType.innerText == 'PREMIUM WAIVER') {
                    checkedId = "total_annual_rider_premium";
                }
                else {
                    checkedId = field.id.slice(0, -7);
                }
            }
            else {
                checkedId = this.getCheckedId(field);
            }

            if (field.alias === "selectedCoverage" || field.alias === "selectedNote") {
                let coverageChecked = []
                this.currentChecked = {}
                getResourceByHref.response[checkedId] = getResourceByHref.response[checkedId] ? getResourceByHref.response[checkedId] : 'No data in admin system.';
                if (getResourceByHref.response[checkedId]) {
                    this.currentChecked[selectedLang[field.label]] = getResourceByHref.response[checkedId];
                    if (selctedCoverage.length > 0) {
                        if (field.alias == "selectedCoverage" ? selctedCoverage[0].hasOwnProperty([field.alias]) : selctedCoverage[0].hasOwnProperty([field.alias])) {
                            for (let i = 0; i < selctedCoverage.length; i++) {
                                let subKeys = Object.keys(selctedCoverage[0][field.alias]);
                                let existed: boolean = true;
                                if (subKeys.length > 0) {
                                    for (let j = 0; j < subKeys.length; j++) {
                                        if (subKeys[j] == getResourceByHref.response[currentId]) {
                                            Object.assign(selctedCoverage[0][field.alias][getResourceByHref.response[currentId]], this.currentChecked);
                                            OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
                                            existed = false;
                                            this.covrageChecked(field);
                                        } else if (j + 1 >= subKeys.length && existed == true) {
                                            this.checkedProperties[field.alias] = []
                                            this.checkedProperties[field.alias][getResourceByHref.response[currentId]] = this.currentChecked;
                                            Object.assign(selctedCoverage[0][field.alias], this.checkedProperties[field.alias]);
                                            OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
                                            this.covrageChecked(field);
                                        }
                                    }
                                } else {
                                    this.checkedProperties[field.alias] = []
                                    this.checkedProperties[field.alias][getResourceByHref.response[currentId]] = this.currentChecked;
                                    Object.assign(selctedCoverage[0][field.alias], this.checkedProperties[field.alias]);
                                    OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
                                    this.covrageChecked(field);
                                }
                            }
                        } else {
                            this.checkedProperties[field.alias] = []
                            field.alias == "selectedCoverage" ? this.checkedProperties[field.alias][getResourceByHref.response["coverage_id"]] = this.currentChecked : this.checkedProperties[field.alias][getResourceByHref.response["note_type_desc"]] = this.currentChecked;
                            Object.assign(selctedCoverage[0], this.checkedProperties)
                            OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
                            this.covrageChecked(field);
                        }
                    } else {
                        this.checkedProperties[field.alias] = []
                        field.alias == "selectedCoverage" ? this.checkedProperties[field.alias][getResourceByHref.response["coverage_id"]] = this.currentChecked : this.checkedProperties[field.alias][getResourceByHref.response["note_type_desc"]] = this.currentChecked;
                        selctedCoverage.push(this.checkedProperties);
                        OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
                        this.covrageChecked(field);
                    }
                }
            }
            else {
                this.savedProperties = localStorage.getItem(field.alias);
                if (this.savedProperties == null) {
                    localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                    this.savedProperties = localStorage.getItem(field.alias);
                }
                this.checkedProperties = JSON.parse(this.savedProperties)
                if (!aliasName) {
                    this.checkedProperties[selectedLang[field.label]] = response.data[checkedId] && response.data[checkedId] != " " ? response.data[checkedId] : 'No data in admin system.';
                    let val = JSON.stringify(this.checkedProperties);
                    Object.keys(this.checkedProperties).forEach(key => {
                        currencyFields.includes(key) ? (this.checkedProperties[key].indexOf('$') >= 0 || this.checkedProperties[key].includes("No data in admin system.")) ? '' : this.checkedProperties[key] = '$' + this.checkedProperties[key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                        this.html += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.checkedProperties[key] + '</dd>';

                    })
                }
                if (field.alias == "billingInfo") {
                    if (checkedId == 'total_remit_due') {
                        this.checkedProperties['Reinstatement Date'] = reinstatementQuoteObj['reinstatement_date'];
                        this.html += '<dt class="pull-left">Reinstatement Date:</dt><dd>&nbsp;' + reinstatementQuoteObj['reinstatement_date'] + '</dd>';
                    }
                    localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                    OcInfraModule.AppInjector.get(CommonService).billingHtml = '<span style="font-size:15px" class="pad-lAr-10px">BILLING</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                } else if (field.alias == "policyNFOInformation") {
                    localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                    OcInfraModule.AppInjector.get(CommonService).nfoHtml = '<span style="font-size:15px" class="pad-lAr-10px">NFO INFO</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                }
                else if (aliasName) {
                    this.policyValues(field, false);
                } else {
                    OcInfraModule.AppInjector.get(CommonService).detailsHtml = '<span style="font-size:15px" class="pad-lAr-10px">DETAILS</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                    localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                }
            }
        }
        else if (field.alias === "selectedCoverage" || field.alias === "selectedNote") {
            let unCheckedId = selectedLang[field.label]
            delete selctedCoverage[0][field.alias][getResourceByHref.response[currentId]][unCheckedId]
            let subKeys = Object.keys(selctedCoverage[0][field.alias]);
            let checked = [];
            for (let i = 0; i < subKeys.length; i++) {
                if (_.isEmpty(selctedCoverage[0][field.alias][subKeys[i]])) {
                    delete selctedCoverage[0][field.alias][getResourceByHref.response[currentId]]
                    checked.push(false)
                } else {
                    checked.push(true)
                }
            }
            localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
            OcInfraModule.AppInjector.get(CommonService).selectedCoverages = selctedCoverage;
            this.covrageChecked(field)
        }
        else {
            this.savedProperties = localStorage.getItem(field.alias)
            this.checkedProperties = JSON.parse(this.savedProperties)
            let unCheckedId = selectedLang[field.label];
            if (aliasName) {
                this.policyValues(field, false);
            }
            Object.keys(this.checkedProperties).map(key => {
                if (unCheckedId === key) {
                    delete this.checkedProperties[unCheckedId];
                    (key == "Total Reinstatement Remittance Due") ? delete this.checkedProperties['Reinstatement Date'] : '';
                    localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties))
                    let requestedDetailSection = document.getElementsByClassName("requested_details_section")[0];

                    Object.keys(this.checkedProperties).forEach(key => {
                        currencyFields.includes(key) ? this.checkedProperties[key].indexOf('$') >= 0 ? '' : this.checkedProperties[key] = '$' + this.checkedProperties[key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                        this.html += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.checkedProperties[key] + '</dd>'
                    })
                    if (field.alias == "billingInfo") {
                        if (!_.isEmpty(this.checkedProperties)) {
                            OcInfraModule.AppInjector.get(CommonService).billingHtml = '<span style="font-size:15px" class="pad-lAr-10px">BILLING</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
                            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                        }
                        else {
                            OcInfraModule.AppInjector.get(CommonService).billingHtml = '';
                            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                        }
                        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                    }

                    else if (field.alias == "policyNFOInformation") {
                        if (!_.isEmpty(this.checkedProperties)) {
                            OcInfraModule.AppInjector.get(CommonService).nfoHtml = '<span style="font-size:15px" class="pad-lAr-10px">NFO INFORMATION</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
                            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                        }
                        else {
                            OcInfraModule.AppInjector.get(CommonService).nfoHtml = '';
                            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                        }
                        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                    }

                    else {
                        if (!_.isEmpty(this.checkedProperties)) {
                            OcInfraModule.AppInjector.get(CommonService).detailsHtml = '<span style="font-size:15px" class="pad-lAr-10px">DETAILS</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
                            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                        } else {
                            OcInfraModule.AppInjector.get(CommonService).detailsHtml = '';
                            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                        }
                        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                    }
                }
            })
        }
    }

    getSuspenseCode(field) {
        let checkedSuspenseHistoryProperties = OcInfraModule.AppInjector.get(CommonService).checkedSuspenseHistory;
        let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
        let checkedId = field.href.substr(-4);
        let suspenseHtml = '';
        if (field.value) {
            this.savedProperties = localStorage.getItem(field.alias);
            if (this.savedProperties == null) {
                localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                this.savedProperties = localStorage.getItem(field.alias);
            }
            this.checkedProperties = JSON.parse(this.savedProperties)
            this.checkedProperties[checkedId] = []
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
            let getResourceByHref = resourceService.getResourceByHref(policyDetails._links.suspense.href);
            let selectedPolicy = _.filter(getResourceByHref.response._links.item, function (o) { return o.href == field.href });
            this.currentChecked[selectedLang._SUSPENSE_AMOUNT] = selectedPolicy[0].summary.suspense_amount;
            this.currentChecked[selectedLang._EFFECTIVE_DATE] = selectedPolicy[0].summary.suspense_effective_date;
            this.checkedProperties[checkedId] = this.currentChecked;
            _.assign(checkedSuspenseHistoryProperties, this.checkedProperties);
            let subKeys = Object.keys(checkedSuspenseHistoryProperties);
            for (var j = 0; j < subKeys.length; j++) {
                Object.keys(checkedSuspenseHistoryProperties[subKeys[j]]).forEach(key => {
                    key == selectedLang._SUSPENSE_AMOUNT ? checkedSuspenseHistoryProperties[subKeys[j]][key].indexOf('$') >= 0 ? '' : checkedSuspenseHistoryProperties[subKeys[j]][key] = '$' + checkedSuspenseHistoryProperties[subKeys[j]][key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                    suspenseHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + checkedSuspenseHistoryProperties[subKeys[j]][key] + '</dd>';
                })
            }
            this.html += suspenseHtml;
            OcInfraModule.AppInjector.get(CommonService).suspenseHtml = ' <span style="font-size:15px" class="pad-lAr-10px">SUSPENSE</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails()
            OcInfraModule.AppInjector.get(CommonService).checkedSuspenseHistory = checkedSuspenseHistoryProperties;
            localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
        } else {
            delete checkedSuspenseHistoryProperties[checkedId];
            _.assign(this.checkedProperties, checkedSuspenseHistoryProperties);
            localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
            let subKeys = Object.keys(checkedSuspenseHistoryProperties);
            for (var j = 0; j < subKeys.length; j++) {
                Object.keys(checkedSuspenseHistoryProperties[subKeys[j]]).forEach(key => {
                    if (key == selectedLang._SUSPENSE_AMOUNT) {
                        suspenseHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + OcInfraModule.AppInjector.get(CommonService).convertToCurrency(checkedSuspenseHistoryProperties[subKeys[j]][key]) + '</dd>';
                    }
                    else {
                        suspenseHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + checkedSuspenseHistoryProperties[subKeys[j]][key] + '</dd>';
                    }
                })
            }
            this.html += suspenseHtml;
            let finalHtml = '';
            Object.keys(checkedSuspenseHistoryProperties).length ? finalHtml = ' <span style="font-size:15px" class="pad-lAr-10px">SUSPENSE</span><dl class="pad-lAr-10px">' + this.html + '</dl>' : finalHtml = '';
            OcInfraModule.AppInjector.get(CommonService).suspenseHtml = finalHtml;
            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
            OcInfraModule.AppInjector.get(CommonService).checkedSuspenseHistory = checkedSuspenseHistoryProperties;
            localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
        }
    }

    
    getSponsorCode(field) {
        let checkedSponsorDetailsProperties = OcInfraModule.AppInjector.get(CommonService).checkedSponsorDetails;
        let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
        let checkedId = field.id.substr(0, field.id.length - 7);
        this.currentChecked = {};
        let sponsorHtml = '';

        let policySponser = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policySponsor');
        let isMrpsContract = OcInfraModule.AppInjector.get(CommonService).getNested(policySponser, 'response', 'is_mrps_contract');

        let mrpsFields = ['coll_loans_allowed_action', 'tpa_loans_allowed_action', 'acc_redctn_loans_allowed_action', 'pre78_loans_allowed_action'];

        if (field.value) {
            this.savedProperties = localStorage.getItem(field.alias);
            if (this.savedProperties == null) {
                localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                this.savedProperties = localStorage.getItem(field.alias);
            }
            this.checkedProperties = JSON.parse(this.savedProperties)
            this.checkedProperties[checkedId] = []
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
            let getResourceByHref = resourceService.getResourceByHref(policyDetails._links.sponsor.href);
            if(getResourceByHref.response._options.properties[checkedId].oneOf){
                getResourceByHref.response._options.properties[checkedId].oneOf.filter(e => {
                    if(e.enum[0] == getResourceByHref.response[checkedId]){
                        this.currentChecked[field.label] = e.title;
                    }
                    if(getResourceByHref.response[checkedId] == ''){
                        this.currentChecked[field.label] = '';
                    }
                });
            }else if(getResourceByHref.response._options.properties[checkedId].type == "boolean"){
                this.currentChecked[field.label] = getResourceByHref.response[checkedId] == true ? 'Yes':'No';
            }else{
                if(field.id == "triggering_evnt_eff_date_action" && !getResourceByHref.response[checkedId]){
                    this.currentChecked[field.label] = " ";
                }else{
                    this.currentChecked[field.label] = getResourceByHref.response[checkedId];
                }
            }
            if(field.id == "street_action"){
                this.currentChecked[field.label] = getResourceByHref.response.street + " " + getResourceByHref.response.street2 + " " + getResourceByHref.response.street3 +" " + getResourceByHref.response.street4+ " " + getResourceByHref.response.city + " " + getResourceByHref.response.state +" " + getResourceByHref.response.zipcode;
            }

            if(isMrpsContract && mrpsFields.includes(field.id)){
                this.currentChecked[field.label] = '';
            }

            this.checkedProperties[checkedId] = this.currentChecked;
            _.assign(checkedSponsorDetailsProperties, this.checkedProperties);
            let subKeys = Object.keys(checkedSponsorDetailsProperties);
            for (var j = 0; j < subKeys.length; j++) {
                Object.keys(checkedSponsorDetailsProperties[subKeys[j]]).forEach(key => {
                    sponsorHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + checkedSponsorDetailsProperties[subKeys[j]][key] + '</dd>';
                })
            }
            this.html += sponsorHtml;
            OcInfraModule.AppInjector.get(CommonService).sponsorHtml = ' <span style="font-size:15px" class="pad-lAr-10px">SPONSOR</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails()
            OcInfraModule.AppInjector.get(CommonService).checkedSponsorDetails = checkedSponsorDetailsProperties;
            localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
        } else {
            delete checkedSponsorDetailsProperties[checkedId];
            _.assign(this.checkedProperties, checkedSponsorDetailsProperties);
            localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
            let subKeys = Object.keys(checkedSponsorDetailsProperties);
            for (var j = 0; j < subKeys.length; j++) {
                Object.keys(checkedSponsorDetailsProperties[subKeys[j]]).forEach(key => {
                    if (key == selectedLang._SUSPENSE_AMOUNT) {
                        sponsorHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + OcInfraModule.AppInjector.get(CommonService).convertToCurrency(checkedSponsorDetailsProperties[subKeys[j]][key]) + '</dd>';
                    }
                    else {
                        sponsorHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + checkedSponsorDetailsProperties[subKeys[j]][key] + '</dd>';
                    }
                })
            }
            this.html += sponsorHtml;
            let finalHtml = '';
            Object.keys(checkedSponsorDetailsProperties).length ? finalHtml = ' <span style="font-size:15px" class="pad-lAr-10px">SPONSOR</span><dl class="pad-lAr-10px">' + this.html + '</dl>' : finalHtml = '';
            OcInfraModule.AppInjector.get(CommonService).sponsorHtml = finalHtml;
            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();            
            OcInfraModule.AppInjector.get(CommonService).checkedSponsorDetails = checkedSponsorDetailsProperties;
            localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
        }
    }

    getCheckedGroupValues(field) {
        let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
        let groupValuesHtml = '';
        let plancodeHtml = '';
        let checkedId = field.id.substr(0, field.id.length - 7);
        let checkedValuesObj = OcInfraModule.AppInjector.get(CommonService).checkedValuesObject;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let getResourceByAlias = resourceService.getResourceFromAliasName(field.alias);
        let selectedDrpdwnObj =  OcInfraModule.AppInjector.get(CommonService).selectedGroupDropdownValues;
        let selectedPlanCode;
        let selectedAssetAccount;
        let effectiveDate;
        if (selectedDrpdwnObj['VALUES']) {
            selectedPlanCode = selectedDrpdwnObj['VALUES']['planCode'];
            selectedAssetAccount = selectedDrpdwnObj['VALUES']['accountType'];
            effectiveDate = selectedDrpdwnObj['VALUES']['effectiveDate'];
        }
        let keyString = selectedPlanCode + '_' + selectedAssetAccount + '_' + effectiveDate;
        if(field.value) {
            let quoteValuesObj = {};
            if (selectedLang[field.label] == 'Message') {
                quoteValuesObj[selectedLang[field.label]] = OcInfraModule.AppInjector.get(CommonService).valuesMesseage ? OcInfraModule.AppInjector.get(CommonService).valuesMesseage : 'No data in admin system.';
            } else {
                if (getResourceByAlias.response && getResourceByAlias.response[checkedId] && getResourceByAlias.response[checkedId] != " ") {
                    quoteValuesObj[selectedLang[field.label]] = '$' +  getResourceByAlias.response[checkedId].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
                } else {
                    quoteValuesObj[selectedLang[field.label]] = 'No data in admin system.';
                }
            }
            if (_.isEmpty(checkedValuesObj)) {
                checkedValuesObj['VALUES'] = {};
                checkedValuesObj['VALUES'][keyString] = quoteValuesObj;
            } else {
                if (checkedValuesObj['VALUES'].hasOwnProperty(keyString)){
                    checkedValuesObj['VALUES'][keyString] = {...checkedValuesObj['VALUES'][keyString], ...quoteValuesObj};
                } else {
                    let newPlanCodeObj = {};
                    newPlanCodeObj[keyString] = quoteValuesObj; 
                    checkedValuesObj['VALUES'] = {...checkedValuesObj['VALUES'], ...newPlanCodeObj};
                }
            }
        } else {
            if (Object.keys(checkedValuesObj['VALUES']).length) {
                if(checkedValuesObj['VALUES'][keyString] && Object.keys(checkedValuesObj['VALUES'][keyString]).length > 1) {
                    delete checkedValuesObj['VALUES'][keyString][selectedLang[field.label]];
                } else {
                    delete checkedValuesObj['VALUES'][keyString];
                    (Object.keys(checkedValuesObj['VALUES']).length) ? null : delete checkedValuesObj['VALUES'];
                }
            }
        }
        OcInfraModule.AppInjector.get(CommonService).checkedValuesObject = checkedValuesObj;
        if (checkedValuesObj['VALUES'] && Object.keys(checkedValuesObj['VALUES']).length) {
            let subKeysList = [];
            Object.keys(checkedValuesObj['VALUES']).forEach (key => {
                plancodeHtml +='<dt class="pull-left"> Product:</dt><dd>&nbsp;' + key.split('_')[0] + '</dd>' + '<dt class="pull-left"> Asset Account:</dt><dd>&nbsp;' + key.split('_')[1] + '</dd>' + '<dt class="pull-left"> Effective Date:</dt><dd>&nbsp;' + key.split('_')[2] + '</dd>';
                Object.keys(checkedValuesObj['VALUES'][key]).forEach (subKey => {
                    if (subKeysList.includes(key)) {
                        groupValuesHtml += '<dl class="pad-lAr-10px"><dt class="pull-left">' + subKey + ':</dt><dd>&nbsp;' + checkedValuesObj['VALUES'][key][subKey] + '</dd></dl>';
                    } else {
                        groupValuesHtml = '';
                        groupValuesHtml += '<dl class="pad-lAr-10px"><dt class="pull-left">' + subKey + ':</dt><dd>&nbsp;' + checkedValuesObj['VALUES'][key][subKey] + '</dd></dl>';
                        subKeysList.push(key);
                    }   
                })
                plancodeHtml += groupValuesHtml;
            })
        }
        this.html = plancodeHtml;
        let finalHtml = '';
        Object.keys(checkedValuesObj).length ? finalHtml = ' <span style="font-size:15px" class="pad-lAr-10px">POLICY VALUES</span><dl class="pad-lAr-10px">' + this.html + '</dl>' : finalHtml = '';
        OcInfraModule.AppInjector.get(CommonService).groupValuesHtml = finalHtml;
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();  
        localStorage.setItem(field.alias, JSON.stringify(checkedValuesObj['VALUES']));
    }

    getRevolvingError(field) {
        let checkedRevolvingErrorProperties = OcInfraModule.AppInjector.get(CommonService).checkedRevolvingError;
        let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
        let checkedId = field.href.substr(-4);
        let revolvingHtml = '';
        if (field.value) {
            this.savedProperties = localStorage.getItem(field.alias);
            if (this.savedProperties == null) {
                localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
                this.savedProperties = localStorage.getItem(field.alias);
            }
            this.checkedProperties = JSON.parse(this.savedProperties)
            this.checkedProperties[checkedId] = []
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
            let getResourceByHref = resourceService.getResourceByHref(policyDetails._links.revolvingError.href);
            let selectedPolicy = _.filter(getResourceByHref.response._links.item, function (o) { return o.href == field.href });
            this.currentChecked[selectedLang._TYPE] = selectedPolicy[0].summary.revolving_error_type_desc;
            this.checkedProperties[checkedId] = this.currentChecked;
            _.assign(checkedRevolvingErrorProperties, this.checkedProperties);
            let subKeys = Object.keys(checkedRevolvingErrorProperties);
            for (var j = 0; j < subKeys.length; j++) {
                Object.keys(checkedRevolvingErrorProperties[subKeys[j]]).forEach(key => {
                    revolvingHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + checkedRevolvingErrorProperties[subKeys[j]][key] + '</dd>';
                })
            }
            this.html += revolvingHtml;
            OcInfraModule.AppInjector.get(CommonService).revolvingHtml = ' <span style="font-size:15px" class="pad-lAr-10px">Revolving</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
            localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
            OcInfraModule.AppInjector.get(CommonService).checkedRevolvingError = checkedRevolvingErrorProperties;
        } else {
            delete checkedRevolvingErrorProperties[checkedId];
            _.assign(this.checkedProperties, checkedRevolvingErrorProperties);
            localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
            let subKeys = Object.keys(checkedRevolvingErrorProperties);
            for (var j = 0; j < subKeys.length; j++) {
                Object.keys(checkedRevolvingErrorProperties[subKeys[j]]).forEach(key => {
                    revolvingHtml += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + checkedRevolvingErrorProperties[subKeys[j]][key] + '</dd>';
                })
            }
            this.html += revolvingHtml;
            let finalHtml = '';
            Object.keys(checkedRevolvingErrorProperties).length ? finalHtml = ' <span style="font-size:15px" class="pad-lAr-10px">Revolving</span><dl class="pad-lAr-10px">' + this.html + '</dl>' : finalHtml = '';
            OcInfraModule.AppInjector.get(CommonService).revolvingHtml = finalHtml;
            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
            OcInfraModule.AppInjector.get(CommonService).checkedRevolvingError = checkedRevolvingErrorProperties;
        }
    }
    checkFormat(field) {
        let check = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
        let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
        let checkedId = this.getCheckedId(field);
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let getResourceByHref = resourceService.getResourceByHref(field.href);
        if (field.value) {
            // In case of Annuties, the value is pulled from billing api instead of policyDetails api
            if (field.id == "total_premium_paid_action" && this.isAnnuity()) {
                check.push(selectedLang[field.label]);
                OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields = check;
            }
            if (getResourceByHref && getResourceByHref._options.properties[checkedId] && getResourceByHref._options.properties[checkedId].format && getResourceByHref._options.properties[checkedId].format == 'currency') {
                check.push(selectedLang[field.label]);
                OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields = check;
            } else if(field.alias=="Hcontracts"){
                check.push(selectedLang[field.label]);
                OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields = [];
            }
        }
        else {
            let removed = _.remove(check, function (n) {
                return n != selectedLang[field.label]
            });
            OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields = removed;
        }

    }
    checkedBillingValue(field) {
        this.checkFormat(field);
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
        let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
        if (field.value) {
            let response;
            // In case of Annuties, the value is pulled from billing api instead of policyDetails api
            if (field.id == "total_premium_paid_action" && this.isAnnuity()) {
                response = resourceService.getResourceFromAliasName('billingInfo');
            } else {
                response = resourceService.getResourceFromAliasName(field.alias);
            }
            this.savedProperties = localStorage.getItem('billingInfo');
            if (this.savedProperties == null) {
                localStorage.setItem('billingInfo', JSON.stringify(this.checkedProperties));
                this.savedProperties = localStorage.getItem('billingInfo');
            }
            let checkedId = this.getCheckedId(field);
            this.checkedProperties = JSON.parse(this.savedProperties)
            if (response.data[checkedId]) {
                this.checkedProperties[selectedLang[field.label]] = response.data[checkedId];
                let val = JSON.stringify(this.checkedProperties);
                Object.keys(this.checkedProperties).forEach(key => {
                    currencyFields.includes(key) ? this.checkedProperties[key].indexOf('$') >= 0 ? '' : this.checkedProperties[key] = '$' + this.checkedProperties[key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                    this.html += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.checkedProperties[key] + '</dd>';
                })
                localStorage.setItem('billingInfo', JSON.stringify(this.checkedProperties));
                OcInfraModule.AppInjector.get(CommonService).billingHtml = '<span style="font-size:15px" class="pad-lAr-10px">BILLING</span><dl class="pad-lAr-10px">' + this.html + '</dl>'
                OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
            }
        } else {
            this.savedProperties = localStorage.getItem('billingInfo')
            this.checkedProperties = JSON.parse(this.savedProperties)
            let unCheckedId = selectedLang[field.label];
            Object.keys(this.checkedProperties).map(key => {
                let html = '';
                delete this.checkedProperties[unCheckedId];
                localStorage.setItem('billingInfo', JSON.stringify(this.checkedProperties))
                Object.keys(this.checkedProperties).forEach(key => {
                    currencyFields.includes(key) ? this.checkedProperties[key].indexOf('$') >= 0 ? '' : this.checkedProperties[key] = '$' + this.checkedProperties[key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                    html += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.checkedProperties[key] + '</dd>';
                })
                if (!_.isEmpty(this.checkedProperties)) {
                    OcInfraModule.AppInjector.get(CommonService).billingHtml = '<span style="font-size:15px" class="pad-lAr-10px">BILLING</span><dl class="pad-lAr-10px">' + html + '</dl>'
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                }
                else {
                    OcInfraModule.AppInjector.get(CommonService).billingHtml = '';
                    OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
                }
                localStorage.setItem('billingInfo', JSON.stringify(this.checkedProperties));
            })
        }
    }

    getCheckedId(field) {
        let checkedId;
        if(field.id == "total_annual_mode_premium_action") {
            checkedId = this.isAnnuity() ? "anticipated_annual_premium" : field.id.slice(0, -7);
        } 
        else if (field.id == "total_premium_paid_action") {
            checkedId = this.isAnnuity() ? "initial_deposit_premium" : field.id.slice(0, -7);
        } else {
            checkedId = field.id.slice(0, -7);
        }
        return checkedId;
    }

    loanValues(field, message) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedLang = OcInfraModule.AppInjector.get(CommonService).selectedLanguage;
        let currencyFields = OcInfraModule.AppInjector.get(CommonService).selectdCurrencyFields;
        let response = resourceService.getResourceFromAliasName(message ? 'loanValues' : field.alias);
        this.savedProperties = localStorage.getItem('loanInfo');
        if (this.savedProperties == null) {
            localStorage.setItem('loanInfo', JSON.stringify(this.checkedProperties));
            this.savedProperties = localStorage.getItem('loanInfo');
        }
        let objID = response.data.quote_effective_date + 'v';
        let effectivedate = response.data.quote_effective_date;
        if (field.value) {
            let checkedId = message ? 'Message' : field.id.slice(0, -7);
            this.checkedProperties = JSON.parse(this.savedProperties)
            let fieldData = response.data[checkedId] ? response.data[checkedId] : 'No data in admin system.' ;

            if(  response.data[checkedId] &&  ["loan_interest_rate", "coll_interest_rate"].includes(checkedId)){
                fieldData = fieldData+"%"
            }
            if (this.checkedProperties[objID]) {
                message ? this.checkedProperties[objID][checkedId] = OcInfraModule.AppInjector.get(CommonService).loanMesseage ? OcInfraModule.AppInjector.get(CommonService).loanMesseage : 'No data in admin system.' : this.checkedProperties[objID][selectedLang[field.label]] = fieldData;
            } else {
                this.checkedProperties[objID] = JSON.parse("{}");
                this.checkedProperties[objID]["Effective Date"] = effectivedate;
                message ? this.checkedProperties[objID][checkedId] = OcInfraModule.AppInjector.get(CommonService).loanMesseage ? OcInfraModule.AppInjector.get(CommonService).loanMesseage : 'No data in admin system.' : this.checkedProperties[objID][selectedLang[field.label]] = fieldData;
            }
        }
        else {
            this.checkedProperties = JSON.parse(this.savedProperties)
            let unCheckedId = message ? 'Message' : selectedLang[field.label];
            Object.keys(this.checkedProperties).map(keys => {
                if (objID === keys) {
                    Object.keys(this.checkedProperties[keys]).map(key => {
                        if (unCheckedId === key) {
                            delete this.checkedProperties[keys][unCheckedId];
                        }
                    })
                }
                if (Object.keys(this.checkedProperties[keys]).length < 2) {
                    delete this.checkedProperties[keys];
                }
            })
        }
        let html = '';
        Object.keys(this.checkedProperties).forEach(keys => {
            Object.keys(this.checkedProperties[keys]).forEach(key => {
                currencyFields.includes(key) ? this.checkedProperties[keys][key].indexOf('$') >= 0 ? '' : this.checkedProperties[keys][key] = '$' + this.checkedProperties[keys][key].replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : '';
                html += '<dt class="pull-left">' + key + ':</dt><dd>&nbsp;' + this.checkedProperties[keys][key] + '</dd>'
            })
        })
        localStorage.setItem('loanInfo', JSON.stringify(this.checkedProperties));
        _.isEmpty(this.checkedProperties) ? OcInfraModule.AppInjector.get(CommonService).loanHtml = '' : OcInfraModule.AppInjector.get(CommonService).loanHtml = html;
        OcInfraModule.AppInjector.get(CommonService).showLoanFinalDetails();
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    }

    checkedLoanValues(field) {
        this.checkFormat(field);
        this.loanValues(field, false);
    }

    openClaimDetails(params) {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
       // OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        let headers = { remote_user: OcInfraModule.AppInjector.get(CommonService).incontactId }
        headers["region_code"] = OcInfraModule.AppInjector.get(CommonService).productInfo.REGION;
        OcInfraModule.AppInjector.get(CommonService).selectedClaimUrl = params
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(headers);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(CommonService).optionCallback(policyDetails._links.claims.href, claimListHeader[0].headers).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).deathClaimProperties = response._options.properties;
            OcInfraModule.AppInjector.get(CommonService).deathClaimRequiredProperties = response._options.required;
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            OcInfraModule.AppInjector.get(CommonService).getCallback(params.href, claimListHeader[0].headers).subscribe(response => {
                OcInfraModule.AppInjector.get(CommonService).claimDetails = response;
                if (response._embedded) {
                    if (response._embedded.status_report.messages[0].severity == 'INFO') {
                        OcInfraModule.AppInjector.get(AdvGrowlService).createTimedWarningMessage(response._embedded.status_report.messages[0].message, '', 0);
                    } else {
                        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response._embedded.status_report.messages[0].message, '', 0);
                    }
                } else {
                    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                    document.getElementById("claims-open-claim-items-anchor").click();
                    OcInfraModule.AppInjector.get(CommonService).showConfirmation('Add comments via the AWD Claim Transaction', 'OK', '', "Alert");
                }
            },
                error => {
                    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                    OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong please try again", 'Error', 0);
                });
        },
            error => {
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong please try again", 'Error', 0);
            });
    }

    openPaymentDetails(params) {
        let headers = { remote_user: OcInfraModule.AppInjector.get(CommonService).incontactId }
        headers["region_code"] = OcInfraModule.AppInjector.get(CommonService).productInfo.REGION;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(headers);
        OcInfraModule.AppInjector.get(APICallerService).get(params.href).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).eobDetails = response;
            resourceService.updateCreateAliasName('selectedPayment', params.href, true);
            document.getElementById("payments-open-payment-items-anchor").click();
        });
    }

    updateAddress(field){
        if(field.value){
            let resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policySponsor');           
            field.viewValue  =  resource.response.street + " " + resource.response.street2 + " " + resource.response.street3 +" " + resource.response.street4+ " " + resource.response.city + " " + resource.response.state +" " + resource.response.zipcode;
        }
        return true
    }

    setLabel(field){
          if(field.value == true){
            field.viewValue  = 'Yes';
          }else{
            field.viewValue = 'No';
          }
       
       return true;    
    }

    setLabelMRPS(field) {
        let policySponser = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policySponsor');
        let isMrpsContract = OcInfraModule.AppInjector.get(CommonService).getNested(policySponser, 'response', 'is_mrps_contract');
        if (isMrpsContract) {
            field.viewValue = '';
            field.value = '';
        } else {
            if (field.value == true) {
                field.viewValue = 'Yes';
            } else {
                field.viewValue = 'No';
            }
        }
        return true;
    }
    
    highlightRow(searchResults) {
        const router = OcInfraModule.AppInjector.get(CommonService).routerService()
        if (searchResults && searchResults.length && router.url === "/screen/partySearch") {
            let i = 0;
            searchResults.forEach(element => {
                i++;
                if (!element.summary.clientId && element.summary.msg && element.summary.msg != '') {
                    let ele = document.getElementById("_" + (i - 1));
                    ele ? ele.style.backgroundColor = "#FCE4E6" : '';
                    ele ? ele.style.color = "#EF655F" : '';
                    ele ? ele.style.border = "solid" : "";
                }
                else {
                    let ele = document.getElementById("_" + (i - 1));
                    ele ? ele.style.backgroundColor = "" : "";
                    ele ? ele.style.color = "" : "";
                    ele ? ele.style.border = "" : "";
                }
            })
        }
    }

    searchBarHandler(params) {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
        OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
        OcInfraModule.AppInjector.get(CommonService).searchResults = {};
        OcInfraModule.AppInjector.get(CommonService).changeHeaders({ statuaory_company_code: null, remote_user: null, role: null, incontact_user: null });
        const self = this;
        const urlIndex = _.findLastIndex(Configuration.config.hostURL.multiHostUrl, ['alias', "searchContracts"]);
        const optionParams: OptionParams = OcInfraModule.AppInjector.get(ResourceSchemaService).getPayload(params.field.alias);
        OcInfraModule.AppInjector.get(ResourceSchemaService).resetPayload();
        optionParams.alias = params.field.actionURL;
        // Checking searchValue
        if(optionParams.params[0]) {
            var searchValue = optionParams.params[0].value.trim();
            // Setting searchValue in local storage
            localStorage.setItem('searchValue', searchValue);
        } else {
            if(!!localStorage.getItem('searchValue')) {
                searchValue = localStorage.getItem('searchValue');
                optionParams.params = [{
                    key: "query",
                    value: localStorage.getItem('searchValue')
                }]
            } else {
                searchValue = false;
            }
        }
        OcInfraModule.AppInjector.get(CommonService).searchContractsValue = optionParams.params[0] ? optionParams.params[0].value.trim() : "";
        const router = OcInfraModule.AppInjector.get(CommonService).routerService()
        const warnMessage = "CURRENT SEARCH RESULTS ARE LIMITED TO 50. PLEASE ASK FOR ADDITIONAL CLIENT SPECIFIC INFORMATION REQUIRED TO NARROW SEARCH RESULTS"
        if (!searchValue) {
            OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Please enter search criteria", 'Error', 0);
            return false;
        }
        this.searchResults = [];
        this.res = {};
        let url: any = Configuration.config.hostURL.multiHostUrl[urlIndex]['url'] + params.field.actionURL;
        optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("search");
        OcInfraModule.AppInjector.get(CommonService).changeHeaders({ statuaory_company_code: null });
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true })
        OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams, "RESET").subscribe(function (response) {
            this.searchResults = response._links.item;
            OcInfraModule.AppInjector.get(CommonService).searchResults = response;
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            if (this.searchResults && this.searchResults.length === 1) {
                OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
                OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                self.highlightRow(this.searchResults);
                if (!this.searchResults[0].summary.clientId && this.searchResults[0].summary.msg && this.searchResults[0].summary.msg != "") {
                    document.getElementById("contracts-collection-search-text") ? document.getElementById("contracts-collection-search-text")["value"] = OcInfraModule.AppInjector.get(CommonService).searchContractsValue : '';
                    let alertMsg = [];
                    let popUpMsg = this.searchResults[0].summary.msg.split(".");
                    alertMsg.push(this.searchResults[0].summary.msg.substring(this.searchResults[0].summary.msg.indexOf("."), 0) + '.', popUpMsg[1].substring(popUpMsg[1].indexOf(":"), 0) + ':');
                    alertMsg.push(this.searchResults[0].summary.contactLabel1 ? this.searchResults[0].summary.contactLabel1 + ": " + this.searchResults[0].summary.contactNumber1 : '', this.searchResults[0].summary.contactLabel2 ? this.searchResults[0].summary.contactLabel2 + ": " + this.searchResults[0].summary.contactNumber2 : '', this.searchResults[0].summary.contactLabel3 ? this.searchResults[0].summary.contactLabel3 + ": " + this.searchResults[0].summary.contactNumber3 : '');
                    let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation({ content: alertMsg, custom: true }, 'CLOSE', '', 'ALERT', '393px');
                    dialogRef.afterClosed().subscribe(res => {
                        res;
                    });
                } else {
                    self.openPolicyDetails(this.searchResults[0])
                }
            } else {
                OcInfraModule.AppInjector.get(NavigationService).navigateTo('/screen/partySearch');
                let table = document.querySelector(".policy-search-result table tbody");
                let observer = new MutationObserver(function (mutation) {
                    if (router.url === "/screen/partySearch") {
                        let sResults = OcInfraModule.AppInjector.get(CommonService).searchResults;
                        self.highlightRowText(OcInfraModule.AppInjector.get(CommonService).searchContractsValue);
                        self.highlightRow(sResults._links.item)
                        if (!(sResults && sResults.response._links.item && sResults.response._links.item.length === 1)) {
                            if (this.searchResults && this.searchResultsresponse._links.item.length >= 50 && sResults._embedded && sResults._embedded.status_report) {
                                this.res._embedded.status_report.url = this.res.resourceURL;
                                OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
                                OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(sResults.responce._embedded.status_report);
                            } else {
                                OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
                                sResults && sResults._embedded && sResults._embedded.status_report && sResults._embedded.status_report.messages && sResults._embedded.status_report.messages.length ? OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(sResults._embedded.status_report) : OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
                                OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                            }
                        }
                    }
                });
                if (table) {
                    observer.observe(table, { attributes: true, childList: true, attributeOldValue: true });
                } else {
                    var highLightInterval = setInterval(function () {
                        let sResults = OcInfraModule.AppInjector.get(CommonService).searchResults;
                        self.highlightRowText(OcInfraModule.AppInjector.get(CommonService).searchContractsValue);
                        self.highlightRow(sResults._links.item);
                        if (!(this.searchResults && this.searchResults.length === 1)) {
                            if (this.searchResults && this.searchResults.length >= 50 && this.res._embedded && this.res._embedded.status_report) {
                                this.res._embedded.status_report.url = this.res.resourceURL;
                                router.url === "/screen/partySearch" ? OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [] : '';
                                OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(this.res._embedded.status_report);
                            } else {
                                router.url === "/screen/partySearch" ? OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [] : '';
                                sResults._embedded && sResults._embedded.status_report && sResults._embedded && sResults._embedded.status_report && sResults._embedded.status_report.messages && sResults._embedded.status_report.messages.length ? OcInfraModule.AppInjector.get(CommonService).pushBannerAlertMessage(sResults._embedded.status_report) : OcInfraModule.AppInjector.get(CommonService).bannerAlertArray = [];
                                OcInfraModule.AppInjector.get(CommonService).showHideBannerAlert.emit();
                            }
                        }
                        let table = document.querySelector(".policy-search-result table tbody");
                        if (table) {
                            observer.observe(table, { attributes: true, childList: true, attributeOldValue: true });
                        }
                        if (document.querySelector(".policy-search-result table")) {
                            clearInterval(highLightInterval);
                        }
                    }, 500);
                }
            }
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
        });
    }

    highlightRowText(searchValue) {
        const searchedColumnArray = document.getElementsByClassName('oc-label-2');
        document.getElementById("contracts-collection-search-text") ? document.getElementById("contracts-collection-search-text")["value"] = OcInfraModule.AppInjector.get(CommonService).searchContractsValue : '';
        const test = searchValue.split(" ").join("|");
        for (var i = 0; i < searchedColumnArray.length; i++) {
            if (searchedColumnArray[i]) {
                let query = searchValue.startsWith('*') ? new RegExp("(\\b" + "[a-zA-Z0-9]*" + test.slice(1) + "\\b)", "gim") : (searchValue.endsWith("*") || searchValue.endsWith("?") ? new RegExp("(\\b" + test.slice(0, -1) + "[a-zA-Z0-9]*\\b)", "gim") : new RegExp("(\\b" + test + "\\b)", "gim"));
                var elemInnerText = searchedColumnArray[i]["innerText"];
                if (!!~searchValue.indexOf('?')) {
                    const reg = searchValue.replace(/\?/g, '.');
                    const results = elemInnerText.match(new RegExp(reg, 'gim'));
                    if (results && results.length) {
                        query = new RegExp("(\\b" + results[0] + "\\b)", "gim")
                    }
                }
                if (searchedColumnArray[i]["innerText"].indexOf("-") !== -1 && searchValue.replace(/-/g, '') === searchedColumnArray[i]["innerText"].replace(/-/g, '')) {
                    var newe = elemInnerText.replace(elemInnerText, "<span style='color : #0275d8;'>" + elemInnerText + "</span>");
                } else {
                    if (searchValue.split(" ").length > 1 && !~searchValue.indexOf('?') && !~searchValue.indexOf('*')) {
                        const arr = searchValue.split(" ").sort((a, b) => b.length - a.length);
                        var newe = elemInnerText;
                        for (var j = 0; j < arr.length; j++) {
                            const sQuery = new RegExp("(\\b" + arr[j] + "\\b)", "gim");
                            newe = newe.replace(sQuery, "<span style='color : #0275d8;'>$1</span>");
                        }
                    } else {
                        var newe = elemInnerText.replace(query, "<span style='color : #0275d8;'>$1</span>");
                    }
                }
                searchedColumnArray[i].innerHTML = newe;
            };
        }
        document.getElementById("contracts-collection-search-text") ? document.getElementById("contracts-collection-search-text")["value"] = OcInfraModule.AppInjector.get(CommonService).searchContractsValue : '';
    }

    createDeathClaim(params) {
        const payload = {};
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const workRequestHref = resourceService.getHrefByAliasName("createClaim");
        const wizardSteps = params.modal.renderer.data.sections[0].properties[0].wizardSteps
        wizardSteps.forEach(step => {
            step.renderer.data.sections[0].properties.forEach(propertiesObj => {
                if (propertiesObj.value) {
                    payload[propertiesObj.id] = propertiesObj.value
                }
            });
        });
        let headers = { remote_user: 'JZACHF' }
        let url = 'http://20.15.66.42:8083/bpo/oc/contracts/PPLK102VL9008473/claims'
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(headers);
        OcInfraModule.AppInjector.get(APICallerService).post(url, payload).subscribe(response => {
            document.getElementById("modal__cross").click();
        });
    }

    openWorkRequestDetails(params) {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const optionParams: Object = {
            'alias': "selectedWorkRequest",
            'headers': OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedWorkRequest")
        }
        OcInfraModule.AppInjector.get(APICallerService).refresh(params.href, optionParams, 'RESET').subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            resourceService.updateCreateAliasName('selectedWorkRequest', params.href, true);
            document.getElementById("work-request-history-open-work-request-details-anchor").click();
            OcInfraModule.AppInjector.get(CommonService).selectedSourceForFax = [];
            OcInfraModule.AppInjector.get(CommonService).attachDocWorkReq = false;
        });
    }

    openAgentWorkRequestDetails(params) {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const optionParams: Object = {
            'alias': "selectedWorkRequest",
            'headers': OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedWorkRequest")
        }
        OcInfraModule.AppInjector.get(APICallerService).refresh(params.href, optionParams, 'RESET').subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            OcInfraModule.AppInjector.get(CommonService).commentsUrl = response && response._links && response._links.comments && response._links.comments.href;
            resourceService.updateCreateAliasName('selectedWorkRequest', params.href, true);
            document.getElementById("work-request-history-open-work-request-details-anchor").click();
            OcInfraModule.AppInjector.get(CommonService).selectedSourceForFax = [];
        });
    }

    openCustomerWorkRequestDetails(params) {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const optionParams: Object = {
            'alias': "selectedWorkRequest",
            'headers': OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedWorkRequest")
        }
        OcInfraModule.AppInjector.get(APICallerService).refresh(params.href, optionParams, 'RESET').subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
            resourceService.updateCreateAliasName('selectedWorkRequest', params.href, true);
            OcInfraModule.AppInjector.get(CommonService).commentsUrl = response && response._links && response._links.comments && response._links.comments.href;
            document.getElementById("work-request-history-open-work-request-details-anchor").click();
            OcInfraModule.AppInjector.get(CommonService).selectedSourceForFax = [];
        });
    }

    openCallHistoryDetails(params) {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
        OcInfraModule.AppInjector.get(CommonService).paramDetails = params;
        document.getElementById("policy-call-history-open-call-history-details-anchor").click();
    }

    openMyCallHistoryDetails(params) {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
        OcInfraModule.AppInjector.get(CommonService).paramDetails = params;
        document.getElementById("call-history-open-my-call-history-details-anchor").click();
    }

    SuspenseMesseage() {
        let policySuspenseDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policySuspense');
        if (policySuspenseDetails) {
            return policySuspenseDetails.response._links.item && !policySuspenseDetails.response._links.item.length;
        }
    }

    getTitle() {
        let title = OcInfraModule.AppInjector.get(CommonService).selectedWorkItemValue;
        return title;
    }

    disableCreateWorkRequestButton(params) {
        let preFillIndicator = OcInfraModule.AppInjector.get(CommonService).preFillIndicator;
        let agentRoleDetails = OcInfraModule.AppInjector.get(CommonService).agentRoleDetails;
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let currentPage = OcInfraModule.AppInjector.get(CommonService).currentRoute;
        let selectedAgentSummary = OcInfraModule.AppInjector.get(CommonService).selectedAgentSummary;
        let selectedCustomerSummary = OcInfraModule.AppInjector.get(CommonService).customerDetails;
        let emailValidator: Boolean = false;
        let statusChange: Boolean = false;
        let phoneFieldChange: Boolean = false;
        
        if (params.modal.modal.componentInstance.renderer && params.modal.modal.componentInstance.renderer.data && params.modal.modal.componentInstance.renderer.data.sections && params.modal.modal.componentInstance.renderer.data.sections[0] && !preFillIndicator) {
            if (currentPage == '/screen/agentDetails') {
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[11].value == '' ? params.modal.modal.componentInstance.renderer.data.sections[0].properties[11].value = "USA" : '';
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[6].value = agentRoleDetails && agentRoleDetails.response && agentRoleDetails.response._links.item.length && agentRoleDetails.response._links.item[0].summary.agentFirmName ? agentRoleDetails.response._links.item[0].summary.agentFirmName : '';
                if (selectedAgentSummary.email_address && params.modal.modal.componentInstance.renderer.data.sections[0].properties[1].value == '') {
                    params.modal.modal.componentInstance.renderer.data.sections[0].properties[1].value = selectedAgentSummary.email_address.trim();
                }
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[16].value == '' ? params.modal.modal.componentInstance.renderer.data.sections[0].properties[16].value = document.getElementById('close-call-notes-textarea_')["value"] + document.getElementById('close-call-phone-number-text')["value"] : null;
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[11].value == undefined ? null : OcInfraModule.AppInjector.get(CommonService).preFillIndicator = true;
            } else {
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[9].value == '' ? params.modal.modal.componentInstance.renderer.data.sections[0].properties[9].value = "USA" : '';
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[14].value == '' ? params.modal.modal.componentInstance.renderer.data.sections[0].properties[14].value = document.getElementById('close-call-notes-textarea_')["value"] + document.getElementById('close-call-phone-number-text')["value"] : null;
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[3].value == '' ? params.modal.modal.componentInstance.renderer.data.sections[0].properties[3].value = OcInfraModule.AppInjector.get(CommonService).policyNumber : null;
                if (selectedCustomerSummary.summary && selectedCustomerSummary.summary.email_address && params.modal.modal.componentInstance.renderer.data.sections[0].properties[1].value == '') {
                    params.modal.modal.componentInstance.renderer.data.sections[0].properties[1].value = selectedCustomerSummary.summary.email_address.trim();
                }
                params.modal.modal.componentInstance.renderer.data.sections[0].properties[9].value == undefined ? null : OcInfraModule.AppInjector.get(CommonService).preFillIndicator = true;
            }
        }
        if ((OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess || OcInfraModule.AppInjector.get(CommonService).authsucess) && document.getElementById('selected-roles-nipr-text') && !document.getElementById('selected-roles-nipr-text')['value']) {
            document.getElementById('selected-roles-nipr-text')["value"] = agentRoleDetails.response._links.item[0].summary.agentNIPR;
        }
        document.querySelector(".createWorkItemModal .okButtonModal");
        if (!(OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess)) {
            document.querySelector(".createWorkItemModal .okButtonModal")['title'] = 'Caller not authorized';
            return true
        } else if (params.modal.modal.componentInstance.renderer && params.modal.modal.componentInstance.renderer.data && params.modal.modal.componentInstance.renderer.data.sections && params.modal.modal.componentInstance.renderer.data.sections[0]) {
            let workItem = OcInfraModule.AppInjector.get(CommonService).selectedWorkItem;
            if (params.modal.modal.componentInstance.renderer.resourceSchemaService.schemaMap[workItem].required.length) {
                let modalProperties = params.modal.modal.componentInstance.renderer.data.sections[0].properties;
                let required: any = [];
                modalProperties.forEach(propertiesObj => {
                    if (propertiesObj.id == "phoneCallRequested") {
                        propertiesObj.value == true ? statusChange = true : statusChange = false;
                    }
                    if (propertiesObj.id == "callerPhoneNumber") {
                        let number = document.getElementById("workitems-schema-phone-text") ? document.getElementById("workitems-schema-phone-text")["value"] : '';
                        propertiesObj.value == '' ? phoneFieldChange = true : number && number.length >= 12 ? phoneFieldChange = false : phoneFieldChange = true;
                    }
                    if (propertiesObj.required == true) {
                        if (propertiesObj.value == '') {
                            propertiesObj.id == "description" ? document.getElementById('workitems-schema-description-of-issue-textarea_')['value'] == '' ? required.push(true) : required.push(false) : required.push(true);
                        } else {
                            let emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                            propertiesObj.id == "email" ? propertiesObj.value.match(emailFormat) ? required.push(false) : required.push(true) : required.push(false);
                            propertiesObj.id == "email" ? emailValidator = propertiesObj.value.match(emailFormat) ? true : false : '';
                        }
                    }
                })
                if (required.includes(true)) {
                    if (required.filter(value => { return value === true; }).length == 1) {
                        document.querySelector(".createWorkItemModal .okButtonModal")['title'] = emailValidator ? 'Enable button by completing required items' : "Invalid Email Address Format";
                    } else {
                        document.querySelector(".createWorkItemModal .okButtonModal")['title'] = 'Enable button by completing required items'
                    }
                    return true;
                } else {
                    if (statusChange) {
                        if (phoneFieldChange) {
                            document.querySelector(".createWorkItemModal .okButtonModal")['title'] = 'Enable by Entering Phone Number'
                            return true;
                        }
                        else {
                            document.querySelector(".createWorkItemModal .okButtonModal")['title'] = '';
                            return false;
                        }
                    }
                    else {
                        document.querySelector(".createWorkItemModal .okButtonModal")['title'] = '';
                        return false;
                    }
                };
            } else {
                document.querySelector(".createWorkItemModal .okButtonModal")['title'] = '';
                return false
            }
        }
    }

    returnCallRequested(params) {
        if (params.value) {
            document.getElementById('workitems-schema-description-of-issue-textarea_')["value"] = document.getElementById('workitems-schema-description-of-issue-textarea_')["value"] == '' ? 'Return Phone Call Requested' : document.getElementById('workitems-schema-description-of-issue-textarea_')["value"] = document.getElementById('workitems-schema-description-of-issue-textarea_')["value"] + ' Return Phone Call Requested';
        } else {
            document.getElementById('workitems-schema-description-of-issue-textarea_')["value"] = document.getElementById('workitems-schema-description-of-issue-textarea_')["value"].replace("Return Phone Call Requested", "");
        }
    }

    selectedCategory(params) {
        document.getElementById('workitems-schema-summary-of-issue-text')["value"] = params.viewValue;
    }

    addressOption(params) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
        let authSucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
        let fullName = selectedRoleSummary.data.fullName ? selectedRoleSummary.data.fullName : selectedRoleSummary.data.ownerFullName ? selectedRoleSummary.data.ownerFullName : selectedRoleSummary.data.annuitantFullName ? selectedRoleSummary.data.annuitantFullName : selectedRoleSummary.data.beneficiaryName ? selectedRoleSummary.data.beneficiaryName : selectedRoleSummary.data.jointOwnerFullName ? selectedRoleSummary.data.jointOwnerFullName : selectedRoleSummary.data.agentFullName ? selectedRoleSummary.data.agentFullName : selectedRoleSummary.data.coOwnerFullName ? selectedRoleSummary.data.coOwnerFullName : "";
        if (authSucess) {
            if (selectedRoleSummary.data.role == 'Payor') {
                document.getElementById('workitems-schema-comments-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.payorFullName + ', Address Change Request for: ' + params.value;
            } else {
                document.getElementById('workitems-schema-comments-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + fullName + ', Address Change Request for: ' + params.value;
            }
        } else {
            let name = document.getElementById("selected-roles-name-text")["value"];
            let number = document.getElementById("selected-roles-phone-number-text")["value"];
            document.getElementById('workitems-schema-comments-textarea_')["value"] = 'Role: ' + OcInfraModule.AppInjector.get(CommonService).otherRole + ', Full Name: ' + name + ', Phone Number: ' + number + ', Address Change Request for: ' + params.value;
        }
    }

    disableProcessButton(params) {
        let workItem = OcInfraModule.AppInjector.get(CommonService).selectedWorkItem;
        if (params.modal.modal.componentInstance.renderer.resourceSchemaService.schemaMap[workItem].data.processed) {
            let modalProperties = params.modal.modal.componentInstance.renderer.data.sections[0].properties;
                let required: any = [];
                let minlength: any = [];
                this.zipExists = false;
                modalProperties.forEach(propertiesObj => {
                    if (propertiesObj.required == true) {
                        if (propertiesObj.value == '') {
                            required.push(true);
                        } else {
                            required.push(false);
                        }
                    }
                    if (propertiesObj.minLength && propertiesObj.value && propertiesObj.value != '') {
                        if (propertiesObj.value.trim().length < propertiesObj.minLength) {
                            minlength.push(true);
                        } else {
                            minlength.push(false);
                        }
                    }
                    if (propertiesObj.label == 'Postal Code') {
                        this.zipExists = true;
                    }
                })
                let postalCodeLength = OcInfraModule.AppInjector.get(CommonService).postalCodeLength;
                if (required.includes(true) || minlength.includes(true) || (this.zipExists && postalCodeLength)) {
                    document.querySelector("#modal__custom")['title'] = 'Enable button by completing required items';
                } else {
                    document.querySelector("#modal__custom")['title'] = '';
                };
        } else {
            document.querySelector("#modal__custom")['title'] = 'Option not applicable.';
            document.querySelector("#modal__custom")['disabled'] = true;
        }
    }

    disableCreateWorkItem(params) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
        let fullName = selectedRoleSummary.data.fullName ? selectedRoleSummary.data.fullName : selectedRoleSummary.data.ownerFullName ? selectedRoleSummary.data.ownerFullName : selectedRoleSummary.data.annuitantFullName ? selectedRoleSummary.data.annuitantFullName : selectedRoleSummary.data.beneficiaryName ? selectedRoleSummary.data.beneficiaryName : selectedRoleSummary.data.jointOwnerFullName ? selectedRoleSummary.data.jointOwnerFullName : selectedRoleSummary.data.agentFullName ? selectedRoleSummary.data.agentFullName : selectedRoleSummary.data.coOwnerFullName ? selectedRoleSummary.data.coOwnerFullName : "";
        if (document.getElementById('workitems-schema-comments-textarea_') && !document.getElementById('workitems-schema-comments-textarea_')['value']) {
            if (selectedRoleSummary.data.role != undefined) {
                if (selectedRoleSummary.data.role == 'Payor') {
                    document.getElementById('workitems-schema-comments-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + selectedRoleSummary.data.payorFullName;
                } else {
                    document.getElementById('workitems-schema-comments-textarea_')["value"] = 'Role: ' + selectedRoleSummary.data.role + ', Full Name: ' + fullName;
                }
            }
            else {
                setTimeout(function () {
                    let name = document.getElementById("selected-roles-name-text")["value"];
                    let number = document.getElementById("selected-roles-phone-number-text")["value"];
                    if (document.getElementById("selected-roles-other-role-name-text")) {
                        document.getElementById('workitems-schema-comments-textarea_')["value"] = 'Role: ' + document.getElementById("selected-roles-other-role-name-text")["value"] + ', Full Name: ' + name + ', Phone Number: ' + number;
                    }
                    else {
                        document.getElementById('workitems-schema-comments-textarea_')["value"] = 'Role: ' + OcInfraModule.AppInjector.get(CommonService).otherRole + ', Full Name: ' + name + ', Phone Number: ' + number;
                    }
                }, 1000);

            }
        }

        document.querySelector(".createWorkItemModal .okButtonModal");
        if (!(OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess)) {
            document.querySelector(".createWorkItemModal .okButtonModal")['title'] = 'Caller not authorized';
            return true
        } else if (params.modal.modal.componentInstance.renderer && params.modal.modal.componentInstance.renderer.data && params.modal.modal.componentInstance.renderer.data.sections && params.modal.modal.componentInstance.renderer.data.sections[0]) {
            this.disableProcessButton(params);
            let workItem = OcInfraModule.AppInjector.get(CommonService).selectedWorkItem;
            if (params.modal.modal.componentInstance.renderer.resourceSchemaService.schemaMap[workItem].required.length) {
                let modalProperties = params.modal.modal.componentInstance.renderer.data.sections[0].properties;
                let required: any = [];
                let minlength: any = [];
                this.zipExists = false;
                modalProperties.forEach(propertiesObj => {
                    if (propertiesObj.required == true) {
                        if (propertiesObj.value == '') {
                            required.push(true);
                        } else {
                            required.push(false);
                        }
                    }
                    if (propertiesObj.minLength && propertiesObj.value && propertiesObj.value != '') {
                        if (propertiesObj.value.trim().length < propertiesObj.minLength) {
                            minlength.push(true);
                        } else {
                            minlength.push(false);
                        }
                    }
                    if (propertiesObj.label == 'Postal Code') {
                        this.zipExists = true;
                    }
                })
                let postalCodeLength = OcInfraModule.AppInjector.get(CommonService).postalCodeLength;
                if (required.includes(true) || minlength.includes(true) || (this.zipExists && postalCodeLength)) {
                    document.querySelector(".createWorkItemModal .okButtonModal")['title'] = 'Enable button by completing required items';
                    return true;
                } else {
                    document.querySelector(".createWorkItemModal .okButtonModal")['title'] = '';
                    return false;
                };
            } else {
                document.querySelector(".createWorkItemModal .okButtonModal")['title'] = '';
                return false
            }
        }
    }

    isPolicyAvailable() {
        if (OcInfraModule.AppInjector.get(CommonService).getAllUrlOfPolicyDetails()) {
            return true;
        }else{
            return false;
        }
    }
    isAnnuity() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details.data.product_type.includes('A')) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    //FOR PANOCSR-1660 adding this code to avoid loading of policyloanstab json for annuities
    isNotAnnuity(){
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details.data.product_type.includes('A')) {
                return false;
            }
            else {
                return true;
            }
        }
    }

    isAnnuityorUL(){
        return (this.isAnnuity() || this.isUL()) ? true : false;
    }

    isNotRetailAnnuity() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            let product_type = details.data.product_type;
            if (product_type == "A1" || product_type == "A2") {
                return false;
            }
            else {
                return true;
            }
        } else return false;
    }

    otherThanU1ofUL() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            let product_type = details.data.product_type;
            if ((this.isUL() && product_type.toUpperCase() != "U1" &&  (!details.data.restriction_code.includes('Y') && !details.data.restriction_code.includes('Z')))) {
                return true;
            }
            return false;
        }
    }

    isRetailAnnuity() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            let product_type = details.data.product_type;
            if (product_type == "A1" || product_type == "A2")  {
                return true;
            }
            else {
                return false;
            }
        } else return false
    }

    isCV15Annuity() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            let product_type = details.data.product_type;
            let cv15 = details.data.isCv15Contract;
            if (product_type == "A1" || product_type == "A2")  {
                if(cv15) {
                return true;
                }
            }
            else {
                return false;
            }
        } else return false
    }

    isRetailNotCV15Annuity() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            let product_type = details.data.product_type;
            let cv15 = details.data.isCv15Contract;
            if (product_type == "A1" || product_type == "A2")  {
                if(cv15) {
                    return false;
                } else{
                    return true
                }
            }
            else {
                return false;
            }
        } else return false
    }

    isOnlyGroupAnn() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            let product_type = details.data.product_type;
            if (product_type == "A3" || product_type == "A4") {
                return true;
            }
            else {
                return false;
            }
        } else return false;
    }

    exceptGroupAnn_AllOthers() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            let product_type = details.data.product_type;
            if (product_type == "A3" || product_type == "A4") {
                return false;
            }
            else {
                return true;
            }
        } else return false;
    }    

    isOnlyTermProduct() {
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'policyCoverage' });
        header[0].headers.wma_user = window.sessionStorage.getItem('username');
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        let policyRiderDetails = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyCoverage');
        if (policyRiderDetails && policyRiderDetails.response._links.item && policyRiderDetails.response._links.item.length) {
            return policyRiderDetails.response._links.item[0].summary.coverage_type == 'RTERM' ? true : false;
        }
    }

    isRestrictCodeYorZ() {
        let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
        return details && details.data && details.data.restriction_code && 
                (details.data.restriction_code.includes('Y') || details.data.restriction_code.includes('Z')) ? true : false;
    }

    isNotTermProductAndRestrictCodeYorZ() {
        if (this.isPolicyAvailable()) {
            let isNotTermProduct = !this.isOnlyTermProduct();
            let isNotisRestrictCodeYorZ = !this.isRestrictCodeYorZ();
            if (isNotTermProduct && isNotisRestrictCodeYorZ) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    isTermProductORRestrictCodeYorZ() {
        if (this.isPolicyAvailable()) {
            let isTermProduct = this.isOnlyTermProduct();
            let isRestrictCodeYorZ = this.isRestrictCodeYorZ();
            if (isTermProduct || ( isRestrictCodeYorZ && this.isNotUL())) {
                return true;
            }
            else {
                return false;
            }
        }
    }


    isTermAndIul() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details.data.product_type.includes('U') || details.data.product_type.includes('T')) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    isTermProduct() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details.data.product_type.includes('T')) {
                return true;
            }
            return false;
        }
    }

    isTermOrU1() {
        if (this.isPolicyAvailable()) {
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
            if (this.isTermProduct() || (policyDetails.response.product_type == 'U1')) {
                return true;
            }
            return false;
        }
    }

    isU1N2restricted(){
        return this.isRestricted("U1") || this.isRestricted("U2");
    }

    isU2restricted(){
        return this.isRestricted("U2");
    }

    isRestricted(productType){
        if (this.isPolicyAvailable()) {
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
            if (policyDetails.response.product_type == productType && 
                (policyDetails.data.restriction_code.includes('Y') || policyDetails.data.restriction_code.includes('Z'))) {
                return true;
            }
            return false;
        }
    }

    isUL() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details.data.product_type.includes('U')) {
                return true;
            }
            else {
                return false;
            }                 
        }else{
            return false; 
        }
    }

    isU3() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details.data.product_type == 'U3') {
                return true;
            }
            else {
                return false;
            }                 
        }else{
            return false; 
        }
    }

    isULNotU3() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details.data.product_type.includes('U') && details.data.product_type != 'U3') {
                return true;
            }
            else {
                return false;
            }                 
        }else{
            return false; 
        }
    }

    isMRPSContract() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details && details.data && details.data.isMrpsContract) {
                return true;
            }
            else {
                return false;
            }                 
        }else{
            return false; 
        }
    }

    checkULType(productType) {
        return this.isPolicyAvailable()&& OcInfraModule.AppInjector.get(ResourceService)
            .getResourceFromAliasName('policyDetails').data.product_type
                .includes(productType);
    }

    isNotUL(){
        if(this.isUL()){
            return false;
        }
        return true;
    }

    isULAndretailAnn() {
        if (this.isUL() || this.isRetailAnnuity()) {
            return true;
        }
        else {
            return false;
        }
    }

    isTermAndRestrictCodesYAndZ() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if ((details.data.product_type.includes('T') || details.data.product_type.includes('U')) && (details.data.restriction_code.includes('Y') || details.data.restriction_code.includes('Z'))) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    isNotT1(){
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details.data.product_type_desc === 'T1'){
                return false;
            }
            else {
                return true;
            }
        }

    }


    isNotTermAndRestrictCodesYAndZ() {
        return !this.isTermAndRestrictCodesYAndZ();
    }

    isNotTermAndRestrictCodesYAndZAndNotAnnuity() {
        return !this.isAnnuity() && !this.isTermAndRestrictCodesYAndZ();
    }

    isTermButNotSupressed() {
        let isNotTermAndRestrictCodesYAndZ = !this.isTermAndRestrictCodesYAndZ();
        let isTermAndIul = this.isTermAndIul();
        if (isNotTermAndRestrictCodesYAndZ && isTermAndIul) {
            return true;
        } else {
            return false;
        }
    }

    isOlsAnnuity() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details.data.product_type.includes('A')) {
                return true;
            }
            else {
                return false;
            }
        }
        if (OcInfraModule.AppInjector.get(CommonService).isOlsAnnuity) {
            return true;
        }
        else {
            return false;
        }
    }

    isOlsIul() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details.data.product_type.includes('U')) {
                return true;
            }
            else {
                return false;
            }
        }
        if (OcInfraModule.AppInjector.get(CommonService).isOlsIul) {
            return true;
        }
        else {
            return false;
        }
    }
    // Hides the tab if policy is A3 or A4
    showTraditionalAndAnnutiesNonGroup() {
        if (this.isPolicyAvailable()) {
            let selectedPolicyInfo = OcInfraModule.AppInjector.get(CommonService).productInfo;
            if (this.isAnnuity()) {
                if (selectedPolicyInfo && (selectedPolicyInfo.product_type == 'A1' || selectedPolicyInfo.product_type == 'A2')) {
                    return true;
                }
                else return false;
            } else return true;
        }
    }

    openWorkRequestSourceDoc(params) {
        const selectedWorkRequestHeaders = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("selectedWorkRequestSource");
        OcInfraModule.AppInjector.get(CommonService).openSourceDocument(params.href, selectedWorkRequestHeaders);
    }

    disableAutoComplete() {
        if (document.getElementById("contracts-collection-search-text")) {
            document.getElementById("contracts-collection-search-text").setAttribute("autocomplete", "off");
        }
        return true;
    }

    showWorkRequestfilter(params) {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let workRequestDetails = resourceService.getResourceFromAliasName('workRequestHistory');
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.workRequestHistory ? workRequestDetails : workRequestDetails;
    }

    openPartyDetails(params) {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
        document.getElementById("policy-call-history-open-party-details-anchor").click();
        OcInfraModule.AppInjector.get(CommonService).personURL = params.href;
        OcInfraModule.AppInjector.get(CommonService).selectedPartyInfoRole = params.summary.role_name;
    }

    rBNameVisible(params) {

        params.properties.forEach(key => {
            if (key.type === 'label' && key.href !== undefined) {
                let href = key.href;
                let details = OcInfraModule.AppInjector.get(ResourceService).getResourceByHref(href);
                let code = details.data.coverage_id.slice(0, 1);
                if (code !== undefined && code === 'E') {
                    key.value = 'Not Applicable';
                    key.viewValue = 'Not Applicable';
                }
                else {
                    //rbNameIndicator = true;
                }
            }
            if (key.type === 'checkbox' && key.href !== undefined) {
                let href = key.href;
                let details = OcInfraModule.AppInjector.get(ResourceService).getResourceByHref(href);
                let code = details.data.coverage_id.slice(0, 1);
                if (code !== undefined && code === 'E') {
                    key.editable = false;
                }
            }
        });
        return true;
    }
    rBTypeVisible(params) {
        params.properties.forEach(key => {
            if (key.type === 'label' && key.href !== undefined) {
                let href = key.href;
                let details = OcInfraModule.AppInjector.get(ResourceService).getResourceByHref(href);
                let code = details.data.coverage_id.slice(0, 1);
                if (code !== undefined && code === 'E') {
                    //rbTypeIndicator = false;
                    key.value = 'Not Applicable';
                    key.viewValue = 'Not Applicable';
                }
                else {
                    //rbTypeIndicator = true;
                }
            }

            if (key.type === 'checkbox' && key.href !== undefined) {
                let href = key.href;
                let details = OcInfraModule.AppInjector.get(ResourceService).getResourceByHref(href);
                let code = details.data.coverage_id.slice(0, 1);
                if (code !== undefined && code === 'E') {
                    key.editable = false;
                }

            }
        });
        return true;
    }
    dbVisible(params) {
        var indicator;
        params.properties.forEach(key => {
            if (key.type === 'label' && key.href !== undefined) {
                let href = key.href;
                let details = OcInfraModule.AppInjector.get(ResourceService).getResourceByHref(href);
                let code = details.data.coverage_id.slice(0, 1);
                if (code !== undefined && code === 'D') {
                    indicator = true;
                }
                else if (code !== undefined && code === 'E') {
                    key.value = 'Not Applicable';
                    key.viewValue = 'Not Applicable';
                    indicator = true;
                }
                else {
                    indicator = false;
                }
            }
            if (key.type === 'checkbox' && key.href !== undefined) {
                let href = key.href;
                let details = OcInfraModule.AppInjector.get(ResourceService).getResourceByHref(href);
                let code = details.data.coverage_id.slice(0, 1);
                if (code !== undefined && code === 'E') {
                    key.editable = false;
                }

            }
        });
        return indicator;
    }

    resetBillingOnClose() {
        OcInfraModule.AppInjector.get(CommonService).billingMethod = false;
    }

    resetNotesOnClose() {
        OcInfraModule.AppInjector.get(CommonService).notesTypeSelected = false;
    }

    disableUpdateBilling() {
        let billingMethod = OcInfraModule.AppInjector.get(CommonService).billingMethod;
        let paymentDueDate = document.getElementById("billing-info-payment-due-date-date_input") ? document.getElementById("billing-info-payment-due-date-date_input")["value"] : undefined;
        let effectiveDate = document.getElementById("billing-info-effective-date-date_input") ? document.getElementById("billing-info-effective-date-date_input")["value"] : undefined;
        let billingAmount = document.getElementById("billing-info-modal-premium-text") ? document.getElementById("billing-info-modal-premium-text")["value"] : undefined;
        if (billingMethod && paymentDueDate && effectiveDate) {
            document.querySelector(".updateBilling .okButtonModal")['title'] = '';
            return false;
        } else {
            document.querySelector(".updateBilling .okButtonModal")['title'] = 'Enable by completing required items';
            return true;
        }
    }

    addNotes(params){
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory");
        const url = resourceService.getHrefByAliasName("policyNotes");
        let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
        let selectElement = document.querySelector('#policy-notes-note-type-select').textContent;
        let notesResponse = resourceService.getResourceFromAliasName('policyNotes');
        let filternotesTypee = notesResponse._options.properties.note_type.oneOf.find(item =>(item.title.includes(selectElement)));
        let noteType = filternotesTypee? filternotesTypee.enum[0]:'';
        let payload = {
            "message": document.getElementById("note-note-input-textarea_")["value"],
            "note_type": noteType
        }
        header[0].headers.role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
        OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
        header[0].headers['user_role'] = OcInfraModule.AppInjector.get(CommonService).userRole;
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        if(this.isOnlyGroupAnn()){
            header[0].headers['product_type'] = policyDetails.data.product_type;
        }
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
        OcInfraModule.AppInjector.get(CommonService).postCallback(url, payload, header[0].headers).subscribe(response => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            OcInfraModule.AppInjector.get(CommonService).billing = false;
            let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
            let param= [];
            param.push({
                "key": "_start",
                "value": "0"
            },
            {
                "key": "_num",
                "value": "100"
            });
            //let [url, params] = this.commonService.getHrefUrlWithParams(policyDetails._links["notes"].href);
            let optionParams = new OptionParams();
            optionParams.alias = "policyNotes";
            optionParams.headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("policyNotes");
            optionParams.headers['product_type']= policyDetails.data.product_type;
            optionParams.params = param;
            OcInfraModule.AppInjector.get(APICallerService).refresh(url, optionParams).subscribe(refreshResponse => { 
               OcInfraModule.AppInjector.get(CommonService).updateRefreshResponse(refreshResponse);
            });
            OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': header[0].headers }).subscribe(refreshResponse => {
            OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
            OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
            });
            OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Notes added successfully', 'Success');
            let notesAddHtml = '<dl class="pad-lAr-10px">' + '<dt class="pull-left">Notes added successfully</dt><dd><br></dd>' + '</dl>';
            OcInfraModule.AppInjector.get(CommonService).notesAdditionHtml = "Notes added successfully";
            OcInfraModule.AppInjector.get(CommonService).notesAddHtml = notesAddHtml;
            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
            params.modal.dialogRef.close();
        }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: true });
            OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': header[0].headers }).subscribe(refreshResponse => {
            OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
            OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
        console.log(error);
         });
        });
    }

    disableAddNotesSubmit() {
         let notesType = OcInfraModule.AppInjector.get(CommonService).notesTypeSelected;
         let notes = document.getElementById("note-note-input-textarea_") ? document.getElementById("note-note-input-textarea_")["value"] :undefined; 
         if(notesType && notes) {
            //document.querySelector(".addNotes .okButtonModal")['title'] = '';
            return false;
         } else {
            //document.querySelector(".addNotes .okButtonModal")['title'] = 'Enable by completing required items';
            return true;
         }
    }

    selectdNotesTypeMethod() {
        OcInfraModule.AppInjector.get(CommonService).notesTypeSelected = true;
    }



    selectdBillingMethod(params) {
        params.value == 'I' ? OcInfraModule.AppInjector.get(CommonService).billingMethod = true : OcInfraModule.AppInjector.get(CommonService).billingMethod = false;

    }

    updateBilling(params) {
        OcInfraModule.AppInjector.get(AdvGrowlService).clearMessages();
        let msg = 'Changing the Billing Method from PAC to another method will permanently remove the existing PAC Banking Information. Do you want to proceed?';
        let dialogRef = OcInfraModule.AppInjector.get(CommonService).showConfirmation(msg, 'CANCEL', 'YES')
        dialogRef.afterClosed().subscribe(res => {
            const resourceService = OcInfraModule.AppInjector.get(ResourceService);
            const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory");
            let header = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
            if (res) {
                const authResponse = resourceService.getResourceFromAliasName('authRes');
                const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
                const modalProperties = params.modal.renderer.data.sections[0].properties
                let payloadData = {};
                let payload = {
                    "CLTY": "MANEFT",
                    "hostKey": selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
                    "sessionId": authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
                    "callStart": authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
                    "type": "Billing Change Request",
                    "clientId": selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
                    "userId": OcInfraModule.AppInjector.get(CommonService).incontactId,
                    "policyNumber": selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : '',
                    "bill_option": null,
                    "modal_premium": null,
                    "payment_due_date": null,
                    "billing_frequency": null,
                    "effective_date": null,
                    'company_code': header[0].headers.company_code,
                    'region_code': header[0].headers.region_code,
                    'remote_user': header[0].headers.remote_user,
                }
                modalProperties.forEach(propertiesObj => {
                    if (propertiesObj.value) {
                        if (propertiesObj.id == 'total_premium_paid' || propertiesObj.id == 'total_annual_mode_premium') {
                            payloadData[propertiesObj.id] = propertiesObj.value;
                        }
                        else {
                            payload[propertiesObj.id] = propertiesObj.value;
                        }
                    }
                });

                payload.effective_date = OcInfraModule.AppInjector.get(CommonService).formatDate(payload.effective_date);
                payload.payment_due_date = OcInfraModule.AppInjector.get(CommonService).formatDate(payload.payment_due_date);
                const url = OcInfraModule.AppInjector.get(CommonService).billingPatchHref;
                header[0].headers.role = OcInfraModule.AppInjector.get(CommonService).selectedRole;
                OcInfraModule.AppInjector.get(APICallerService).changeHeaders(header[0].headers);
                OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true })
                OcInfraModule.AppInjector.get(APICallerService).patch(url, payload).subscribe(response => {
                    OcInfraModule.AppInjector.get(CommonService).billing = false;
                    OcInfraModule.AppInjector.get(APICallerService).refresh(url, { 'alias': "billingInfo", 'headers': header[0].headers }).subscribe(refreshResponse => { });
                    OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': header[0].headers }).subscribe(refreshResponse => {
                        OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
                        OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
                    });
                    OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage('Work Item Successful: EFT Request', 'Success');
                    params.modal.dialogRef.close();
                },
                    error => {
                        OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': header[0].headers }).subscribe(refreshResponse => {
                            OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
                            OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
                        });
                    }
                )
            }
        });
    }

    billingUpdate() {
        return OcInfraModule.AppInjector.get(CommonService).editBillingFields;
    }

    checkedannuityLoanMesseage(field) {
        if (field.value) {
            let loanMsg = {}
            loanMsg['annuity'] = {};
            loanMsg['annuity']['Message'] = field.label;
            localStorage.setItem('loanInfo', JSON.stringify(loanMsg));
            this.html = '<dt class="pull-left">Loan :</dt>' + '<span style="padding:5px;">' + field.label + '</span>';
            OcInfraModule.AppInjector.get(CommonService).loanHtml = this.html;
        } else {
            localStorage.removeItem('loanInfo');
            OcInfraModule.AppInjector.get(CommonService).loanHtml = '';
        }
        OcInfraModule.AppInjector.get(CommonService).showLoanFinalDetails();
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    }

    checkedSponsorMesseage(field) {
        if (field.value) {
            let loanMsg = {}
            loanMsg['annuity'] = {};
            loanMsg['annuity']['Message'] = field.label;
            localStorage.setItem('policySponsor', JSON.stringify(loanMsg));
            this.html = '<dt class="pull-left">Sponsor :</dt>' + '<span style="padding:5px;">' + field.label + '</span>';
            OcInfraModule.AppInjector.get(CommonService).sponsorHtml = this.html;
        } else {
            localStorage.removeItem('policySponsor');
            OcInfraModule.AppInjector.get(CommonService).sponsorHtml = '';
        }
        OcInfraModule.AppInjector.get(CommonService).showSponsorFinalDetails();
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    }

    IfmailingAddress2() {
        let mailingAddressLine2
        if (mailingAddressLine2 == "") {
            return false;
        }
    }

    checkedValuesMessage(field) {
        let html = '';
        if (field.value) {
            let valuesMsg = {}
            valuesMsg['annuity'] = {};
            valuesMsg['annuity']['Message'] = field.label;
            localStorage.setItem(field.alias, JSON.stringify(valuesMsg));
            html = '<span style="font-size:15px" class="pad-lAr-10px">POLICY VALUES</span><dl class="pad-lAr-10px" style="padding-left: 10px"><dt class="pull-left">' + 'PolicyValues' + ':</dt><dd style="font-weight: 200;">&nbsp;' + field.label + '</dd></dl>';
            OcInfraModule.AppInjector.get(CommonService).quoteValuesHtml = html;
        } else {
            localStorage.removeItem('field.alias');
            OcInfraModule.AppInjector.get(CommonService).quoteValuesHtml = '';
        }
        OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
    }

    showWorkRequestRecords() {
        const resource = this.resourceService.getResourceFromAliasName("workRequestHistory");
        return resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length;
    }
    showNoRecordsMsg() {
        const resource = this.resourceService.getResourceFromAliasName("workRequestHistory");
        return !(resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length);
    }
    showOutgoingTable() {
        const resource = this.resourceService.getResourceFromAliasName("outgoingmoney");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.outgoingmoney ? resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }

    showHideProcessDate() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details.data.product_type.includes('A')) {
                var tbl = document.querySelectorAll(".moneyTable table");
                if (tbl && tbl[0]) {
                    tbl[0].setAttribute("id", '_phpaymentHistory');
                }
                return false;
            }
            else {
                return true;
            }
        }
    }
    
    showNoRecordsMsgOgm() {
        const resource = this.resourceService.getResourceFromAliasName("outgoingmoney");
        return !(resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length);
    }
    showHcoiTable() {
        const resource = this.resourceService.getResourceFromAliasName("historicalcoi");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.historicalcoi ? resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }
    showHDividendHistoryTable() {
        const resource = this.resourceService.getResourceFromAliasName("dividendHistory");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.dividend_history ? resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }
    showNoRecordsMsgDividendHist() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const resource = resourceService.getResourceFromAliasName("dividendHistory");
        let isData =  OcInfraModule.AppInjector.get(CommonService).getNested(resource,"response","_links","item")
        return !(isData && !_.isEmpty(isData));
    }
    showNoRecordsMsgHcoi() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        const resource = resourceService.getResourceFromAliasName("historicalcoi");
        let isData =  OcInfraModule.AppInjector.get(CommonService).getNested(resource,"response","_links","item")
        return !(isData && !_.isEmpty(isData));
    }
    showPaymentTable() {
        const resource = this.resourceService.getResourceFromAliasName("paymenthistory");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.paymenthistory ? resource && resource.response && resource.response._links && resource.response._links.item.length : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }
    showNoRecordsMsgPayment() {
        const resource = this.resourceService.getResourceFromAliasName("paymenthistory");
        return !(resource && resource.response && resource.response._links && resource.response._links.item.length);
    }
    showIncomingMoneyTable() {
        const resource = this.resourceService.getResourceFromAliasName("incomingmoney");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.incomingmoney ? resource && resource.response && resource.response._links && resource.response._links.item.length : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }
    showNoRecordsMsgIncomingMoney() {
        const resource = this.resourceService.getResourceFromAliasName("incomingmoney");
        return !(resource && resource.response && resource.response._links && resource.response._links.item.length);
    }
    showProgramEnrollmentTable() {
        const resource = this.resourceService.getResourceFromAliasName("programenrollment");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        
        return selectedProperties && selectedProperties.programenrollment ? resource && resource.response && resource.response._links && resource.response._links.item.length : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }
    showNoRecordsMsgProgramEnrollment() {
        const resource = this.resourceService.getResourceFromAliasName("programenrollment");
        let isData =  OcInfraModule.AppInjector.get(CommonService).getNested(resource,"response","_links","item")
        return !(isData && !_.isEmpty(isData));
        // return !(resource && resource.response && resource.response._links && resource.response._links.item.length);
    }
    showSuspenceTable() {
        const resource = this.resourceService.getResourceFromAliasName("policySuspense");
        let isData =  OcInfraModule.AppInjector.get(CommonService).getNested(resource,"response","_links","item")
        return (isData && !_.isEmpty(isData))?true:false;
    }
    showNoRecordsMsgSuspence() {
        const resource = this.resourceService.getResourceFromAliasName("policySuspense");
        let isData =  OcInfraModule.AppInjector.get(CommonService).getNested(resource,"response","_links","item")
        return !(isData && !_.isEmpty(isData));
    }
    showWithdrawalTable() {
        const resource = this.resourceService.getResourceFromAliasName("historyFunds");
        let selectedProperties = OcInfraModule.AppInjector.get(CommonService).filterProperties;
        return selectedProperties && selectedProperties.historyFunds ? resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length : resource && resource.response && resource.response._links && resource.response._links.item.length;
    }
    showNoRecordsMsgWith() {
        const resource = this.resourceService.getResourceFromAliasName("historyFunds");
        return !(resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length);
    }

    showRevolvingTable() {
        const resource = this.resourceService.getResourceFromAliasName("policyRevolvingError");
        return resource && resource.response && resource.response._links && resource.response._links.item.length;
    }
    showNoRecordsMsgRevolving() {
        const resource = this.resourceService.getResourceFromAliasName("policyRevolvingError");
        return !(resource && resource.response && resource.response._links && resource.response._links.item.length);
    }
    showClaimsTable() {
        const resource = this.resourceService.getResourceFromAliasName("claimList");
        return resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length;
    }
    showNoRecordsMsgClaims() {
        const resource = this.resourceService.getResourceFromAliasName("claimList");
        return !(resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length);
    }
    showPaymentsTable() {
        const resource = this.resourceService.getResourceFromAliasName("payments");
        return resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length;
    }
    showNoRecordsMsgPayments() {
        const resource = this.resourceService.getResourceFromAliasName("payments");
        let isData =  OcInfraModule.AppInjector.get(CommonService).getNested(resource,"response","_links","item")
        return !(isData && !_.isEmpty(isData));
    }

    checkClass() {
        let correspondenceCheckWrapper = document.querySelectorAll('div.mat-step-icon-state-number')
        if (correspondenceCheckWrapper && correspondenceCheckWrapper.length) {
            for (let i = 0; i < correspondenceCheckWrapper.length; i++) {
                correspondenceCheckWrapper[i]['classList'].add('mat-step-icon-state-edit');
            }
        }
        return true;
    }

    csrWidgetVisibility() {
        return OcInfraModule.AppInjector.get(CommonService).csrUser;
    }

    olsWidgetVisibility() {
        return OcInfraModule.AppInjector.get(CommonService).olsUser;
    }

    showNoRecordsmsgAgent() {
        let resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('searchAgents');
        let isData =  OcInfraModule.AppInjector.get(CommonService).getNested(resource,"response","_links","item")
        return !(isData && !_.isEmpty(isData));
    }

    showCustomerTable() {
        let resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('searchClients');
        let isData =  OcInfraModule.AppInjector.get(CommonService).getNested(resource,"response","_links","item")
        return (isData && !_.isEmpty(isData))?true:false;
    }

    showNoRecordsMsgCustomer() {
        let resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('searchClients');
        let isData =  OcInfraModule.AppInjector.get(CommonService).getNested(resource,"response","_links","item")
        return !(isData && !_.isEmpty(isData));
    }

    showAgentTable() {
        let resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('searchAgents');
        return resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length;
    }

    showNoRecordsMsgCallHistory() {
        let resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyCallHistory');
       let isData =  OcInfraModule.AppInjector.get(CommonService).getNested(resource,"response","_links","item")
        return !(isData && !_.isEmpty(isData));
    }

    showPendingTransactionsTable() {
        const resource = this.resourceService.getResourceFromAliasName("pendingTransactions");
        return !(resource && resource.response && resource.response._links && resource.response._links.item.length && resource.response._links.item[0].summary._embedded);
    }

    showPendingTransactionsMsg() {
        const resource = this.resourceService.getResourceFromAliasName("pendingTransactions");
        return resource && resource.response && resource.response._links && resource.response._links.item.length && resource.response._links.item[0].summary._embedded;
    }

    showCallHistoryTable() {
        let resource = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyCallHistory');
        return resource && resource.response && resource.response._links && resource.response._links.item && resource.response._links.item.length;
    }

    policyDetailsAobVisible() {
        return OcInfraModule.AppInjector.get(CommonService).aobVisible;
    }
    policyDetailsPorfolioVisible() {
        return !OcInfraModule.AppInjector.get(CommonService).aobVisible;
    }
    gmibPayoutVisible() {
        return true;
    }

    chanageCsrNameLabel(field) {
        if (field && (field.value || field.type === "button")) {
            let element = document.getElementsByClassName("olsCsrLabelClass");
            if (OcInfraModule.AppInjector.get(CommonService).olsUser && element.length) {
                element[0]["childNodes"][0]["childNodes"][0]["childNodes"][1]["childNodes"][0]["nodeValue"] = 'OLS CSR Name';
            }
            return true;
        }
        return true;
    }

    confirmationNumber() {
        return !OcInfraModule.AppInjector.get(CommonService).tir2Ticket;
    }

    confirmationTransaction() {
        return !OcInfraModule.AppInjector.get(CommonService).confirmation;
    }

    confirmationNumberTir2Ticket() {
        if (OcInfraModule.AppInjector.get(CommonService).tir2Ticket && document.getElementById('autorization-label_label')) {
            this.authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse;
            document.getElementById('autorization-label_label').innerHTML = 'Confirmation #' + this.authResponse.updateResponse.data.confirmationNumber;
        }
        return OcInfraModule.AppInjector.get(CommonService).tir2Ticket;
    }
    confirmationTransactionVisible() {
        if (OcInfraModule.AppInjector.get(CommonService).confirmation && document.getElementById('autorization-label_label')) {
            this.authResponse = OcInfraModule.AppInjector.get(CommonService).authResponse;
            document.getElementById('autorization-label_label').innerHTML = 'Confirmation #' + this.authResponse.updateResponse.data.confirmationNumber;
        }

        return OcInfraModule.AppInjector.get(CommonService).confirmation;
    }
    showCustomerBanner() {
        if ((OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess)) {
            OcInfraModule.AppInjector.get(CommonService).agentBanner = false;
            return OcInfraModule.AppInjector.get(CommonService).agentBanner;
        } else {
            return OcInfraModule.AppInjector.get(CommonService).agentBanner;
        }
    }

    showAgentBanner() {
        if ((OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess)) {
            OcInfraModule.AppInjector.get(CommonService).customerBanner = false;
            return OcInfraModule.AppInjector.get(CommonService).customerBanner;
        } else {
            return OcInfraModule.AppInjector.get(CommonService).customerBanner;
        }
    }


    withdrawlSurrenderModalTitle = () => {
        return OcInfraModule.AppInjector.get(CommonService).getWithdrawalSurrenderAction().getWithdrawalSurrenderAction();
    }

    formatPhoneNum(field) {
        if (field && (field.value || field.type === "label")) {
            let replacePhone = field.value.replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
            let element = document.getElementsByClassName("phoneNumberFormat");
            field.viewValue = replacePhone;
            return true;
        }
        return true;
    }

    formatPhoneNumberText(field) {
        if (field && (field.value || field.type === "label")) {
            let replacePhone = field.value.replace(/[^0-9 -]/g, "");
            let element = document.getElementsByClassName("phoneNumberFormat");
            field.viewValue = replacePhone;
            field.value = replacePhone;
            field.required == 'phoneNumRequired' ? this.phoneNumRequired() : false;
            return true;
        }
        return true;
    }

    disableWorkRequestHistory() {
        let productInfo = this.commonService.productInfo; return (!(OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess || OcInfraModule.AppInjector.get(CommonService).authsucess)) || (productInfo && productInfo["product_type"].toLowerCase() == "h");
    }

    accordianDisableForHclient() {
        let productInfo = this.commonService.productInfo;
        return productInfo && productInfo.length > 0 && productInfo["product_type"].toLowerCase() == "h";
    }

    disableOlsWorkRequestHistory() {
        return OcInfraModule.AppInjector.get(CommonService).otherRole == "Unauthorized caller" ? true : !(OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess || OcInfraModule.AppInjector.get(CommonService).authsucess);
    }
    phoneNumRequired() {
        if ((OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess)) {
            return true;
        }
        return false;
    }
    onPendingTransactionTabClick(tabClicked) {
        // on the click of pending transaction tab we refresh data.
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let pendingTransactions = resourceService.getResourceFromAliasName('pendingTransactions');
        let pendingTransactionGETURL = "";
        let headers;
        // in case of pending transaction never populated we can hook off policyDetails to get URL.
        if (!pendingTransactions) {
            let policyDetails = resourceService.getResourceFromAliasName('policyDetails') ? resourceService.getResourceFromAliasName('policyDetails').response : null;
            pendingTransactionGETURL = policyDetails._links.self.href + "/pendingTransactions";
            headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("policyDetails");
        } else {
            pendingTransactionGETURL = pendingTransactions._links.self.href;
            headers = OcInfraModule.AppInjector.get(CommonService).getHeaderByAliasName("pendingTransactions");
        }

        OcInfraModule.AppInjector.get(APICallerService).refresh(pendingTransactionGETURL, { 'alias': "pendingTransactions", 'headers': headers }).subscribe(refreshResponse => {

        });
    }

    // Hidden the section when Policy become "Lapsed"
    policyStatus() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        let isVisble= false;
        if ((policyDetails.data.policy_status).toLowerCase() == 'l') {
            isVisble= true;
        } else if(this.isUL()) {
            if( (policyDetails.data.policy_status.toLowerCase() == 'o' )||
                ((this.checkULType("U1") || this.checkULType("U2"))&&  policyDetails.data.policy_status == '3') ||
                ( this.checkULType("U2") &&  policyDetails.data.policy_status.toLowerCase() == '6')){
                    isVisble = true;
            }
        }
        return isVisble;
    }


    isnotu1u2(){
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        if (this.checkULType("U3")) {
            return true;
        }
        else if ((policyDetails.data.policy_status).toLowerCase() == 'l' && policyDetails.data.product_type.includes("T")) {
            return true;
        } 
        else {
            return false;
        }
    }

    // visible for u3
    isVUL() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        if (this.checkULType("U3")) {
            return true;
        }
        else if (this.isUL()) {
            if ((policyDetails.data.policy_status.toLowerCase() == 'o') || (policyDetails.data.policy_status.toLowerCase() == 'l') || ((this.checkULType("U1") || this.checkULType("U2")) && policyDetails.data.policy_status == '3') || (this.checkULType("U2") && policyDetails.data.policy_status.toLowerCase() == '6')) {
                return true;
            }
        }
        else if ((policyDetails.data.policy_status).toLowerCase() == 'l' && policyDetails.data.product_type.includes("T")) {
            return true;
        } 
        else {
            return false;
        }
    }

    isUlButton() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        if (this.checkULType("U3")) {
            return true;
        }
        else if (this.isUL()) {
            if (((policyDetails.data.policy_status.toLowerCase() == 'o' || policyDetails.data.policy_status.toLowerCase() == 'l')) || ((this.checkULType("U1") || this.checkULType("U2")) && policyDetails.data.policy_status == '3') || (this.checkULType("U2") && policyDetails.data.policy_status.toLowerCase() == '6')) {
                return true;
            }
        }
        return false;
    }

    isTermButton(){
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        if (this.checkULType("U3")) {
            return true;
        }
        else if ((policyDetails.data.policy_status).toLowerCase() == 'l' && policyDetails.data.product_type.includes("T")) {
            return true;
        }
        else{ return false; }
    }

    // Quote Values For Bills tab(Reinstatement Quote)
    quoteValuesForBills() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let billingInfo = resourceService.getResourceFromAliasName('billingInfo');
        // To get assigned date fieds value from Common servive file
        let reinstatementQuoteObj = OcInfraModule.AppInjector.get(CommonService).selectedDateObj;
        if(this.checkULType("U1") || this.checkULType("U2")){
            delete reinstatementQuoteObj.paid_to_date;
            delete reinstatementQuoteObj.requirements_received_date

        }
        // Get URL from Billing tab Object
        let url = billingInfo && billingInfo._links && billingInfo._links['billing/reinstatementquote'] && billingInfo._links['billing/reinstatementquote'].href;
        // Get header for billingInfo
        let header = _.filter(Configuration.config.headers.multiHostUrlHeader, function (o) { return o.alias == 'billingInfo' });
        header[0]['headers']['role'] = OcInfraModule.AppInjector.get(CommonService).selectedRole;
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: true });
        let quotedLabel = document.getElementById('re-instatement-quote-label');
        OcInfraModule.AppInjector.get(CommonService).patchCallback(url, reinstatementQuoteObj, header[0].headers).subscribe(response => {
            delete header[0]['headers']['role'];
            this.removeReinstatementQuotedLable(quotedLabel);
            this.reinstateCheckedStatus();
            OcInfraModule.AppInjector.get(CommonService).total_remit_due = response.total_remit_due;

            // Creating reinstatment quote value label amount and assining amount to that element
            let reinstatementSection = document.querySelector('.reinstatementquoteSection .form-group');
            var label = document.createElement("label");
            label.setAttribute('id', 're-instatement-quote-label')
            label.innerHTML = "$" + response.total_remit_due;
            reinstatementSection.appendChild(label);
            (document.getElementById("billing-info-total-remit-due-action-checkbox-input") as HTMLInputElement).disabled = false;
            (<HTMLInputElement>document.getElementById('quote-values-quote-values-button')).disabled = true;
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
        }, error => {
            delete header[0]['headers']['role'];
            this.removeReinstatementQuotedLable(quotedLabel);
            (<HTMLInputElement>document.getElementById('quote-values-quote-values-button')).disabled = true;
            this.reinstateCheckedStatus();

            (document.getElementById("billing-info-total-remit-due-action-checkbox-input") as HTMLInputElement).disabled = true;
            error && error._embedded && error._embedded.status_report && error._embedded.status_report.messages &&
                error._embedded.status_report.messages[0] && error._embedded.status_report.messages[0].message ?
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error._embedded.status_report.messages[0].message, 'Error', 0) :
                OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage('Something went wrong please try again', 'Error', 0);
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false })
        });
    }


    // remove Reinstatement quoted lable
    removeReinstatementQuotedLable(quotedLabel) {
        if (typeof (quotedLabel) != 'undefined' && quotedLabel != null) {
            document.getElementById('re-instatement-quote-label').remove();
        }
    }

    // If user already added Reinstatement but again changed the date / after added reinstatement then failure

    reinstateCheckedStatus() {
        let reinstateCheckedStatus = (document.getElementById("billing-info-total-remit-due-action-checkbox-input") as HTMLInputElement).checked;
        if (reinstateCheckedStatus) {
            document.getElementById('billing-info-total-remit-due-action-checkbox-input').click();
            OcInfraModule.AppInjector.get(CommonService).total_remit_due = '';
        }
    }

    // Section load disable Reinstatement quote value button disabled
    disableQuoteButton() {
        return false;
    }

    // Billing Tab - Reinstatement quote check box disabled
    disableReinstatementCheckbox() {
        if(this.isPolicyAvailable()){
            OcInfraModule.AppInjector.get(CommonService).disableReinstatementCheckbox();
        }
    }

    beneficiaryTableCheckedAction(field) {
        let clickedBeneficiary = [];
        this.html = '';
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let response = resourceService.getResourceFromAliasName(field.alias);
        let responseJson = JSON.parse(JSON.stringify(response.response._links.item));
        var grid = document.querySelector(".beneficiaryRolesTab");
        let beneficiaryInputLength = grid.getElementsByTagName('input')
        let columnItem = ['party_name', 'beneficiary_type', 'beneficiary_relationship'];
        // Iterating every input element in the table and getting selected object from the original response
        for (let i = 0; i < beneficiaryInputLength.length; i++) {
            let inputCheckedStatus = (grid.getElementsByTagName('input')[i] as HTMLInputElement).checked;
            if (inputCheckedStatus) {
                responseJson[i].summary['beneficiary_type'] = responseJson[i].summary['beneficiary_type'] + " " + responseJson[i].summary['beneficiary_split_percent'] + "%";
                clickedBeneficiary.push(responseJson[i].summary);
            }
        }
        let columnNameObj = {
            party_name: 'Full Name',
            beneficiary_type: 'Type/Percent',
            beneficiary_relationship: 'Relationship'
        };
        // Iterating every clicked object and binding to Close call Notes
        for (let i = 0; i < clickedBeneficiary.length; i++) {
            this.html += '<dt class="pull-left">' + columnNameObj.party_name + ':</dt><dd>&nbsp;' + clickedBeneficiary[i].party_name + '</dd>' + '<dt class="pull-left">' + columnNameObj.beneficiary_type + ':</dt><dd>&nbsp;' + clickedBeneficiary[i].beneficiary_type + '</dd>' + '<dt class="pull-left">' + columnNameObj.beneficiary_relationship + ':</dt><dd>&nbsp;' + clickedBeneficiary[i].beneficiary_relationship + '</dd>';
        }

        this.checkedProperties = this.reCreateNewObject(clickedBeneficiary, columnItem);
        this.checkedProperties = JSON.parse(JSON.stringify(this.checkedProperties));

        // Renaming the object keys
        for (let i = 0; i < this.checkedProperties.length; i++) {
            delete Object.assign(this.checkedProperties[i],
                {
                    ['Full Name']: this.checkedProperties[i]['party_name'],
                })['party_name'];

            delete Object.assign(this.checkedProperties[i],
                {
                    ['Type/Percent']: this.checkedProperties[i]['beneficiary_type'],
                })['beneficiary_type'];

            delete Object.assign(this.checkedProperties[i],
                {
                    ['Relationship']: this.checkedProperties[i]['beneficiary_relationship']
                })['beneficiary_relationship'];
        }
        localStorage.setItem(field.alias, JSON.stringify(this.checkedProperties));
        if (clickedBeneficiary.length) {
            OcInfraModule.AppInjector.get(CommonService).beneficiaryHtml = '<span style="font-size:15px" class="pad-lAr-10px">BENEFICIARY DETAILS</span><dl class="pad-lAr-10px">' + this.html + '</dl>';
            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        } else {
            OcInfraModule.AppInjector.get(CommonService).beneficiaryHtml = '';
            OcInfraModule.AppInjector.get(CommonService).showRequestedDetails();
        }
    }

    reCreateNewObject(data, displayedColumns) {
        let sendBack = data.map(object => {
            var newObject = {};
            for (const objPropertyName of displayedColumns) {
                if (!object.hasOwnProperty(objPropertyName)) {
                    newObject[objPropertyName] = '-';
                } else {
                    newObject[objPropertyName] = object[objPropertyName] ? object[objPropertyName] : '-';
                }
            }
            return newObject;
        });
        return sendBack;
    }

    // If whole life base not to show in UI
    isNotWholeLifeandUL() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        if (policyDetails && this.isNotRetailAnnuity()) {
            // future purpose - if (!(policyDetails.response.product_type_desc == 'WHOLE LIFE BASE') && !(policyDetails.response.product_type_desc.includes('T1')) && (policyDetails.response.product_type == 'U1')) {
            if (!(policyDetails.response.product_type_desc == 'WHOLE LIFE BASE') && !(policyDetails.response.product_type_desc.includes('T1')) && !(policyDetails.response.product_type_desc.includes('U'))) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    otherRoleAuthSucessAndNotGrp(field){
        if (OcInfraModule.AppInjector.get(CommonService).changeotherRoleAuthsucess && field && this.isOnlyGroupAnn()) {
            return true;
        }
        return false;
    }

    otherRoleAuthSucessAndGrpAnn(field){
        if (OcInfraModule.AppInjector.get(CommonService).changeotherRoleAuthsucess && field && (!this.isOnlyGroupAnn())) {
            return true;
        }
        return false;
    }

      // If whole life base or term to show in UI
      isWholeLifeOrTerm() {
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
        if (policyDetails) {
            if ((policyDetails.response.product_type_desc == 'WHOLE LIFE BASE') || (policyDetails.response.product_type_desc.includes('T1'))) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    //method used for only PANOCSR-2204
    isAnnuityCV15(){
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            let planCode = details.data.plan_code;
            let planCodelist = ['101000', '106000', '107000', '108000', '113000', '121000', '122000',
             '141000', '201000', '207000', '211000', '213000', '222000', '240000', '241000','140000',
              '141000', '141LNP', '240000', '241000', '241LNP'];

            let  isPlancode =  _.includes(planCodelist,planCode)
            if(this.isAnnuity() && isPlancode === true){
                return true;
            } else {
                return false;
            }
    }
}

    isAnnuityandLife() {
        if (this.isPolicyAvailable()) {
            let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
            if (details.data.product_type.includes('A') || details.data.product_type.includes('T') || details.data.product_type.includes('U')) {
                return true;
            }
            else {
                return false;
            }
        }
    }

loadTabs() {    
    this.lazyloadPolicyDetailsTabs();    
    this.lazyloadFinancialHistoryTabs();
    return this.hideOnlyForHClient();
}


lazyloadPolicyDetailsTabs = async () => {
    Array.from(document.querySelectorAll(".policyDetailsTab .mat-tab-labels > div.mat-tab-label")).forEach(async (policyDetailsTab, index) => {
        let tabElement = <HTMLElement>policyDetailsTab;
        const tabId = tabElement.getAttribute("id");
        if ((index == 1 && !this.isAnnuity()) || (index == 2 && !this.showTraditionalAndAnnutiesNonGroup()) || (index == 3 && !this.exceptGroupAnn_AllOthers()) || (index == 4 && !this.isOnlyGroupAnn()) || (index == 5 && !this.isNotAnnuity()) || (index == 6 && !this.isAnnuity()) || (index == 7 && !this.isOnlyGroupAnn()) || (index == 11 && !this.isNotAnnuityorUL()) || (index == 12 && !this.isAnnuityorUL())) {
            (<HTMLElement>policyDetailsTab).style.display = 'none';
            tabElement.setAttribute("tab-display", "none");
        } else {
            (<HTMLElement>policyDetailsTab).style.display = 'block';
            tabElement.setAttribute("tab-display", "block");
        }
    });
}

    lazyloadFinancialHistoryTabs = async () => {
        Array.from(document.querySelectorAll(".financialHistoryTab .mat-tab-labels > div.mat-tab-label")).forEach(async (financialHistoryTab, index) => {
            let tabElement = <HTMLElement>financialHistoryTab;
            const tabId = tabElement.getAttribute("id");
            if ((index == 1 && !this.isAnnuity()) || (index == 0 && !this.hideNonAnnuityFields()) || (index == 2 && !this.isAnnuity()) ||  (index == 5 && !this.isAnnuityandLife())  || (index == 6 && !this.hideNonAnnuityAndUlFields()) || (index == 7 && !this.isAnnuityorUL()) || (index == 8 && !this.isNotUL()) || (index == 9 && !this.isAnnuity())) {
                (<HTMLElement>financialHistoryTab).style.display = 'none';
                tabElement.setAttribute("tab-display", "none");
            } else {
                (<HTMLElement>financialHistoryTab).style.display = 'block';
                tabElement.setAttribute("tab-display", "block");
                if (index == 1 && tabElement.getAttribute("tab-display") == 'block') {
                    OcInfraModule.AppInjector.get(CommonService).financialClickId = tabId;
                }
            }
        });
    }

    setGenderLabelName(field) {
        const selectedRolesResponse = this.selectedRolesResponse();
        if (selectedRolesResponse) {
            const {role} = selectedRolesResponse.data;
            if (role === this.commonService._Annuity || role === this.commonService._Owner) {
                this.determineGender(role, field);
            } else if (role === this.commonService._Owner || role === this.commonService._Insured) {
                this.determineGender(role, field);
            } else {
                this.determineGender(null, field);
            };

            return true;
    }
    return false;
    }

    // Selected role 
    selectedRolesResponse(){
        const resourceService = OcInfraModule.AppInjector.get(ResourceService);
        let selectedIdentity = resourceService.getResourceFromAliasName('selectedRoles');
        if(selectedIdentity && selectedIdentity.data){
            return selectedIdentity;
        } else {
            return false;
        };
    }
    
    determineGender(role:string, field:any){
        field['label']=  role ? role + ' ' + this.selectedLang['_GENDER'] : '';
    }

    annuitiesAddButton() {
        let authsucess = OcInfraModule.AppInjector.get(CommonService).authsucess;
        let isAnnuity = this.isAnnuity();
        if(authsucess && isAnnuity){
            return true;
        } else{
            return false;
        }
    }


}
