import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { APICallerService, Configuration, OcInfraModule, ResourceService } from '@diaas/ux-web';
import { AdvGrowlService } from 'primeng-advanced-growl';
import { CommonService } from '../../../services/common.service';
import * as _ from 'lodash';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as $ from 'jquery';
import { CreateDeathClaimSharedService } from '../create-death-claim-shared.service';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../format-datepicker';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
export interface dropdown {
  tooltip: any;
  id: string;
  vewValue: string;
}
const INSURED = "Insured";
@Component({
  selector: 'claim-party-information',
  templateUrl: './claim-party-information.component.html',
  styleUrls: ['claim-party-information.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ]
})

export class ClaimPartyInformationComponent implements OnInit {
  @Input() portfolioDeathClaimInfo: any;
  @ViewChild('myTable') myTable: MatTable<any>;
  claimpartyForm: FormGroup;
  gender: dropdown[];
  residenceState: dropdown[];
  relationToInsuredCode: dropdown[];
  residenceCountry: dropdown[];
  mailingState: dropdown[];
  mailingCountry: dropdown[];
  searchFirstName: any;
  searchLastName: any;
  dataSource: any;
  displayedColumns = ['name', 'resState', 'roles'];
  spans = [];
  addPartyClicked: boolean = false;
  effectiveMaxDate = new Date();
  effectiveMinDate = new Date(1900, 1, 1);
  partyTypeIndividual: boolean = true;
  partyTypeOrganization: boolean = false;
  fieldData: any;
  partyData: any;
  requiredProperties: any;
  showErrorMessage: boolean = false;
  message: string;
  authorizationCheck: boolean = false;
  claimPartyRoleSource: any;
  claimPartyRoleDisplayedColumns = ['claimRoleCodeDisplayValue', 'source', 'role'];
  displayedColumns1 = ['Policy']
  rolesList: any;
  rolesDisplayList: any;
  payload: {};
  selectedRowIndex: any;
  rowClicked: boolean = false;
  claimPartyResetResponse: [];
  addPartyDisable: boolean = false;
  aWDclaimPartyList = [];
  selectedaWDclaimParty: any;
  selectedAWD = [];
  assosiatePolicy = [];
  postPayload: {};
  public validMailingAddress = false;
  public isAddressSameasResidence = false;
  isClaimPartyRoleDuplicate: boolean = false;
  ClaimPartyBeforeAdd: any;
  isMutlipleInsuredRoles: boolean = false;
  resiAdd2Req: boolean = false;
  mailingAdd2Req: boolean = false;
  isAnnuity: boolean =false;
  isTextFieldDisabled = true;

  constructor(private _formBuilder: FormBuilder, private datePipe: DatePipe, private dialog: MatDialog,
    private createDeathClaimSharedSerice: CreateDeathClaimSharedService) { }

  ngOnInit() {
    this.formInit();
    if (OcInfraModule.AppInjector.get(CommonService).authsucess || OcInfraModule.AppInjector.get(CommonService).otherRoleAuthsucess) {
      this.message = "Enable button by completing required items."
      this.authorizationCheck = true;
      let details = OcInfraModule.AppInjector.get(ResourceService).getResourceFromAliasName('policyDetails');
      this.isAnnuity = (details.data.product_type.includes('A') ? true : false);

    }
    else {
      this.authorizationCheck = false;
      this.message = "Caller not authorized."
    }
  }

  formInit() {
    this.claimpartyForm = this._formBuilder.group({
      searchFirstName: new FormControl('', [Validators.required]),
      searchMiddleName: new FormControl(''),
      searchLastName: new FormControl('', [Validators.required]),
      gender: new FormControl(''),
      suffix: new FormControl(''),
      dob: new FormControl(''),
      searchGovId: new FormControl(''),
      relationToInsuredCode: new FormControl('10'),
      foreignTin: new FormControl(''),
      corporationName: new FormControl(''),
      residenceAddressLine1: new FormControl(''),
      residenceAddressLine2: new FormControl(''),
      residenceCity: new FormControl(''),
      residenceState: new FormControl(''),
      residenceZip: new FormControl(''),
      residenceCountry: new FormControl('1'),
      mailingAddressLine1: new FormControl(''),
      mailingAddressLine2: new FormControl(''),
      mailingCity: new FormControl(''),
      mailingState: new FormControl(''),
      mailingZip: new FormControl(''),
      mailingCountry: new FormControl('1'),
      primaryPhone: new FormControl(''),
      faxPhone: new FormControl(''),
      optOutMobile: new  FormControl(false),
      text: new FormControl({ value: '', disabled: true  }),
      optOutEmail: new  FormControl(false),
      email: new FormControl(''),
      partyType: new FormControl(''),
      mailingAddress: new FormControl('')
    })
  }
  getClaimPartylist(callFromCreate = false) {
    let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    OcInfraModule.AppInjector.get(CommonService).getCallback(this.portfolioDeathClaimInfo._options.links[2].href, claimListHeader[0].headers).subscribe(response => {
      this.fieldData = response;
      this.rolesList = this.fieldData._options.properties.claimRole.oneOf.map(item => { return { id: item.enum[0], title: item.title } });
      this.rolesDisplayList =  _.filter(this.rolesList, function (o) { return o.id != '1057' })
      this.formDropDownValues();
      this.spans = [];
      this.dataSource = this.extractData(response.claimPartyList ? response.claimPartyList : []);
      this.cacheSpan('Id', d => d.id);
      if(callFromCreate == true){
        this.createClaimKit();
      }
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong please try again", 'Error', 0);
    });
  }

  formDropDownValues() {
    this.requiredProperties = this.fieldData._options.required;
    this.searchFirstName = this.fieldData._options.required;
    this.searchLastName = this.fieldData._options.required;
    this.gender = this.fieldData._options.properties.gender.oneOf;
    this.relationToInsuredCode = this.fieldData._options.properties.relationToInsuredCode.oneOf;
    this.residenceState = this.fieldData._options.properties.residenceState.oneOf;
    this.residenceCountry = this.fieldData._options.properties.residenceCountry.oneOf;
    this.mailingState = this.fieldData._options.properties.mailingState.oneOf;
    this.mailingCountry = this.fieldData._options.properties.mailingCountry.oneOf;
  }

  cacheSpan(key, accessor) {
    for (let i = 0; i < this.dataSource.length;) {
      let currentValue = accessor(this.dataSource[i]);
      let count = 1;
      for (let j = i + 1; j < this.dataSource.length; j++) {
        if (currentValue != accessor(this.dataSource[j])) {
          break;
        }
        count++;
      }
      if (!this.spans[i]) {
        this.spans[i] = {};
      }
      this.spans[i][key] = count;
      i += count;
    }
  }

  getRowSpan(col, index) {
    return this.spans[index] && this.spans[index][col];
  }

  private extractData(data) {
    return data.reduce((current, next) => {
      let searchFirstName = next.searchFirstName ? next.searchFirstName : "";
      let searchMiddleName = next.searchMiddleName ? next.searchMiddleName : "";
      let searchLastName = next.searchLastName ? next.searchLastName : "";
      let corporationName = next.corporationName ? next.corporationName : "";
      if (next.claimPartyRoleList && next.claimPartyRoleList.length > 0) {
        next.claimPartyRoleList.forEach(b => {
          current.push({
            id: next.partyID,
            name: ((next.searchFirstName || next.searchMiddleName || next.searchLastName || next.corporationName) ? ((next.searchFirstName || next.searchMiddleName || next.searchLastName) ? `${searchFirstName} ${searchMiddleName} ${searchLastName}` : `${corporationName}`) : ""),
            resState: next.state ? next.state.substr(0, 2) : "", roles: b
          });
        });
      } else {
        current.push({
          id: next.partyID,
          name: (next.searchFirstName || next.searchMiddleName || next.searchLastName) ? `${searchFirstName} ${searchMiddleName} ${searchLastName}` : `${corporationName}`,
          resState: next.state ? next.state.substr(0, 2) : "", roles: { claimRoleCodeDisplayValue: '', source: '' }
        });
      }
      return current;
    }, []);
  }

  formatResidencePostalCode(e) {
    if (e.target.value.length > 5 && e.target.value.length < 10) {
      this.claimpartyForm.controls["residenceZip"].setErrors({ 'incorrect': true });
    }
    else {
      this.claimpartyForm.controls["residenceZip"].setErrors(null);
      this.claimpartyForm.controls['residenceZip'].updateValueAndValidity();
    }
    e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57) ? e.preventDefault() : e.target["value"] = e.target["value"].replace(/(\d{5}(?!\d?$))\-?/g, '$1-');
  }

  formatMailingPostalCode(e) {
    if (e.target.value.length > 5 && e.target.value.length < 10) {
      this.claimpartyForm.controls["mailingZip"].setErrors({ 'incorrect': true });
    }
    else {
      this.claimpartyForm.controls["mailingZip"].setErrors(null);
      this.claimpartyForm.controls['mailingZip'].updateValueAndValidity();
    }
    (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) ? e.preventDefault() : e.target["value"] = e.target["value"].replace(/(\d{5}(?!\d?$))\-?/g, '$1-');
  }

  onlyNumber(e) {
    if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
      e.preventDefault();
    }
  }

  mailingstateChange(e) {
    this.mailingAddressValidation();
    if (e == 0) {
      this.claimpartyForm.controls["mailingState"].setErrors({ 'incorrect': true });
    }
    else {
      this.claimpartyForm.controls["mailingState"].setErrors(null);
      this.claimpartyForm.controls['mailingState'].updateValueAndValidity();
    }
  }

  residencestateChange(e) {
    this.resiChange();
    if (e == 0) {
      this.claimpartyForm.controls["residenceState"].setErrors({ 'incorrect': true });
    }
    else {
      this.claimpartyForm.controls["residenceState"].setErrors(null);
      this.claimpartyForm.controls['residenceState'].updateValueAndValidity();
    }
  }

  resiCntryChnge(e){
    if(e != 1){
      this.claimpartyForm.get("residenceAddressLine1").setValue("");
      this.claimpartyForm.get("residenceAddressLine2").setValue("");
      this.claimpartyForm.get("residenceCity").setValue("");
      this.claimpartyForm.get("residenceCity").disable();
      this.claimpartyForm.get("residenceState").setValue("");
      this.claimpartyForm.get("residenceState").disable();
      this.claimpartyForm.get("residenceZip").setValue("");
      this.claimpartyForm.get("residenceZip").disable();
      this.resiAdd2Req = true;
      this.claimpartyForm.controls['residenceAddressLine2'].setValidators([Validators.required]);
      this.claimpartyForm.controls['residenceAddressLine2'].updateValueAndValidity();
    }else{
      this.claimpartyForm.get("residenceAddressLine1").setValue("");
      this.claimpartyForm.get("residenceAddressLine2").setValue("");
      this.claimpartyForm.get("residenceCity").enable();
      this.claimpartyForm.get("residenceCity").setValue("");
      this.claimpartyForm.get("residenceState").enable();
      this.claimpartyForm.get("residenceState").setValue("");
      this.claimpartyForm.get("residenceZip").enable();
      this.claimpartyForm.get("residenceZip").setValue("");
      this.claimpartyForm.controls['residenceAddressLine2'].setValidators(null);
      this.claimpartyForm.controls['residenceAddressLine2'].updateValueAndValidity();
      this.resiAdd2Req = false;
    }
  }

  mailingCntryChnge(e) {
    if (e != 1) {
      this.claimpartyForm.get("mailingAddressLine1").setValue("");
      this.claimpartyForm.get("mailingAddressLine2").setValue("");
      this.claimpartyForm.get("mailingCity").setValue("");
      this.claimpartyForm.get("mailingCity").disable();
      this.claimpartyForm.get("mailingState").setValue("");
      this.claimpartyForm.get("mailingState").disable();
      this.claimpartyForm.get("mailingZip").setValue("");
      this.claimpartyForm.get("mailingZip").disable();
      this.claimpartyForm.controls['mailingAddressLine2'].setValidators([Validators.required]);
      this.claimpartyForm.controls['mailingAddressLine2'].updateValueAndValidity();
      this.mailingAdd2Req = true;
    } else {
      this.claimpartyForm.get("mailingAddressLine1").setValue("");
      this.claimpartyForm.get("mailingAddressLine2").setValue("");
      this.claimpartyForm.get("mailingCity").enable();
      this.claimpartyForm.get("mailingCity").setValue("");
      this.claimpartyForm.get("mailingState").enable();
      this.claimpartyForm.get("mailingState").setValue("");
      this.claimpartyForm.get("mailingZip").enable();
      this.claimpartyForm.get("mailingZip").setValue("");
      this.claimpartyForm.controls['mailingAddressLine2'].setValidators(null);
      this.claimpartyForm.controls['mailingAddressLine2'].updateValueAndValidity();
      this.mailingAdd2Req = false;
    }
  }

  blockSpecialChar(e) {
    var k = e.keyCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  govtIdFormat(e): boolean {
    {
      if (e.target["value"].match(/[a-z]/i)) {
        e.target["value"] = '';
      }
      // Removing extra char when pasting
      if (e.keyCode == 86 || e.keyCode == 17) {
        e.target["value"] = e.target["value"].substring(0, 9);
      }
      if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
        e.preventDefault();
      } else {
        let str = new String(e.target["value"]);
        str = str.replace(/[A-Za-z$-()\.]/g, '');
        str = str.replace(/[^0-9]/g, '');
        str = str.replace(/[^\d]/g, '');
        e.target["value"] = str.replace(/(\d{3})(\d{2})(\d{3})/g, '$1-$2-$3');
        return true;
      }
    }
  }

  phoneFormat(e): boolean {
    {
      if (e.keyCode > 31 && (e.keyCode < 48 || e.keyCode > 57)) {
        e.preventDefault();
      } else {
        e.target["value"] = e.target["value"].replace(/(\d{3}(?!\d?$))\-?/g, '$1-');
        return true;
      }
    }
  }

  changeTextToUppercase(field) {
    const obj = {};
    obj[field] = this.claimpartyForm.controls[field].value.toUpperCase();
    this.claimpartyForm.patchValue(obj);
  }

  mailingAddressValidation() {
    if (
      this.claimpartyForm.controls["mailingAddressLine1"].status == "INVALID" ||
      this.claimpartyForm.controls["mailingCity"].status == "INVALID" ||
      this.claimpartyForm.controls["mailingState"].value == 0 ||
      this.claimpartyForm.controls["mailingZip"].status == "INVALID" ||
      this.claimpartyForm.controls["mailingCountry"].status == "INVALID"
    ) {
      this.validMailingAddress = false;
    }
    else {
      this.validMailingAddress = true;
    }
  }
  onSelectPolicy(event) {
    this.selectedAWD = $('#AWDMultiSelect').val();
  }

  onChange(e) {
    this.selectedaWDclaimParty = e.value;
  }

  isAwdPolicy(policyNumber) {
    const associatedpol = this.aWDclaimPartyList.filter(x => x.summary.policynumber == policyNumber);
    if (associatedpol.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  getClaimPartyDetails(row) {
    this.isClaimPartyRoleDuplicate = false;
    this.assosiatePolicy = this.createDeathClaimSharedSerice.associatedPolicies;
    let href;
    for (let i = 0; i < this.fieldData.claimPartyList.length; i++) {
      if (row.id == this.fieldData.claimPartyList[i].partyID) {
        href = this.fieldData.claimPartyList[i]._options.links[0].href;
      }
    }
    let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    OcInfraModule.AppInjector.get(CommonService).getCallback(href, claimListHeader[0].headers).subscribe(response => {
      this.partyData = {};
      this.partyData = response;
      response.claimPartyRoleList.forEach(item => {
        const role = this.rolesList.find(i => i.id == item.code);
        item.code = role ? role.id.toString() : -1;
        item.readOnly= role && role.title === INSURED;
      });
      this.claimPartyRoleSource = response.claimPartyRoleList;
      this.claimPartyResetResponse = JSON.parse(JSON.stringify(response.claimPartyRoleList));
      this.isMutlipleInsuredRoles = this.isPartyRoleDuplicate();
      this.addPartyClicked = false;
      this.addPartyDisable = false;
      OcInfraModule.AppInjector.get(CommonService).getCallback(this.partyData._options.links[3].href, claimListHeader[0].headers).subscribe(response => {
        if (response.code == "200") {
          this.aWDclaimPartyList = response._items;
        }
        else {
          this.aWDclaimPartyList = [];
        }
      }, error => {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
        error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Unable to fetch CLMPARTY Transaction in AWD", 'Error', 0);
      });
      setTimeout(() => {
        if (this.partyData.partyType == "2") {
          this.claimpartyForm.controls["partyType"].setValue("2");
          this.partyTypeIndividual = false;
          this.partyTypeOrganization = true;
          this.claimpartyForm.controls['searchFirstName'].setValidators(null);
          this.claimpartyForm.controls['searchFirstName'].updateValueAndValidity();
          this.claimpartyForm.controls['searchLastName'].setValidators(null);
          this.claimpartyForm.controls['searchLastName'].updateValueAndValidity();
          this.claimpartyForm.controls['corporationName'].setValidators([Validators.required]);
          this.claimpartyForm.controls['corporationName'].updateValueAndValidity();
        }
        else {
          this.claimpartyForm.controls["partyType"].setValue("1");
          this.onPartyTypeIndividualSelect();
        }
        if ((<HTMLInputElement>document.getElementById('mailingAddress-input')).checked) {
          document.getElementById('mailingAddress-input').click();
        }
        this.existingPartyValues();
        this.selectedAWD = [];
        $('#AWDMultiSelect').val("");
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      }, 2000);
      this.rowClicked = false;
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Something went wrong please try again", 'Error', 0);
    });
  }

  existingPartyValues() {
    this.claimpartyForm.controls["dob"].setValue(this.partyData.dob ? new Date(this.partyData.dob) : "");
    this.claimpartyForm.controls["searchFirstName"].setValue(this.partyData.searchFirstName ? this.partyData.searchFirstName : "");
    this.claimpartyForm.controls["searchMiddleName"].setValue(this.partyData.searchMiddleName ? this.partyData.searchMiddleName : "");
    this.claimpartyForm.controls["searchLastName"].setValue(this.partyData.searchLastName ? this.partyData.searchLastName : "");
    if (this.fieldData._options.properties.gender && this.fieldData._options.properties.gender.oneOf) {
      for (let i = 0; i < this.fieldData._options.properties.gender.oneOf.length; i++) {
        if (this.fieldData._options.properties.gender.oneOf[i].enum[0] == this.partyData.gender) {
          this.claimpartyForm.controls["gender"].setValue(this.fieldData._options.properties.gender.oneOf[i].enum[0]);
        }
      }
    }
    this.claimpartyForm.controls["searchGovId"].setValue(this.partyData.searchGovId ? this.partyData.searchGovId.replace(/(\d{3})(\d{2})(\d{3})/g, '$1-$2-$3') : "");
    if (this.fieldData._options.properties.relationToInsuredCode && this.fieldData._options.properties.relationToInsuredCode.oneOf) {
      for (let i = 0; i < this.fieldData._options.properties.relationToInsuredCode.oneOf.length; i++) {
        if (this.fieldData._options.properties.relationToInsuredCode.oneOf[i].enum[0] == this.partyData.relationToInsuredCode) {
          this.claimpartyForm.controls["relationToInsuredCode"].setValue(this.fieldData._options.properties.relationToInsuredCode.oneOf[i].enum[0]);
        }
      }
    }
    this.claimpartyForm.controls["foreignTin"].setValue(this.partyData.foreignTin ? this.partyData.foreignTin : "");
    this.claimpartyForm.controls["corporationName"].setValue(this.partyData.corporationName ? this.partyData.corporationName : "");
    this.claimpartyForm.controls['residenceAddressLine2'].setValidators(null);
    this.claimpartyForm.controls['residenceAddressLine2'].updateValueAndValidity();
    this.resiAdd2Req = false;
    this.claimpartyForm.controls["residenceAddressLine1"].setValue(this.partyData.residenceAddressLine1 ? this.partyData.residenceAddressLine1 : "");
    this.claimpartyForm.controls["residenceAddressLine2"].setValue(this.partyData.residenceAddressLine2 ? this.partyData.residenceAddressLine2 : "");
    this.claimpartyForm.get("residenceCity").enable();
    this.claimpartyForm.controls["residenceCity"].setValue(this.partyData.residenceCity ? this.partyData.residenceCity : "");
    this.claimpartyForm.get("residenceState").enable();
    if (this.fieldData._options.properties.residenceState && this.fieldData._options.properties.residenceState.oneOf) {
      if (this.partyData.residenceState == 0) {
        this.claimpartyForm.controls["residenceState"].setValue('');
      }
      else {
        for (let i = 0; i < this.fieldData._options.properties.residenceState.oneOf.length; i++) {
          if (this.fieldData._options.properties.residenceState.oneOf[i].enum[0] == this.partyData.residenceState) {
            this.claimpartyForm.controls["residenceState"].setValue(this.fieldData._options.properties.residenceState.oneOf[i].enum[0]);
          }
        }
      }
    }
    this.claimpartyForm.get("residenceZip").enable();
    this.claimpartyForm.controls["residenceZip"].setValue(this.partyData.residenceZip ? this.partyData.residenceZip.replace(/(\d{5}(?!\d?$))\-?/g, '$1-') : "");
    if (this.fieldData._options.properties.residenceCountry && this.fieldData._options.properties.residenceCountry.oneOf) {
      if (this.partyData.residenceCountry == 0) {
        this.claimpartyForm.get("residenceCity").enable();
        this.claimpartyForm.get("residenceState").enable();
        this.claimpartyForm.get("residenceZip").enable();
        this.claimpartyForm.controls['residenceAddressLine2'].setValidators(null);
        this.claimpartyForm.controls['residenceAddressLine2'].updateValueAndValidity();
        this.resiAdd2Req = false;
        this.claimpartyForm.controls["residenceCountry"].setValue('1');
      }
      else {
        for (let i = 0; i < this.fieldData._options.properties.residenceCountry.oneOf.length; i++) {
          if (this.fieldData._options.properties.residenceCountry.oneOf[i].enum[0] == this.partyData.residenceCountry) {
            if (this.partyData.residenceCountry != 1) {
              this.claimpartyForm.get("residenceCity").setValue("");
              this.claimpartyForm.get("residenceCity").disable();
              this.claimpartyForm.get("residenceState").setValue("");
              this.claimpartyForm.get("residenceState").disable();
              this.claimpartyForm.get("residenceZip").setValue("");
              this.claimpartyForm.get("residenceZip").disable();
              this.resiAdd2Req = true;
              this.claimpartyForm.controls['residenceAddressLine2'].setValidators([Validators.required]);
              this.claimpartyForm.controls['residenceAddressLine2'].updateValueAndValidity();
            } else {
              this.claimpartyForm.get("residenceCity").enable();
              this.claimpartyForm.get("residenceState").enable();
              this.claimpartyForm.get("residenceZip").enable();
              this.claimpartyForm.controls['residenceAddressLine2'].setValidators(null);
              this.claimpartyForm.controls['residenceAddressLine2'].updateValueAndValidity();
              this.resiAdd2Req = false;
            }
            this.claimpartyForm.controls["residenceCountry"].setValue(this.fieldData._options.properties.residenceCountry.oneOf[i].enum[0]);
          }
        }
      }
    }
    this.claimpartyForm.controls['mailingAddressLine2'].setValidators(null);
    this.claimpartyForm.controls['mailingAddressLine2'].updateValueAndValidity();
    this.mailingAdd2Req = false;
    this.claimpartyForm.controls["mailingAddressLine1"].setValue(this.partyData.mailingAddressLine1 ? this.partyData.mailingAddressLine1 : "");
    this.claimpartyForm.controls["mailingAddressLine2"].setValue(this.partyData.mailingAddressLine2 ? this.partyData.mailingAddressLine2 : "");
    this.claimpartyForm.get("mailingCity").enable();
    this.claimpartyForm.controls["mailingCity"].setValue(this.partyData.mailingCity ? this.partyData.mailingCity : "");
    this.claimpartyForm.get("mailingState").enable();
    if (this.fieldData._options.properties.mailingState && this.fieldData._options.properties.mailingState.oneOf) {
      if (this.partyData.mailingState == 0) {
        this.claimpartyForm.controls["mailingState"].setValue('');
      }
      else {
        for (let i = 0; i < this.fieldData._options.properties.mailingState.oneOf.length; i++) {
          if (this.fieldData._options.properties.mailingState.oneOf[i].enum[0] == this.partyData.mailingState) {
            this.claimpartyForm.controls["mailingState"].setValue(this.fieldData._options.properties.mailingState.oneOf[i].enum[0]);
          }
        }
      }
    }
    this.claimpartyForm.get("mailingZip").enable();
    this.claimpartyForm.controls["mailingZip"].setValue(this.partyData.mailingZip ? this.partyData.mailingZip.replace(/(\d{5}(?!\d?$))\-?/g, '$1-') : "");
    if (this.fieldData._options.properties.mailingCountry && this.fieldData._options.properties.mailingCountry.oneOf) {
      if (this.partyData.mailingCountry == 0) {
        this.claimpartyForm.controls["mailingCountry"].setValue('1');
        this.claimpartyForm.get("mailingCity").enable();
        this.claimpartyForm.get("mailingState").enable();
        this.claimpartyForm.get("mailingZip").enable();
        this.mailingAdd2Req = false;
        this.claimpartyForm.controls['mailingAddressLine2'].setValidators(null);
        this.claimpartyForm.controls['mailingAddressLine2'].updateValueAndValidity();
      }
      else {
        for (let i = 0; i < this.fieldData._options.properties.mailingCountry.oneOf.length; i++) {
          if (this.fieldData._options.properties.mailingCountry.oneOf[i].enum[0] == this.partyData.mailingCountry) {
            if (this.partyData.mailingCountry != 1) {
              this.claimpartyForm.get("mailingCity").setValue("");
              this.claimpartyForm.get("mailingCity").disable();
              this.claimpartyForm.get("mailingState").setValue("");
              this.claimpartyForm.get("mailingState").disable();
              this.claimpartyForm.get("mailingZip").setValue("");
              this.claimpartyForm.get("mailingZip").disable();
              this.claimpartyForm.controls['mailingAddressLine2'].setValidators([Validators.required]);
              this.claimpartyForm.controls['mailingAddressLine2'].updateValueAndValidity();
              this.mailingAdd2Req = true;
            }
            else{
              this.claimpartyForm.get("mailingCity").enable();
              this.claimpartyForm.get("mailingState").enable();
              this.claimpartyForm.get("mailingZip").enable();
              this.mailingAdd2Req = false;
              this.claimpartyForm.controls['mailingAddressLine2'].setValidators(null);
              this.claimpartyForm.controls['mailingAddressLine2'].updateValueAndValidity();
            }
            this.claimpartyForm.controls["mailingCountry"].setValue(this.fieldData._options.properties.mailingCountry.oneOf[i].enum[0]);
          }
        }
      }
    }
    this.claimpartyForm.controls["email"].setValue(this.partyData.emailDetail ? this.partyData.emailDetail : "");
    if (this.partyData.primaryPhoneDetail) {
      let phoneNumber = this.partyData.primaryPhoneAreaCode + this.partyData.primaryPhoneDetail;
      this.claimpartyForm.controls["primaryPhone"].setValue(phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3'));
    }
    else {
      this.claimpartyForm.controls["primaryPhone"].setValue("");
    }
    if (this.partyData.textPhoneDetail) {
      let textPhone = this.partyData.textAreaCode + this.partyData.textPhoneDetail;
      this.claimpartyForm.controls["text"].setValue(textPhone.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3'));
    }
    else {
      this.claimpartyForm.controls["text"].setValue("");
    }
    this.claimpartyForm.controls["optOutMobile"].setValue(this.partyData.optOutMobile ? true:false);
    this.claimpartyForm.controls["optOutEmail"].setValue(this.partyData.optOutEmail ? true:false);

    if (this.partyData.faxPhoneDetail) {
      let phoneNumber = this.partyData.faxPhoneAreaCode + this.partyData.faxPhoneDetail;
      this.claimpartyForm.controls["faxPhone"].setValue(phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3'));
    }
    else {
      this.claimpartyForm.controls["faxPhone"].setValue("");
    }
  }

  addParty() {
    this.addPartyClicked = true;
    this.rowClicked = false;
    this.addPartyDisable = true;
    this.isClaimPartyRoleDuplicate = false;
    this.formInit();
    this.mailingAdd2Req = false;
    this.claimpartyForm.controls['mailingAddressLine2'].setValidators(null);
    this.claimpartyForm.controls['mailingAddressLine2'].updateValueAndValidity();
    this.claimpartyForm.controls['residenceAddressLine2'].setValidators(null);
    this.claimpartyForm.controls['residenceAddressLine2'].updateValueAndValidity();
    this.resiAdd2Req = false;
    return this.claimPartyRoleSource = [];
    
  }

  validateEmail(e) {
    this.showErrorMessage = false;
    var email = e.target["value"];
    var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (!expr.test(email) && email) {
      this.showErrorMessage = true;
    }
    this.toggleText();
  }

  toggleText(): void {
    this.isTextFieldDisabled = this.showErrorMessage;
    const textControl = this.claimpartyForm.get('text');
    if (this.isTextFieldDisabled) {
      textControl.disable();
    } else {
      textControl.enable();
    }
  }

  selectedRow(index) {
    this.selectedRowIndex = index;
  }

  onRemoveRole() {
    if (this.selectedRowIndex || this.selectedRowIndex == "0") {
      this.claimPartyRoleSource.splice(this.selectedRowIndex, 1);
      this.myTable.renderRows();
      this.rowClicked = false;
    }
    if (this.partyData && this.partyData.claimPartyRoleList && this.partyData.claimPartyRoleList[this.selectedRowIndex] && this.partyData.claimPartyRoleList[this.selectedRowIndex].code == -1) {
      this.isClaimPartyRoleDuplicate = this.claimPartyRoleSource.some(role => role.code === this.partyData.claimPartyRoleList[this.selectedRowIndex].code);
    } else {
       if(this.isMutlipleInsuredRoles) { //duplicate insured roles
        var res = this.claimPartyRoleSource.filter((x) => !this.claimPartyResetResponse.find(y => x['claimPartyRoleID'] === y['claimPartyRoleID'] ));
        var codeArray = res.map(function(item){ return item.code });
        var duplicate = codeArray.some(function(item, i){ 
          return codeArray.indexOf(item) != i 
        });
        var insExists = res.some(function(e) {
          return e.claimRoleCodeDisplayValue === INSURED;
        }); 
       this.isClaimPartyRoleDuplicate = insExists ? true : duplicate;
        
      }  else {
        this.isClaimPartyRoleDuplicate = this.isPartyRoleDuplicate();
      }
    }
    const roleSelected = this.claimPartyRoleSource.every(item => item.code != "-1");
    if (roleSelected && this.claimPartyRoleSource.length) {
      this.addPartyDisable = false;
    }
    else {
      this.addPartyDisable = true;
    }
    this.claimpartyForm.markAsDirty();
  }

  onAddRole() {
    const roleSelected = this.claimPartyRoleSource.every(item => item.code != "-1");
    if (roleSelected) {
      const obj = {
        "claimRoleCodeDisplayValue": "", "code": "-1", "source": ""
      }
      this.ClaimPartyBeforeAdd = JSON.parse(JSON.stringify(this.claimPartyRoleSource));
      this.claimPartyRoleSource.push(obj);
      this.myTable.renderRows();
      this.addPartyDisable = true;
    }
    this.claimpartyForm.markAsDirty();
  }

  onRowClick(index, row) {
    this.selectedRowIndex = index;
    this.selectedRow = row;
    if (this.selectedRow) {
      this.rowClicked = true && !row.readOnly;
    }
  }

  selectedRole(e, data) {
    if (e.value != "-1") {
      if(this.partyData == undefined) { // add
        for (let i = 0; i < this.rolesList.length; i++) {
          if (this.rolesList[i].id == e.value) {
            this.selectedRow["source"] = "Claim";
            this.selectedRow["claimRoleCodeDisplayValue"] = this.rolesList[i].title;
          }
        }
        this.isClaimPartyRoleDuplicate = this.isPartyRoleDuplicate();
      } else { //update
        if(this.partyData && this.partyData.claimPartyRoleList && data.claimPartyRoleID){
          var updatedRoleTitle;
          for (let i = 0; i < this.rolesList.length; i++) {
            if (this.rolesList[i].id == e.value) {
              updatedRoleTitle = this.rolesList[i].title;
            }
          }
          this.isClaimPartyRoleDuplicate = this.partyData.claimPartyRoleList.some(role => role.claimRoleCodeDisplayValue === updatedRoleTitle);
          if(!this.isClaimPartyRoleDuplicate) {
            for (let i = 0; i < this.rolesList.length; i++) {
              if (this.rolesList[i].id == e.value) {
                this.selectedRow["source"] = "Claim";
                this.selectedRow["claimRoleCodeDisplayValue"] = this.rolesList[i].title;
              }
            }
          }
        } else { // add new role while updating
          this.isClaimPartyRoleDuplicate = this.ClaimPartyBeforeAdd.some(role => role.code === data.code);
          for (let i = 0; i < this.rolesList.length; i++) {
            if (this.rolesList[i].id == e.value) {
              this.selectedRow["source"] = "Claim";
              this.selectedRow["claimRoleCodeDisplayValue"] = this.rolesList[i].title;
            }
          }
        }
      }
    }
    else {
      this.selectedRow["source"] = "";
      this.selectedRow["claimRoleCodeDisplayValue"] = "";
      this.selectedRow["code"] = "-1";
    }
    const roleSelected = this.claimPartyRoleSource.every(item => item.code != "-1");
    if (roleSelected) {
      this.addPartyDisable = false;
    }
    else {
      this.addPartyDisable = true;
    }
    this.claimpartyForm.markAsDirty();
  }

  isPartyRoleDuplicate() {
    var codeArray = this.claimPartyRoleSource.map(function(item){ return item.code });
    var isDuplicate = codeArray.some(function(item, i){ 
      return codeArray.indexOf(item) != i 
    });
    return isDuplicate;
  }

  onPartyTypeIndividualSelect() {
    this.claimpartyForm.controls['searchFirstName'].setValidators([Validators.required])
    this.claimpartyForm.controls['searchFirstName'].updateValueAndValidity();
    this.claimpartyForm.controls['searchLastName'].setValidators([Validators.required])
    this.claimpartyForm.controls['searchLastName'].updateValueAndValidity();
    this.claimpartyForm.controls['corporationName'].setValidators(null);
    this.claimpartyForm.controls['corporationName'].updateValueAndValidity();
    this.partyTypeIndividual = true;
    this.partyTypeOrganization = false;
  }

  onRadioChange(value) {
    if (value == "2") {
      this.partyTypeIndividual = false;
      this.partyTypeOrganization = true;
      this.claimpartyForm.controls['searchFirstName'].setValidators(null)
      this.claimpartyForm.controls['searchFirstName'].updateValueAndValidity();
      this.claimpartyForm.controls['searchLastName'].setValidators(null)
      this.claimpartyForm.controls['searchLastName'].updateValueAndValidity();
      this.claimpartyForm.controls['corporationName'].setValidators([Validators.required])
      this.claimpartyForm.controls['corporationName'].updateValueAndValidity();
    }
    else {
      this.onPartyTypeIndividualSelect();
    }
  }

  isSameasResidence(event) {
    if (event.checked) {
      this.isAddressSameasResidence = true;

      let residenceAddressLine1 = this.claimpartyForm.controls["residenceAddressLine1"].value
      residenceAddressLine1 = residenceAddressLine1.replaceAll(',', '');
      
      let residenceAddressLine2 = this.claimpartyForm.controls["residenceAddressLine2"].value
      residenceAddressLine2 = residenceAddressLine2.replaceAll(',', '');

      let residenceCity = this.claimpartyForm.controls["residenceCity"].value
      residenceCity = residenceCity.replaceAll(',', '');

      this.claimpartyForm.controls["mailingAddressLine1"].setValue(residenceAddressLine1);
      this.claimpartyForm.get("mailingAddressLine1").disable();
      this.claimpartyForm.controls["mailingAddressLine2"].setValue(residenceAddressLine2);
      this.claimpartyForm.get("mailingAddressLine2").disable();
      this.claimpartyForm.controls["mailingCity"].setValue(residenceCity);
      this.claimpartyForm.get("mailingCity").disable();
      this.claimpartyForm.controls["mailingState"].setValue(this.claimpartyForm.controls["residenceState"].value);
      this.claimpartyForm.get("mailingState").disable();
      this.claimpartyForm.controls["mailingZip"].setValue(this.claimpartyForm.controls["residenceZip"].value);
      this.claimpartyForm.get("mailingZip").disable();
      this.claimpartyForm.controls["mailingCountry"].setValue(this.claimpartyForm.controls["residenceCountry"].value);
      this.claimpartyForm.get("mailingCountry").disable();
    }
    else {
      this.mailingAdd2Req = false;
      this.isAddressSameasResidence = false;
      this.claimpartyForm.controls["mailingAddressLine1"].setValue("");
      this.claimpartyForm.get("mailingAddressLine1").enable();
      this.claimpartyForm.controls['mailingAddressLine2'].setValidators(null);
      this.claimpartyForm.controls['mailingAddressLine2'].updateValueAndValidity();
      this.claimpartyForm.controls["mailingAddressLine2"].setValue("");
      this.claimpartyForm.get("mailingAddressLine2").enable();
      this.claimpartyForm.controls["mailingCity"].setValue("");
      this.claimpartyForm.get("mailingCity").enable();
      this.claimpartyForm.controls["mailingZip"].setValue("");
      this.claimpartyForm.get("mailingZip").enable();
      this.claimpartyForm.controls["mailingCountry"].setValue("1");
      this.claimpartyForm.get("mailingCountry").enable();
      this.claimpartyForm.controls["mailingState"].setValue("");
      this.claimpartyForm.get("mailingState").enable();
    }
  }

  updateClaimParty(callFromCreate) {
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
    const authResponse = resourceService.getResourceFromAliasName('authRes');

    let residenceCity = this.claimpartyForm.controls['residenceCity'].value
    residenceCity = residenceCity.replaceAll(',', '');
    
    let mailingCity = this.claimpartyForm.controls['mailingCity'].value
    mailingCity = mailingCity.replaceAll(',', '');

    let residenceAddressLine1 = this.claimpartyForm.controls['residenceAddressLine1'].value
    residenceAddressLine1 = residenceAddressLine1.replaceAll(',', '');
    let residenceAddressLine2 = this.claimpartyForm.controls['residenceAddressLine2'].value
    residenceAddressLine2 = residenceAddressLine2.replaceAll(',', '');

    let mailingAddressLine1 = this.claimpartyForm.controls['mailingAddressLine1'].value
    mailingAddressLine1 = mailingAddressLine1.replaceAll(',', '');
    let mailingAddressLine2 = this.claimpartyForm.controls['mailingAddressLine2'].value
    mailingAddressLine2 = mailingAddressLine2.replaceAll(',', '');


    this.payload = {
      residenceAddressLine1: residenceAddressLine1,
      residenceAddressLine2: residenceAddressLine2,
      residenceCity: residenceCity,
      residenceState: this.claimpartyForm.controls['residenceState'].value,
      residenceZip: this.claimpartyForm.controls['residenceZip'].value,
      residenceCountry: this.claimpartyForm.controls['residenceCountry'].value,
      mailingAddressLine1: mailingAddressLine1,
      mailingAddressLine2: mailingAddressLine2,
      mailingCity: mailingCity,
      mailingState: this.claimpartyForm.controls['mailingState'].value,
      mailingZip: this.claimpartyForm.controls['mailingZip'].value,
      mailingCountry: this.claimpartyForm.controls['mailingCountry'].value,
      optOutEmail: this.claimpartyForm.controls['optOutEmail'].value,
      emailDetail: this.claimpartyForm.controls['email'].value,
      primaryPhoneAreaCode: this.claimpartyForm.controls['primaryPhone'].value ? this.claimpartyForm.controls['primaryPhone'].value.substring(0, 3) : "",
      primaryPhoneDetail: this.claimpartyForm.controls['primaryPhone'].value ? (this.claimpartyForm.controls['primaryPhone'].value.substring(4, 7) + this.claimpartyForm.controls['primaryPhone'].value.substring(8)) : "",
      optOutMobile: this.claimpartyForm.controls['optOutMobile'].value,
      textAreaCode: this.claimpartyForm.controls['text'].value ? this.claimpartyForm.controls['text'].value.substring(0, 3) : "",
      textPhoneDetail: this.claimpartyForm.controls['text'].value ? (this.claimpartyForm.controls['text'].value.substring(4, 7) + this.claimpartyForm.controls['text'].value.substring(8)) : "",
      faxPhoneAreaCode: this.claimpartyForm.controls['faxPhone'].value ? this.claimpartyForm.controls['faxPhone'].value.substring(0, 3) : "",
      faxPhoneDetail: this.claimpartyForm.controls['faxPhone'].value ? (this.claimpartyForm.controls['faxPhone'].value.substring(4, 7) + this.claimpartyForm.controls['faxPhone'].value.substring(8)) : "",
      searchGovId: this.claimpartyForm.controls['searchGovId'].value,
      relationToInsuredCode: this.claimpartyForm.controls['relationToInsuredCode'].value,
      foreignTin: this.claimpartyForm.controls['foreignTin'].value,
      hostKey: selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
      policyNumber: selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number,
      region_code: claimListHeader[0].headers.region_code,
      remote_user: claimListHeader[0].headers.remote_user,
      sessionId: authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
      callStart: authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
      clientId: selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
      userId: OcInfraModule.AppInjector.get(CommonService).incontactId,
      claimPartyRoleList: this.partyData.claimPartyRoleList,
      residenceAddressId: this.partyData.residenceAddressId ? this.partyData.residenceAddressId : "",
      partyType: this.claimpartyForm.controls['partyType'].value ? this.claimpartyForm.controls['partyType'].value : "1"
    }
    if (this.partyTypeIndividual) {
      this.payload["searchFirstName"] = this.claimpartyForm.controls['searchFirstName'].value;
      this.payload["searchMiddleName"] = this.claimpartyForm.controls['searchMiddleName'].value ? this.claimpartyForm.controls['searchMiddleName'].value : "";
      this.payload["searchLastName"] = this.claimpartyForm.controls['searchLastName'].value;
      this.payload["gender"] = this.claimpartyForm.controls['gender'].value;
      this.payload["dob"] = this.datePipe.transform(this.claimpartyForm.controls['dob'].value, "MM/dd/yyyy");
    }
    else {
      this.payload["corporationName"] = this.claimpartyForm.controls['corporationName'].value;
    }
    if (this.partyData.mailingAddressId && this.partyData.mailingAddressId != "") {
      this.payload["mailingAddressId"] = this.partyData.mailingAddressId;
    }
    if(!this.isAnnuity){
      this.payload["optOutEmail"] =  this.claimpartyForm.controls['optOutEmail'].value;
      this.payload["optOutMobile"] =  this.claimpartyForm.controls['optOutMobile'].value;
   }
    OcInfraModule.AppInjector.get(CommonService).patchCallback(this.partyData._options.links[1].href, this.payload, claimListHeader[0].headers).subscribe(response => {
      setTimeout(() => {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        this.getClaimPartylist(callFromCreate);
      }, 5001);
      this.claimpartyForm.markAsPristine();
      if(!callFromCreate){
        OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
      }
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to update Claim Party. Please try again.", 'Error', 0);
    });
  }

  resiChange() {
    if (this.isAddressSameasResidence) {
      this.claimpartyForm.controls["mailingAddressLine1"].setValue(this.claimpartyForm.controls["residenceAddressLine1"].value);
      this.claimpartyForm.controls["mailingAddressLine2"].setValue(this.claimpartyForm.controls["residenceAddressLine2"].value);
      this.claimpartyForm.controls["mailingCity"].setValue(this.claimpartyForm.controls["residenceCity"].value);
      this.claimpartyForm.controls["mailingState"].setValue(this.claimpartyForm.controls["residenceState"].value);
      this.claimpartyForm.controls["mailingZip"].setValue(this.claimpartyForm.controls["residenceZip"].value);
      this.claimpartyForm.controls["mailingCountry"].setValue(this.claimpartyForm.controls["residenceCountry"].value);
    }
  }

  createClaimParty() {
    OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
    let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
    const resourceService = OcInfraModule.AppInjector.get(ResourceService);
    let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
    const selectedRoleSummary = resourceService.getResourceFromAliasName('selectedRoles');
    const authResponse = resourceService.getResourceFromAliasName('authRes');

    let residenceCity = this.claimpartyForm.controls['residenceCity'].value
    residenceCity = residenceCity.replaceAll(',', '');
    
    let mailingCity = this.claimpartyForm.controls['mailingCity'].value
    mailingCity = mailingCity.replaceAll(',', '');

    let residenceAddressLine1 = this.claimpartyForm.controls['residenceAddressLine1'].value
    residenceAddressLine1 = residenceAddressLine1.replaceAll(',', '');
    let residenceAddressLine2 = this.claimpartyForm.controls['residenceAddressLine2'].value
    residenceAddressLine2 = residenceAddressLine2.replaceAll(',', '');

    let mailingAddressLine1 = this.claimpartyForm.controls['mailingAddressLine1'].value
    mailingAddressLine1 = mailingAddressLine1.replaceAll(',', '');
    let mailingAddressLine2 = this.claimpartyForm.controls['mailingAddressLine2'].value
    mailingAddressLine2 = mailingAddressLine2.replaceAll(',', '');

    this.payload = {
      residenceAddressLine1: residenceAddressLine1,
      residenceAddressLine2: residenceAddressLine2,
      residenceCity: residenceCity,
      residenceState: this.claimpartyForm.controls['residenceState'].value,
      residenceZip: this.claimpartyForm.controls['residenceZip'].value,
      residenceCountry: this.claimpartyForm.controls['residenceCountry'].value,
      mailingAddressLine1: mailingAddressLine1,
      mailingAddressLine2: mailingAddressLine2,
      mailingCity: mailingCity,
      mailingState: this.claimpartyForm.controls['mailingState'].value,
      mailingZip: this.claimpartyForm.controls['mailingZip'].value,
      mailingCountry: this.claimpartyForm.controls['mailingCountry'].value,
      emailDetail: this.claimpartyForm.controls['email'].value,
      primaryPhoneAreaCode: this.claimpartyForm.controls['primaryPhone'].value ? this.claimpartyForm.controls['primaryPhone'].value.substring(0, 3) : "",
      primaryPhoneDetail: this.claimpartyForm.controls['primaryPhone'].value ? (this.claimpartyForm.controls['primaryPhone'].value.substring(4, 7) + this.claimpartyForm.controls['text'].value.substring(8)) : "",
      textAreaCode: this.claimpartyForm.controls['text'].value ? this.claimpartyForm.controls['text'].value.substring(0, 3) : "",
      textPhoneDetail: this.claimpartyForm.controls['text'].value ? (this.claimpartyForm.controls['text'].value.substring(4, 7) + this.claimpartyForm.controls['text'].value.substring(8)) : "",
      faxPhoneAreaCode: this.claimpartyForm.controls['faxPhone'].value ? this.claimpartyForm.controls['faxPhone'].value.substring(0, 3) : "",
      faxPhoneDetail: this.claimpartyForm.controls['faxPhone'].value ? (this.claimpartyForm.controls['faxPhone'].value.substring(4, 7) + this.claimpartyForm.controls['faxPhone'].value.substring(8)) : "",
      searchGovId: this.claimpartyForm.controls['searchGovId'].value,
      relationToInsuredCode: this.claimpartyForm.controls['relationToInsuredCode'].value,
      foreignTin: this.claimpartyForm.controls['foreignTin'].value,
      hostKey: selectedRoleSummary && selectedRoleSummary.data.hostKey ? selectedRoleSummary.data.hostKey : '',
      policyNumber: selectedRoleSummary && selectedRoleSummary.data.policyNumber ? selectedRoleSummary.data.policyNumber : policyDetails.data.policy_number,
      region_code: claimListHeader[0].headers.region_code,
      remote_user: claimListHeader[0].headers.remote_user,
      sessionId: authResponse && authResponse.data.contactSessionID ? authResponse.data.contactSessionID : '',
      callStart: authResponse && authResponse.data.callStart ? authResponse.data.callStart : '',
      clientId: selectedRoleSummary && selectedRoleSummary.data.clientId ? selectedRoleSummary.data.clientId : '',
      userId: OcInfraModule.AppInjector.get(CommonService).incontactId,
      claimPartyRoleList: this.claimPartyRoleSource,
      partyType: this.claimpartyForm.controls['partyType'].value ? this.claimpartyForm.controls['partyType'].value : "1"
    }
    if (this.partyTypeIndividual) {
      this.payload["searchFirstName"] = this.claimpartyForm.controls['searchFirstName'].value;
      this.payload["searchMiddleName"] = this.claimpartyForm.controls['searchMiddleName'].value ? this.claimpartyForm.controls['searchMiddleName'].value : "";
      this.payload["searchLastName"] = this.claimpartyForm.controls['searchLastName'].value;
      this.payload["gender"] = this.claimpartyForm.controls['gender'].value;
      this.payload["dob"] = this.datePipe.transform(this.claimpartyForm.controls['dob'].value, "MM/dd/yyyy");
    }
    else {
      this.payload["corporationName"] = this.claimpartyForm.controls['corporationName'].value;
    }
    OcInfraModule.AppInjector.get(CommonService).postCallback(this.fieldData._options.links[0].href, this.payload, claimListHeader[0].headers).subscribe(response => {
      this.closePartyForm();
      setTimeout(() => {
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        this.getClaimPartylist();
      }, 5001);
      OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage(response.message, 'Success');
      const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
      let workRequestheaders = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
      let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
      let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'claimList' });
      if (response.success) {
        setTimeout(() =>{
        OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': workRequestheaders[0].headers}).subscribe(refreshResponse => {
          OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
          OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
          OcInfraModule.AppInjector.get(APICallerService).refresh(policyDetails._links.claims.href, { 'alias': "claimList", 'headers':claimListHeader[0].headers}).subscribe(refreshResponse => {
              OcInfraModule.AppInjector.get(CommonService).claimsTableRes = refreshResponse;
             OcInfraModule.AppInjector.get(CommonService).sendClaimsFilterEvent();
          });
        });
      },2500); 
    }
    }, error => {
      OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
      error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to create Claim Party. Please try again.", 'Error', 0);
    });
  }

  closePartyForm() {
    if ((<HTMLInputElement>document.getElementById('mailingAddress-input')).checked) {
      document.getElementById('mailingAddress-input').click();
    }
    this.claimpartyForm.reset();
    this.onPartyTypeIndividualSelect();
    this.partyData = false;
    this.addPartyClicked = false;
    this.isClaimPartyRoleDuplicate = false;
  }

  resetClaimParty() {
    if ((<HTMLInputElement>document.getElementById('mailingAddress-input')).checked) {
      document.getElementById('mailingAddress-input').click();
    }
    this.existingPartyValues();
    this.rowClicked = false;
    this.claimPartyRoleSource = JSON.parse(JSON.stringify(this.claimPartyResetResponse));
    this.isClaimPartyRoleDuplicate = false;
    this.partyData.claimPartyRoleList = this.claimPartyRoleSource;
    this.claimpartyForm.markAsPristine();
    this.addPartyDisable = false;
  }

  createAWDClaimParty() {
    if(this.claimpartyForm.dirty){
      this.updateClaimParty(true);
    }
    else{
      this.createClaimKit();
    }
  }

  createClaimKit(){
    const dialogRef = this.dialog.open(ClaimKitIndicatorComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe(dialogResponse => {
      if (dialogResponse) {
        let claimListHeader = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'createClaim' });
        let selectedAWDPolicies = this.selectedAWD;
        var locations = [];
        for (var i = 0; i < selectedAWDPolicies.length; i++) {
          let selectedCompanyCode = this.assosiatePolicy.filter(x => x.policyNumber == selectedAWDPolicies[i]);
          locations.push({
            "policyNumber": selectedAWDPolicies[i],
            "companyCode": selectedCompanyCode[0].companyCode,
            "productType": "T1"
          });
          this.postPayload = {
            claimKitIndicator: dialogResponse
          };
        }
        this.postPayload["policyDetails"] = [];
        this.postPayload["policyDetails"] = (locations);
        OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: true, edit: false });
        OcInfraModule.AppInjector.get(CommonService).postCallback(this.partyData._options.links[2].href, this.postPayload, claimListHeader[0].headers).subscribe(response => {
          OcInfraModule.AppInjector.get(CommonService).getCallback(this.partyData._options.links[3].href, claimListHeader[0].headers).subscribe(response => {
            if (response.code == "200") {
              this.aWDclaimPartyList = response._items;
            }
            else {
              this.aWDclaimPartyList = [];
            }
          }, error => {
            OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
            error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Unable to fetch CLMPARTY Transaction in AWD", 'Error', 0);
          });
          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
          OcInfraModule.AppInjector.get(AdvGrowlService).createSuccessMessage("Claim Kit successfully created", 'Success');
          const resourceService = OcInfraModule.AppInjector.get(ResourceService);
          const workRequestHref = resourceService.getHrefByAliasName("workRequestHistory").split('?')[0];
          let workRequestheaders = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'workRequestHistory' });
          let policyDetails = resourceService.getResourceFromAliasName('policyDetails');
          let claimListHeaders = _.filter(Configuration.config.headers["multiHostUrlHeader"], function (o) { return o.alias == 'claimList' });
          if (response.success) {
            setTimeout(() =>{
            OcInfraModule.AppInjector.get(APICallerService).refresh(workRequestHref, { 'alias': "workRequestHistory", 'headers': workRequestheaders[0].headers}).subscribe(refreshResponse => {
            OcInfraModule.AppInjector.get(CommonService).wrkReqHistoryRes = refreshResponse;
            OcInfraModule.AppInjector.get(CommonService).sendFilterEvent();
            OcInfraModule.AppInjector.get(APICallerService).refresh(policyDetails._links.claims.href, { 'alias': "claimList", 'headers':claimListHeaders[0].headers}).subscribe(refreshResponse => {
              OcInfraModule.AppInjector.get(CommonService).claimsTableRes = refreshResponse;
              OcInfraModule.AppInjector.get(CommonService).sendClaimsFilterEvent();
            });       
          });
      },2500); 
    }
        }, error => {
          OcInfraModule.AppInjector.get(CommonService).showHideSpinner({ showSpinner: false, edit: false });
          error && error.message ? OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage(error.message, 'Error', 0) : OcInfraModule.AppInjector.get(AdvGrowlService).createTimedErrorMessage("Failed to create Claim Party. Please try again.", 'Error', 0);
        });
      }
      this.selectedAWD = [];
      $('#AWDMultiSelect').val("");
    })
  }


    restrictComma = (e: any) => {
        if (e.keyCode === 44) {
            e.preventDefault();
        }
    }

}

@Component({
  selector: 'claim-kit-indicator',
  templateUrl: 'claim-kit-indicator.html',
  styleUrls: ['claim-kit-indicator.scss']
})
export class ClaimKitIndicatorComponent {
  constructor(public dialogRef: MatDialogRef<ClaimKitIndicatorComponent>) {

  }
}